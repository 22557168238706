import React from 'react';
import { Handle, Position } from 'reactflow';

const PlantNode = ({ data, id, handleNodeChange }) => {
    const handleNumeratorChange = (e) => {
        handleNodeChange(id, 'numerator', e.target.value);
    };

    const handleDenominatorChange = (e) => {
        handleNodeChange(id, 'denominator', e.target.value);
    };

    return (
        <div style={{ padding: 10, border: '1px solid #fff', borderRadius: '5px', background: '#2a2a2a', width: '150px' }}>
            {data.label}
            <input
                type="text"
                value={data.numerator}
                onChange={handleNumeratorChange}
                placeholder="Num"
                style={{ width: '100%', margin: '5px 0', background: 'transparent', border: '1px solid #fff', color: '#fff' }}
            />
            <input
                type="text"
                value={data.denominator}
                onChange={handleDenominatorChange}
                placeholder="Den"
                style={{ width: '100%', margin: '5px 0', background: 'transparent', border: '1px solid #fff', color: '#fff' }}
            />
            <Handle type="target" position={Position.Left} style={{ background: '#555' }} />
            <Handle type="source" position={Position.Right} style={{ background: '#555' }} />
        </div>
    );
};

export default PlantNode;
