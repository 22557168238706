import React from 'react';
import { Card, CardContent, Typography} from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    display: 'block', // This is important to make the link occupy the full space of the Card
});

const ThermoPropertiesBlog = ({ item }) => (
    <StyledLink to={`/thermopropertiesblogpost/${item.slugName}`}>
        <Card
            elevation={0}
            sx={{
                maxWidth: '100%',
                // boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                backgroundColor: '#353535',
                // borderRadius: '15px',
                // padding: '16px',
                transition: 'transform .2s', // Adds a nice hover effect
                '&:hover': {
                    transform: 'scale(1.02)'  // Adds a subtle scale on hover
                },
                // border: '2px solid #262931'
            }}
        >
            <CardContent>
                <Typography
                    variant="body1"
                    sx={{
                        color:'#fffffe',
                        marginBottom: '8px',
                        textAlign: 'left'
                    }}
                >
                    {item.title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        marginBottom: '16px',
                        color:'#b9b9b9',
                        textAlign: 'left'
                    }}
                >
                    {item.shortDescription}
                </Typography>
            </CardContent>
        </Card>
    </StyledLink>
);

export default ThermoPropertiesBlog;
