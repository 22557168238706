/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Box, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import { Container } from '@mui/material';
import { Helmet } from "react-helmet";
import LogicBlogPostList from "../HowTosLogic/LogicBlogPostList";
import BlogPostListPlain from "./BlogPostListPlain";
import MatrixBlogPostList from "../HowTosMatrix/MatrixBlogPostList";
import BodeBlogPostList from "../HowTosBode/BodeBlogPostList";
import NyquistBlogPostList from "../HowTosNyquist/NyquistBlogPostList";
import NicholsBlogPostList from "../HowTosNichols/NicholsBlogPostList";
import RootLocusBlogPostList from "../HowTosRootLocus/RootLocusBlogPostList";
import PoleZeroMapBlogPostList from "../HowTosPoleZeroMap/PoleZeroMapBlogPostList";
import StepResponseBlogPostList from "../HowTosStepResponse/StepResponseBlogPostList";
import ImpulseResponseBlogPostList from "../HowTosImpulseResponse/ImpulseResponseBlogPostList";
import FeedbackControlSystemBlogPostList from "../HowTosFeedbackControlSystem/FeedbackControlSystemBlogPostList";
import FeaBlogPostList from "../HowTosFea/FeaBlogPostList";
import BeamBlogPostList from "../HowTosBeam/BeamBlogPostList";
import ThermoPlotsBlogPostList from "../HowTosThermoPlots/ThermoPlotsBlogPostList";
import BmsBlogPostList from "../HowTosBms/BmsBlogPostList";
import CircuitBlogPostList from "../HowTosCircuit/CircuitBlogPostList";
import ModelBlogPostList from "../HowTosModel/ModelBlogPostList";
import DiagramsBlogPostList from "../HowTosDiagrams/DiagramsBlogPostList";
import GanttBlogPostList from "../HowTosGantt/GanttBlogPostList";
import StocksBlogPostList from "../HowTosStocks/StocksBlogPostList";
import ResumeBlogPostList from "../HowTosResume/ResumeBlogPostList";

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
    marginBottom: '1rem',
    color: '#9DC183',
    '&:hover': {
        color: '#0056b3',
    },
    cursor: 'pointer',
    textDecoration: 'none'
});

const StyledCard = styled(Card)({
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
    padding: '2rem',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2rem',
    height: '240px', // Fixed height for the card
    overflow: 'hidden', // Ensure overflow content is hidden
    textAlign: 'center'
});

const BlogPosts = () => {
    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 3,
                    width: '100%' // Ensure full width
                }}>
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant="h1" sx={{ textAlign: 'center', marginBottom: 2, color:'#ffc801', fontSize: '2rem' }}>
                            All Blog Posts
                        </Typography>
                        <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: 2, color:'#fffffe' }}>
                            All Tutorials and Articles
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: 2, color: '#b9b9b9' }}>
                            Discover in-depth guides and tutorials across various apps including logic solver, equation solver, linear algebra, and more on NebulaSolver.
                        </Typography>
                    </CardContent>
                </StyledCard>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/logicblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Logic Solver</StyledTypography>
                    </a>
                    <LogicBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/blogpostsplain" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Equation Solver</StyledTypography>
                    </a>
                    <BlogPostListPlain />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/matrixblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Linear Algebra</StyledTypography>
                    </a>
                    <MatrixBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/bodeblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Bode Plot</StyledTypography>
                    </a>
                    <BodeBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/nyquistblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Nyquist Plot</StyledTypography>
                    </a>
                    <NyquistBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/nicholsblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Nichols Plot</StyledTypography>
                    </a>
                    <NicholsBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/rootlocusblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Root Locus</StyledTypography>
                    </a>
                    <RootLocusBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/polezeromapblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Pole Zero Map</StyledTypography>
                    </a>
                    <PoleZeroMapBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/stepresponseblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Step Response</StyledTypography>
                    </a>
                    <StepResponseBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/impulseresponseblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Impulse Response</StyledTypography>
                    </a>
                    <ImpulseResponseBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/feedbackcontrolsystemblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>PID Control System</StyledTypography>
                    </a>
                    <FeedbackControlSystemBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/feablogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>2D-Truss Analysis</StyledTypography>
                    </a>
                    <FeaBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/beamblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Beam Analysis</StyledTypography>
                    </a>
                    <BeamBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/thermoplotsblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Thermodynamic Diagrams</StyledTypography>
                    </a>
                    <ThermoPlotsBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/bmsblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Battery Management Systems</StyledTypography>
                    </a>
                    <BmsBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/circuitblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Electronics</StyledTypography>
                    </a>
                    <CircuitBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/modelblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Systems Engineering</StyledTypography>
                    </a>
                    <ModelBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/diagramsblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Diagrams</StyledTypography>
                    </a>
                    <DiagramsBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/ganttblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Gantt Chart</StyledTypography>
                    </a>
                    <GanttBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/stocksblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Stock Analysis</StyledTypography>
                    </a>
                    <StocksBlogPostList />
                </Box>
                <Box sx={{ width: '100%' }}>
                    <a href="https://nebulasolver.com/resumeblogposts" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <StyledTypography>Resume Builder</StyledTypography>
                    </a>
                    <ResumeBlogPostList />
                </Box>
            </Box>
            <Helmet>
                <title>All Blog Posts</title>
                <link rel="canonical" href={`https://nebulasolver.com/blogposts`} />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on various apps including logic solver, equation solver, linear algebra, and more on NebulaSolver."
                />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/blogposts`} />
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/blogposts`} />
                <meta property="og:title" content="How To's - Blog Posts Collection" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/blogposts" />
                <meta property="og:description" content="Explore comprehensive articles and tutorials on various apps including logic solver, equation solver, linear algebra, and more on NebulaSolver." />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="All Blog Posts" />
                <meta name="twitter:description" content="Explore comprehensive articles and tutorials on various apps including logic solver, equation solver, linear algebra, and more on NebulaSolver." />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default BlogPosts;
