import React, { useEffect, useRef } from 'react';

const Netlist = () => {
    const svgContainerRef = useRef(null);

    useEffect(() => {
        if (window.netlistsvg) {
            console.log("netlistsvg is loaded");

            // Provided netlist JSON
            const netlist = {
                "modules": {
                    "resistor_divider": {
                        "ports": {
                            "A": {
                                "direction": "input",
                                "bits": [2]
                            },
                            "B": {
                                "direction": "input",
                                "bits": [3]
                            },
                            "A AND B": {
                                "direction": "output",
                                "bits": [4]
                            }
                        },
                        "cells": {
                            "R1": {
                                "type": "r_v",
                                "connections": {
                                    "A": [2],
                                    "B": [5]
                                },
                                "attributes": {
                                    "value": "10k"
                                }
                            },
                            "R2": {
                                "type": "r_v",
                                "connections": {
                                    "A": [3],
                                    "B": [5]
                                },
                                "attributes": {
                                    "value": "10k"
                                }
                            },
                            "Q1": {
                                "type": "q_pnp",
                                "port_directions": {
                                    "C": "input",
                                    "B": "input",
                                    "E": "output"
                                },
                                "connections": {
                                    "C": [6],
                                    "B": [5],
                                    "E": [7]
                                }
                            },
                            "R3": {
                                "type": "r_v",
                                "connections": {
                                    "A": [7],
                                    "B": [8]
                                },
                                "attributes": {
                                    "value": "10k"
                                }
                            },
                            "R4": {
                                "type": "r_v",
                                "connections": {
                                    "A": [7],
                                    "B": [9]
                                },
                                "attributes": {
                                    "value": "10k"
                                }
                            },
                            "R5": {
                                "type": "r_v",
                                "connections": {
                                    "A": [4],
                                    "B": [12]
                                },
                                "attributes": {
                                    "value": "10k"
                                }
                            },
                            "Q2": {
                                "type": "q_pnp",
                                "port_directions": {
                                    "C": "input",
                                    "B": "input",
                                    "E": "output"
                                },
                                "connections": {
                                    "C": [10],
                                    "B": [9],
                                    "E": [4]
                                }
                            },
                            "vcc": {
                                "type": "vcc",
                                "connections": {
                                    "A": [6]
                                },
                                "attributes": {
                                    "name": "VCC"
                                }
                            },
                            "vcc2": {
                                "type": "vcc",
                                "connections": {
                                    "A": [10]
                                },
                                "attributes": {
                                    "name": "VCC"
                                }
                            },
                            "gnd": {
                                "type": "gnd",
                                "port_directions": {
                                    "A": "input"
                                },
                                "connections": {
                                    "A": [8]
                                },
                                "attributes": {
                                    "name": "DGND"
                                }
                            },
                            "gnd2": {
                                "type": "gnd",
                                "port_directions": {
                                    "A": "input"
                                },
                                "connections": {
                                    "A": [12]
                                },
                                "attributes": {
                                    "name": "DGND"
                                }
                            }
                        }
                    }
                }
            };

            const skin = window.netlistsvg.analogSkin;

            // Render the netlist using netlistsvg
            window.netlistsvg.render(skin, netlist, (err, svg) => {
                if (err) {
                    console.error("Error rendering netlist:", err);
                } else {
                    console.log("SVG generated:", svg);
                    if (svgContainerRef.current) {
                        // Create a shadow root
                        const shadowRoot = svgContainerRef.current.attachShadow({ mode: 'open' });

                        // Parse the SVG string into a DOM node
                        const parser = new DOMParser();
                        const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
                        const svgElement = svgDoc.documentElement;

                        // Apply custom styles directly to the SVG elements
                        const customStyle = `
                            svg {
                                stroke: #fff;
                                fill: none;
                            }
                            text {
                                fill: #fff;
                                stroke: none;
                                font-size: 10px;
                                font-weight: bold;
                                font-family: "Courier New", monospace;
                            }
                            .nodelabel {
                                text-anchor: middle;
                            }
                            .inputPortLabel {
                                text-anchor: end;
                            }
                            .splitjoinBody {
                                fill: #fff;
                            }
                            .symbol {
                                stroke-linejoin: round;
                                stroke-linecap: round;
                                stroke-width: 2;
                            }
                            .detail {
                                stroke-linejoin: round;
                                stroke-linecap: round;
                                fill: #fff;
                            }
                        `;

                        const styleElement = document.createElement('style');
                        styleElement.textContent = customStyle;
                        shadowRoot.appendChild(styleElement);

                        // Scale the SVG
                        svgElement.setAttribute('transform', 'scale(1.5)'); // Adjust the scale factor as needed

                        // Center the SVG
                        svgElement.setAttribute('style', 'display: block; margin: auto; background-color: #fff; padding: 10px; border-radius: 10px;');

                        shadowRoot.appendChild(svgElement);
                    }
                }
            });
        } else {
            console.error("netlistsvg is not loaded");
        }
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <h1>Netlist SVG Rendering</h1>
            <div ref={svgContainerRef} style={{ textAlign: 'center', marginTop: '50px' }}></div>
        </div>
    );
};

export default Netlist;
