/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Container, TextField, Button, Typography, Card, CardContent, Grid, CircularProgress, CardMedia, Link } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { keyframes } from '@emotion/react';
import { Link as RouterLink } from 'react-router-dom';
import JSZip from 'jszip';
import BeamBlogPosts from "../components/HowTosBeam/BeamBlogPosts";
import {Helmet} from "react-helmet";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Define a pulse animation for buttons
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const PricingBox = () => (
    <Grid>
        <Box
            sx={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#353535',
                    borderRadius: '15px',
                    border: '2px solid #35353500',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                            marginBottom: '10px',
                        }}
                    >
                        Pricing
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{ color: '#9DC183', marginBottom: '20px' }}
                        >
                            $9.99 / year
                        </Typography>
                        <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
                            {[
                                'Full access to all the apps',
                                'Free articles and tutorials',
                                'No need to install anything',
                                'Auth0 Authentication',
                                'Stripe Payments Integration'
                            ].map((item) => (
                                <Typography
                                    key={item}
                                    variant="h6"
                                    sx={{ color: '#b9b9b9', marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <i className="fas fa-check-circle" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', backgroundColor: '#353535' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                        Get NebulaSolver
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, color: '#b9b9b9' }}>
                        Subscribe to access the Beam Analysis Tool.
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/subscribe"
                        sx={{
                            backgroundColor: '#15a2fa',
                            '&:hover': {
                                backgroundColor: '#9DC183',
                            },
                            width: '50%',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            animation: `${pulseAnimation} 2s infinite`,
                        }}
                    >
                        Go to Subscribe
                    </Button>
                </CardContent>
            </Card>
        </Box>
    </Grid>
);

// Define a hover animation for links
const hoverAnimation = keyframes`
  0% { color: #fffffe; }
  100% { color: #9DC183; }
`;

const MoreByNebulaSolver = () => (
    <Box sx={{ marginTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                <CardContent sx={{ padding: '14px 0px' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                        More by NebulaSolver
                    </Typography>
                    <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: '400px' }}>
                        {[
                            { href: "https://nebulasolver.com/equations", text: "Equation Solver" },
                            { href: "https://nebulasolver.com/matrix", text: "Linear Algebra" },
                            { href: "https://nebulasolver.com/controlsystems", text: "Control Systems" },
                            { href: "https://nebulasolver.com/finiteelements", text: "Finite Elements" },
                            { href: "https://nebulasolver.com/thermodynamics", text: "Thermodynamics" },
                            { href: "https://nebulasolver.com/batterymanagement", text: "Battery Management" },
                            { href: "https://nebulasolver.com/electronics", text: "Electronics" },
                            { href: "https://nebulasolver.com/systems", text: "Systems Engineering" },
                            { href: "https://nebulasolver.com/diagrams", text: "Diagrams" },
                            { href: "https://nebulasolver.com/gantt", text: "Gantt Chart" },
                            { href: "https://nebulasolver.com/stocks", text: "Stock Analysis" }
                        ].map(item => (
                            <Link
                                key={item.href}
                                href={item.href}
                                sx={{
                                    display: 'block',
                                    mb: 1,
                                    color: '#fffffe',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                {item.text}
                            </Link>
                        ))}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Box>
);

const Beam = () => {
    const { user, isAuthenticated, isLoading: authLoading, getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState('');
    const [beamAnalysisImages, setBeamAnalysisImages] = useState([]);
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);
    // State hooks for beam configuration
    const [elements, setElements] = useState([]);
    const [supports, setSupports] = useState([]);
    const [loads, setLoads] = useState([]);
    // State hooks for form input values
    const [newElement, setNewElement] = useState({ start: '', end: '', EA: '', EI: '' });
    const [newSupport, setNewSupport] = useState({ node_id: '', type: '', direction: '', translation: '', k: '' });
    const [newLoad, setNewLoad] = useState({ node_id: '', type: '', Fx: '', Fy: '', q: '', Ty: '' });



    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !authLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, authLoading]);

    const handleAddElement = () => {
        // Validation or transformation logic here if necessary
        setElements(prevElements => [...prevElements, newElement]);
        setNewElement({ start: '', end: '', EA: '', EI: '' }); // Reset form
    };

    const handleAddSupport = () => {
        // Validation or transformation logic here if necessary
        setSupports(prevSupports => [...prevSupports, newSupport]);
        setNewSupport({ node_id: '', type: '', direction: '', translation: '', k: '' }); // Reset form
    };

    const handleAddLoad = () => {
        // Validation or transformation logic here if necessary
        setLoads(prevLoads => [...prevLoads, newLoad]);
        setNewLoad({ node_id: '', type: '', Fx: '', Fy: '', q: '', Ty: '' }); // Reset form
    };

    const handleSubmitBeam = async () => {
        if (!isAuthenticated || !user) {
            setError('User not authenticated');
            return;
        }

        const payload = {
            elements: elements.map(e => ({
                start: e.start.split(',').map(Number), // Convert "0,0" to [0, 0]
                end: e.end.split(',').map(Number),     // Convert "5,0" to [5, 0]
                EA: parseFloat(e.EA),
                EI: parseFloat(e.EI)
            })),
            supports: supports.map(s => ({
                node_id: parseInt(s.node_id),
                type: s.type,
                direction: s.type === 'roll' ? parseInt(s.direction) : undefined,
                translation: s.type === 'spring' ? parseInt(s.translation) : undefined,
                k: s.type === 'spring' ? parseFloat(s.k) : undefined,
            })),
            loads: loads.map(l => ({
                node_id: l.node_id ? parseInt(l.node_id) : undefined,
                element_id: l.element_id ? parseInt(l.element_id) : undefined,
                type: l.type,
                Fx: l.Fx ? parseFloat(l.Fx) : undefined,
                Fy: l.Fy ? parseFloat(l.Fy) : undefined,
                q: l.q ? parseFloat(l.q) : undefined,
                Ty: l.type === 'moment' ? parseFloat(l.Ty) : undefined,
            }))
        };

        try {
            const jwtResponse = await fetch('/.netlify/functions/generateJWTBeam', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userEmail: user.email }),
            });

            if (!jwtResponse.ok) {
                throw new Error(`HTTP error ${jwtResponse.status}: ${jwtResponse.statusText}`);
            }

            const jwtData = await jwtResponse.json();
            const jwtToken = jwtData.token;

            const response = await fetch('https://b2edn9mdr6.us-west-2.awsapprunner.com/analyze_beam', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorBody = await response.text();
                throw new Error(`HTTP error! status: ${response.status} - ${errorBody}`);
            }

            const blob = await response.blob();
            const zip = await JSZip.loadAsync(blob);

            // Extract file names and generate URLs for the images
            const imageUrls = await Promise.all(
                Object.keys(zip.files)
                    .filter(fileName => fileName.startsWith('plot_') && fileName.endsWith('.png'))
                    .map(async (fileName) => {
                        const fileBlob = await zip.files[fileName].async('blob');
                        return URL.createObjectURL(fileBlob);
                    })
            );

            // Update state with the new image URLs
            setBeamAnalysisImages(imageUrls);

        } catch (err) {
            setError(`Error during the beam analysis: ${err.message}`);
            console.error(err);
        }
    };

    if (authLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!subscribeStatus) {
        return (
            <Container maxWidth={false}>

                <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <Box sx={{ marginTop: '0px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                                <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                                    <CardContent sx={{ padding: '0px 0px' }}>
                                        <BeamBlogPosts />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PricingBox />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MoreByNebulaSolver />
                    </Grid>
                </Grid>
                <Helmet>
                    <title>NebulaSolver - Beam Analysis</title>
                    <link rel="canonical" href={`https://nebulasolver.com/beam`}/>
                    <meta property="og:title" content="NebulaSolver - Beam Analysis"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png"/>
                    <meta property="og:url" content="https://nebulasolver.com/beam"/>
                    <meta property="og:description"
                          content="NebulaSolver - Beam Analysis. Generate beam diagrams using the finite element method."/>
                    <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/beam`}/>
                    <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/beam`}/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content="NebulaSolver - Beam Analysis"/>
                    <meta name="twitter:description"
                          content="NebulaSolver - Beam Analysis. Generate beam diagrams using the finite element method."/>
                    <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png"/>
                    <meta name="twitter:site" content="@nebulasolver"/>
                </Helmet>
            </Container>
        );
    }

    return (
        <Container maxWidth={false}>

            <Grid container spacing={3} alignItems="stretch" justifyContent="center">
                {/* Beam Inputs */}
                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '30px',
                            '& .MuiTextField-root': { mb: 2 },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#1c2734',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#9DC183',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#b9b9b9',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#9ca3af',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#9ca3af',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#9ca3af',
                            },
                        }}
                    >
                        {/* Element Input */}
                        <Card sx={{ ...transferFunctionCardStyle }}>
                            <CardContent>
                                <Typography variant="h6" sx={headerTextStyle}>
                                    Add Element
                                </Typography>
                                <TextField
                                    label="Start (x, y)"
                                    value={newElement.start}
                                    onChange={(e) => setNewElement({...newElement, start: e.target.value})}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="End (x, y)"
                                    value={newElement.end}
                                    onChange={(e) => setNewElement({...newElement, end: e.target.value})}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="EA (stiffness)"
                                    value={newElement.EA}
                                    onChange={(e) => setNewElement({...newElement, EA: e.target.value})}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="EI (flexural rigidity)"
                                    value={newElement.EI}
                                    onChange={(e) => setNewElement({...newElement, EI: e.target.value})}
                                    fullWidth
                                    margin="normal"
                                />
                                <Button
                                    variant="contained"
                                    sx={{ mt: 1, backgroundColor: '#15a2fa', '&:hover': { backgroundColor: '#9DC183' }}}
                                    onClick={handleAddElement}
                                    fullWidth
                                >
                                    Add Element
                                </Button>
                            </CardContent>
                        </Card>
                        {/* Support Input */}
                        <Card sx={{ ...transferFunctionCardStyle, mt: 2 }}>
                            <CardContent>
                                <Typography variant="h6" sx={headerTextStyle}>
                                    Add Support
                                </Typography>
                                <TextField
                                    label="Node ID"
                                    value={newSupport.node_id}
                                    onChange={(e) => setNewSupport({...newSupport, node_id: e.target.value})}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Type (fixed | hinged | roll | spring)"
                                    value={newSupport.type}
                                    onChange={(e) => setNewSupport({...newSupport, type: e.target.value})}
                                    fullWidth
                                    margin="normal"
                                />
                                {newSupport.type === 'roll' && (
                                    <TextField
                                        label="Direction (1 for X, 2 for Y)"
                                        value={newSupport.direction}
                                        onChange={(e) => setNewSupport({...newSupport, direction: e.target.value})}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                                {newSupport.type === 'spring' && (
                                    <>
                                        <TextField
                                            label="Translation (1, 2, or 3)"
                                            value={newSupport.translation}
                                            onChange={(e) => setNewSupport({...newSupport, translation: e.target.value})}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Spring constant (k)"
                                            value={newSupport.k}
                                            onChange={(e) => setNewSupport({...newSupport, k: e.target.value})}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </>
                                )}
                                <Button
                                    variant="contained"
                                    sx={{ mt: 1, backgroundColor: '#15a2fa', '&:hover': { backgroundColor: '#9DC183' }}}
                                    onClick={handleAddSupport}
                                    fullWidth
                                >
                                    Add Support
                                </Button>
                            </CardContent>
                        </Card>
                        {/* Load Input */}
                        <Card sx={{ ...transferFunctionCardStyle, mt: 2 }}>
                            <CardContent>
                                <Typography variant="h6" sx={headerTextStyle}>
                                    Add Load
                                </Typography>
                                <TextField
                                    label="Node ID (for point load)"
                                    value={newLoad.node_id}
                                    onChange={(e) => setNewLoad({...newLoad, node_id: e.target.value})}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Element ID (for distributed load)"
                                    value={newLoad.element_id}
                                    onChange={(e) => setNewLoad({...newLoad, element_id: e.target.value})}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Type (point | distributed | moment)"
                                    value={newLoad.type}
                                    onChange={(e) => setNewLoad({...newLoad, type: e.target.value})}
                                    fullWidth
                                    margin="normal"
                                />
                                {
                                    newLoad.type === 'point' && (
                                        <>
                                            <TextField
                                                label="Fx (force in x direction)"
                                                value={newLoad.Fx}
                                                onChange={(e) => setNewLoad({...newLoad, Fx: e.target.value})}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Fy (force in y direction)"
                                                value={newLoad.Fy}
                                                onChange={(e) => setNewLoad({...newLoad, Fy: e.target.value})}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </>
                                    )
                                }

                                {
                                    newLoad.type === 'distributed' && (
                                        <TextField
                                            label="q (distributed load)"
                                            value={newLoad.q}
                                            onChange={(e) => setNewLoad({...newLoad, q: e.target.value})}
                                            fullWidth
                                            margin="normal"
                                        />
                                    )
                                }
                                {newLoad.type === 'moment' && (
                                    <TextField
                                        label="Ty (moment)"
                                        value={newLoad.Ty}
                                        onChange={(e) => setNewLoad({...newLoad, Ty: e.target.value})}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                                <Button
                                    variant="contained"
                                    sx={{ mt: 1, backgroundColor: '#15a2fa', '&:hover': { backgroundColor: '#9DC183' }}}
                                    onClick={handleAddLoad}
                                    fullWidth
                                >
                                    Add Load
                                </Button>
                            </CardContent>
                        </Card>
                        <Box sx={{ width: '100%', marginTop: '30px', textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 3, mb: 2, backgroundColor: '#15a2fa', '&:hover': { backgroundColor: '#9DC183' }}}
                                onClick={handleSubmitBeam}
                                fullWidth
                            >
                                Analyze Beam
                            </Button>
                        </Box>
                    </Box>
                </Grid>

                {/* Beam Plots */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ width: '100%', marginTop: '30px' }}>
                        {beamAnalysisImages.map((imageUrl, index) => (
                            <img key={index} src={imageUrl} alt={`Beam Analysis Plot ${index + 1}`} style={{ width: '100%', height: 'auto', borderRadius: '10px', marginBottom:'20px'}} />
                        ))}
                    </Box>
                </Grid>

                {/* Beam Blog */}
                <Grid item xs={12} md={3}>
                    <Box sx={{ marginTop: '0px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '14px',
                            }}
                        >
                            <Card
                                sx={{
                                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                                    backgroundColor: '#353535',
                                    borderRadius: '15px',
                                    border: '2px solid #35353500',
                                    width: '100%',
                                    mt: 2,
                                }}
                            >
                                <CardContent sx={{padding:'0px 0px'}}>
                                    <BeamBlogPosts/>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Helmet>
                <title>NebulaSolver - Beam Analysis</title>
                <link rel="canonical" href={`https://nebulasolver.com/beam`}/>
                <meta property="og:title" content="NebulaSolver - Beam Analysis"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png"/>
                <meta property="og:url" content="https://nebulasolver.com/beam"/>
                <meta property="og:description"
                      content="NebulaSolver - Beam Analysis. Generate beam diagrams using the finite element method."/>
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/beam`}/>
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/beam`}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="NebulaSolver - Beam Analysis"/>
                <meta name="twitter:description"
                      content="NebulaSolver - Beam Analysis. Generate beam diagrams using the finite element method."/>
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png"/>
                <meta name="twitter:site" content="@nebulasolver"/>
            </Helmet>
        </Container>
    );
};

const cardStyleTransferFunction = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px',
    // padding:'60px'
};

const cardStyle = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px',
    padding:'0px'
};

const bodePlotCardStyle = {
    ...cardStyle, // Reuse the base styles and add specific styles for the BodeBlogPost plot card below
    // maxWidth: 345, // Set a max-width for larger screens
    margin: 'auto', // Center the card
    // padding: '50px'
};

const bodePlotImageStyle = {
    width: '100%', // Make the image responsive
    height: 'auto', // Maintain aspect ratio
    borderRadius:'10px',
};

const transferFunctionCardStyle = {
    ...cardStyleTransferFunction, // Reuse the base styles and add specific styles for the BodeBlogPost plot card below
    // maxWidth: 345, // Set a max-width for larger screens
    margin: 'auto', // Center the card
};

const headerTextStyle = {
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    // textTransform: 'uppercase',
    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: `${rainbowAnimation} 10s ease infinite`,
    backgroundSize: '200% 200%',
    '@media (max-width:600px)': {
        fontSize: '1.2em',
    },
    marginBottom: '10px',
};

export default Beam;
