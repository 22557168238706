import React, { useState } from 'react';
import { Handle } from 'reactflow';

const handleStyle = { width: 10, height: 10, background: '#fff' };
const labelStyle = { textAlign: 'center', width: '100%', padding: '0px', borderRadius: '5px', boxSizing: 'border-box' };

export const InputNode = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, width: '100px', height: '36px', }}>
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={labelStyle} />
        </div>
    );
};

export const SumNodePlusMinusNull = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, borderRadius: '50%', width: '50px', height: '50px', textAlign: 'center', border: '2px solid #b9b9b9' }}>
            <Handle type="target" position="left" id="input1" style={handleStyle} />
            <Handle type="target" position="bottom" id="input2" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={{ ...labelStyle, width: '100%', height: '20px', border: 'none' }} />
        </div>
    );
};

export const SumNodePlusPlusPlus = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, borderRadius: '50%', width: '50px', height: '50px', textAlign: 'center', border: '2px solid #b9b9b9' }}>
            <Handle type="target" position="left" id="input1" style={handleStyle} />
            <Handle type="target" position="bottom" id="input2" style={handleStyle} />
            <Handle type="target" position="top" id="input3" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={{ ...labelStyle, width: '100%', height: '20px', border: 'none' }} />
        </div>
    );
};

export const SumNodePlusPlusMinus = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, borderRadius: '50%', width: '50px', height: '50px', textAlign: 'center', border: '2px solid #b9b9b9' }}>
            <Handle type="target" position="left" id="input1" style={handleStyle} />
            <Handle type="target" position="bottom" id="input2" style={handleStyle} />
            <Handle type="target" position="top" id="input3" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={{ ...labelStyle, width: '100%', height: '20px', border: 'none' }} />
        </div>
    );
};

export const SumNodePlusMinusMinus = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, borderRadius: '50%', width: '50px', height: '50px', textAlign: 'center', border: '2px solid #b9b9b9' }}>
            <Handle type="target" position="left" id="input1" style={handleStyle} />
            <Handle type="target" position="bottom" id="input2" style={handleStyle} />
            <Handle type="target" position="top" id="input3" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={{ ...labelStyle, width: '100%', height: '20px', border: 'none' }} />
        </div>
    );
};

export const SumNodePlusMinusPlus = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, borderRadius: '50%', width: '50px', height: '50px', textAlign: 'center', border: '2px solid #b9b9b9' }}>
            <Handle type="target" position="left" id="input1" style={handleStyle} />
            <Handle type="target" position="bottom" id="input2" style={handleStyle} />
            <Handle type="target" position="top" id="input3" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={{ ...labelStyle, width: '100%', height: '20px', border: 'none' }} />
        </div>
    );
};

export const SumNodePlusPlusNull = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, borderRadius: '50%', width: '50px', height: '50px', textAlign: 'center', border: '2px solid #b9b9b9' }}>
            <Handle type="target" position="left" id="input1" style={handleStyle} />
            <Handle type="target" position="bottom" id="input2" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={{ ...labelStyle, width: '100%', height: '20px', border: 'none' }} />
        </div>
    );
};

export const SumNodePlusNullMinus = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, borderRadius: '50%', width: '50px', height: '50px', textAlign: 'center', border: '2px solid #b9b9b9' }}>
            <Handle type="target" position="left" id="input1" style={handleStyle} />
            <Handle type="target" position="top" id="input2" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={{ ...labelStyle, width: '100%', height: '20px', border: 'none' }} />
        </div>
    );
};

export const SumNodePlusNullPlus = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, borderRadius: '50%', width: '50px', height: '50px', textAlign: 'center', border: '2px solid #b9b9b9' }}>
            <Handle type="target" position="left" id="input1" style={handleStyle} />
            <Handle type="target" position="top" id="input2" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={{ ...labelStyle, width: '100%', height: '20px', border: 'none' }} />
        </div>
    );
};

export const PIDNode = ({ data }) => {
    const [label, setLabel] = useState(data.label);
    const [num, setNum] = useState(data.num || '');
    const [den, setDen] = useState(data.den || '');

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    const handleNumChange = (event) => {
        setNum(event.target.value);
        data.num = event.target.value;
    };

    const handleDenChange = (event) => {
        setDen(event.target.value);
        data.den = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, width: '200px', height: '86px', }}>
            <Handle type="target" position="left" id="input" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={labelStyle} />
            <input type="text" value={num} onChange={handleNumChange} placeholder="Num" style={labelStyle} />
            <input type="text" value={den} onChange={handleDenChange} placeholder="Den" style={labelStyle} />
        </div>
    );
};

export const PlantNode = ({ data }) => {
    const [label, setLabel] = useState(data.label);
    const [num, setNum] = useState(data.num || '');
    const [den, setDen] = useState(data.den || '');

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    const handleNumChange = (event) => {
        setNum(event.target.value);
        data.num = event.target.value;
    };

    const handleDenChange = (event) => {
        setDen(event.target.value);
        data.den = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, width: '200px', height: '86px', }}>
            <Handle type="target" position="left" id="input" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={labelStyle} />
            <input type="text" value={num} onChange={handleNumChange} placeholder="Num" style={labelStyle} />
            <input type="text" value={den} onChange={handleDenChange} placeholder="Den" style={labelStyle} />
        </div>
    );
};

export const ScopeNode = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, width: '100px', height: '36px', }}>
            <Handle type="target" position="left" id="input" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={labelStyle} />
        </div>
    );
};

export const GainNode = ({ data }) => {
    const [label, setLabel] = useState(data.label);

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, width: '100px', height: '36px', }}>
            <Handle type="target" position="right" id="input" style={handleStyle} />
            <Handle type="source" position="left" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={labelStyle} />
        </div>
    );
};

export const TFNodeRight = ({ data }) => {
    const [label, setLabel] = useState(data.label);
    const [num, setNum] = useState(data.num || '');
    const [den, setDen] = useState(data.den || '');

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    const handleNumChange = (event) => {
        setNum(event.target.value);
        data.num = event.target.value;
    };

    const handleDenChange = (event) => {
        setDen(event.target.value);
        data.den = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, width: '200px', height: '86px', }}>
            <Handle type="target" position="left" id="input" style={handleStyle} />
            <Handle type="source" position="right" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={labelStyle} />
            <input type="text" value={num} onChange={handleNumChange} placeholder="Num" style={labelStyle} />
            <input type="text" value={den} onChange={handleDenChange} placeholder="Den" style={labelStyle} />
        </div>
    );
};

export const TFNodeLeft = ({ data }) => {
    const [label, setLabel] = useState(data.label);
    const [num, setNum] = useState(data.num || '');
    const [den, setDen] = useState(data.den || '');

    const handleChange = (event) => {
        setLabel(event.target.value);
        data.label = event.target.value;
    };

    const handleNumChange = (event) => {
        setNum(event.target.value);
        data.num = event.target.value;
    };

    const handleDenChange = (event) => {
        setDen(event.target.value);
        data.den = event.target.value;
    };

    return (
        <div style={{ ...labelStyle, width: '200px', height: '86px', }}>
            <Handle type="target" position="right" id="input" style={handleStyle} />
            <Handle type="source" position="left" id="output" style={handleStyle} />
            <input type="text" value={label} onChange={handleChange} style={labelStyle} />
            <input type="text" value={num} onChange={handleNumChange} placeholder="Num" style={labelStyle} />
            <input type="text" value={den} onChange={handleDenChange} placeholder="Den" style={labelStyle} />
        </div>
    );
};
