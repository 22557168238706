/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Grid, Typography, Box, Button, Card, CardContent, CardActionArea, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import CalculateIcon from '@mui/icons-material/Calculate';
import FunctionsIcon from '@mui/icons-material/Functions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CasinoIcon from '@mui/icons-material/Casino';
import BarChartIcon from '@mui/icons-material/BarChart';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TimelineIcon from '@mui/icons-material/Timeline';
import GradientIcon from '@mui/icons-material/Gradient';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import DataArrayIcon from '@mui/icons-material/DataArray';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import Data from "bootstrap/js/src/dom/data";
import { Helmet } from 'react-helmet';

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Define a pulse animation for buttons
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const nebulaSolverURL = 'https://i.imgur.com/4i2qa7a.png';
const computationalToolsURL = 'https://i.imgur.com/hSo742v.png';
const uiURL = 'https://i.imgur.com/2pzi1RD.png';
const applicationImageURL = 'https://i.imgur.com/zcImEpk.png';

// Placeholder variables for media in the sub cards
const imgMediaURL1 = 'https://i.imgur.com/N1ngMWk.png';
const imgMediaURL2 = 'https://i.imgur.com/4zHUORM.png';
const imgMediaURL3 = 'https://i.imgur.com/VAB5sPa.png';
const imgMediaURL4 = 'https://i.imgur.com/QgjWhBE.png';
const imgMediaURL5 = 'https://i.imgur.com/eHxzK6T.png';
const imgMediaURL6 = 'https://i.imgur.com/w2HYgtq.png';
const imgMediaURL7 = 'https://i.imgur.com/BVmFhcF.png';
const imgMediaURL8 = 'https://i.imgur.com/BVmFhcF.png';
const imgMediaURL9 = 'https://i.imgur.com/BVmFhcF.png';

// Routes for the sub cards

const routeURL1 = '/circuit';
const routeURL2 = '/thermoproperties';
const routeURL3 = '/cylces';
const routeURL4 = '/rootlocus';
const routeURL5 = '/polezeromap';
const routeURL6 = '/stepresponse';
const routeURL7 = '/impulseresponse';

const Electronics = () => {
    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>NebulaSolver - Electronics Management</title>
                <link rel="canonical" href={`https://nebulasolver.com/electronics`}/>
                <meta property="og:title" content="NebulaSolver - Electronics Management"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png"/>
                <meta property="og:url" content="https://nebulasolver.com/electronics"/>
                <meta property="og:description" content="NebulaSolver - Cloud-Based Electronics Management Analysis"/>
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/electronics`}/>
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/electronics`}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="NebulaSolver - Electronics Management"/>
                <meta name="twitter:description" content="NebulaSolver - Cloud-Based Electronics Management Analysis."/>
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png"/>
                <meta name="twitter:site" content="@nebulasolver"/>
            </Helmet>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={2}>
                    {/* Space for additional content or sidebar */}
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box
                        sx={{
                            marginTop: '30px',
                            // paddingTop: '30px'
                        }}
                    >
                        <FeatureCard
                            title="Electronics"
                            description="The Electronics page provides tools to simulate and analyze electronic circuits effortlessly. Whether you’re a student, researcher, or professional, our app enables you to input SPICE netlists to test and optimize circuit designs digitally. Generate detailed plots to visualize voltage, current, and power across components, ensuring accurate and reliable results. With NebulaSolver, you can enhance your circuit designs, improve educational projects, and streamline your engineering workflows with precision and ease."
                        />
                        <CallToActionCard />
                    </Box>

                </Grid>
                <Grid item xs={12} md={2}>
                    {/* Space for additional content or sidebar */}
                </Grid>
            </Grid>
        </Container>
    );
};

const HeaderCard = () => (
    <Card sx={cardStyle}>
        <CardContent>
            <Typography variant="h3" sx={headerTextStyle}>
                Nebula Solver
            </Typography>
            <Typography variant="subtitle1" sx={{ color: '#b8b8b8', textAlign: 'center' }}>
                The cloud-based app for engineering and mathematical computations. Simplify, solve, and innovate with ease.
            </Typography>
        </CardContent>
    </Card>
);

// Updated FeatureCard to include a 3x3 grid of sub cards
const FeatureCard = ({ title, description }) => {
    const subCards = [
        {
            title: "Circuit Simulation",
            description: "Generate Electronic Circuit Simulations",
            image: imgMediaURL1,
            route: routeURL1
        },
        // {
        //     title: "Thermodynamic Properties",
        //     description: "Calculate Thermodynamic Properties.",
        //     image: imgMediaURL2,
        //     route: routeURL2
        // },
        // {
        //     title: "Thermodynamic Cycles",
        //     description: "Generate Thermodynamic Cycles",
        //     image: imgMediaURL3,
        //     route: routeURL3
        // },
        // {
        //     title: "Root Locus",
        //     description: "Generate Root Locus Plot",
        //     image: imgMediaURL4,
        //     route: routeURL4
        // },
        // {
        //     title: "Pole Zero Map",
        //     description: "Generate Pole Zero Map.",
        //     image: imgMediaURL5,
        //     route: routeURL5
        // },
        // {
        //     title: "Step Response",
        //     description: "Generate Step Response Plot.",
        //     image: imgMediaURL6,
        //     route: routeURL6
        // },
        // {
        //     title: "Impulse Response",
        //     description: "Generate Impulse Response Plot.",
        //     image: imgMediaURL7,
        //     route: routeURL7
        // },
        // {
        //     title: "Placeholder",
        //     description: "Placeholder.",
        //     image: imgMediaURL8,
        //     route: routeURL8
        // },
        // {
        //     title: "Placeholder",
        //     description: "Placeholder.",
        //     image: imgMediaURL9,
        //     route: routeURL9
        // },
        // ... add other sub cards here with their respective icons and routes
    ];
    return (
        <Card sx={cardStyle}>
            <CardContent>
                <Typography
                    variant="h6"
                    sx={{
                        marginBottom: 3,
                        textAlign: 'center',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 'bold',
                        letterSpacing: '0.05em',
                        // textTransform: 'uppercase',
                        background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        animation: `${rainbowAnimation} 10s ease infinite`,
                        backgroundSize: '200% 200%',
                        '@media (max-width:600px)': {
                            fontSize: '1.2em',
                        },
                    }}
                >
                    {title}
                </Typography>
                <Typography variant="body1" sx={{ color: '#b8b8b8', marginBottom: '20px' }}>
                    {description}
                </Typography>
                <Grid container spacing={2}>
                    {subCards.map((card, index) => (
                        <Grid item xs={4} key={index}>
                            <SubCard
                                title={card.title}
                                description={card.description}
                                image={card.image}
                                route={card.route}
                            />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};


// SubCard component
const SubCard = ({ title, description, image, route }) => (
    <CardActionArea component={Link} to={route} sx={{ width: '100%', height: 180, margin: 'auto'}}>
        <Card
            elevation={0}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                backgroundColor:'#353535',
                // border: '2px solid #ffc801',
            }}
        >
            {/* Added style for the image */}
            <CardMedia
                component="img"
                image={
                    image
                }
                alt={title}
                sx={{
                    maxHeight: 120, // Maximum image height
                    maxWidth: '100%', // Maximum image width
                    objectFit: 'contain', // Keep the aspect ratio
                }}
            />
            <CardContent>
                <Typography variant="subtitle1" sx={{ textAlign: 'center', color:'#b9b9b9' }}>
                    {title}
                </Typography>
                {/* Description can be included if needed */}
            </CardContent>
        </Card>
    </CardActionArea>
);


const CallToActionCard = () => (
    <Card sx={cardStyle}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ marginBottom: '28px', color:'#fffffe', textAlign: 'center' }}>
                Ready to Simplify Your Numerical Challenges?
            </Typography>
            <CallToAction />
        </CardContent>
    </Card>
);

const CallToAction = () => (
    <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/subscribe"
        sx={{
            backgroundColor: '#15a2fa',
            '&:hover': {
                backgroundColor: '#9DC183',
                transform: 'scale(1.05)',
            },
            padding: '10px 20px',
            transition: 'all 0.3s ease',
            fontSize: '1rem',
            fontWeight: 'bold',
            animation: `${pulseAnimation} 2s infinite`,
        }}
    >
        Get Started
    </Button>
);

const cardStyle = {
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    marginBottom: '20px',
    overflow: 'hidden',
};

const headerTextStyle = {
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: `${rainbowAnimation} 10s ease infinite`,
    backgroundSize: '200% 200%',
};

const callToActionStyle = {
    backgroundColor: '#15a2fa',
    '&:hover': { backgroundColor: '#9DC183', transform: 'scale(1.05)' },
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    fontSize: '1rem',
    fontWeight: 'bold',
};

export default Electronics;
