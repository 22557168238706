/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Grid, Typography, Box, Button, Card, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { GiLogicGateAnd } from "react-icons/gi";
import { PiMathOperationsFill } from "react-icons/pi";
import { TbBrandMatrix, TbCircuitDiode } from "react-icons/tb";
import { MdControlPoint } from "react-icons/md";
import { FaRegSnowflake, FaProjectDiagram } from "react-icons/fa";
import { RiBattery2ChargeFill } from "react-icons/ri";
import { FaChartGantt, FaArrowTrendUp } from "react-icons/fa6";
import { TbCircuitMotor } from "react-icons/tb";
import TrussIcon from './truss-v3.png'; // Import your custom icon
import { FaWpforms } from "react-icons/fa";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Define a pulse animation for buttons
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const routeURL1 = '/logic';
const routeURL2 = '/equations';
const routeURL3 = '/matrix';
const routeURL4 = '/controlsystems';
const routeURL5 = '/finiteelements';
const routeURL6 = '/thermodynamics';
const routeURL7 = '/batterymanagement';
const routeURL8 = '/electronics';
const routeURL9 = '/systems';
const routeURL10 = '/diagrams';
const routeURL11 = '/gantt';
const routeURL12 = '/stocks';
const routeURL13 = '/resume';

const Home = () => {
    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>NebulaSolver - Home</title>
                <meta property="og:title" content="NebulaSolver - Cloud-Based Math Computations" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/" />
                <meta property="og:description" content="NebulaSolver - Cloud-Based Math Computations. Tackle boolean logic, non-linear equations, linear algebra, control systems and more." />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/`} />
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="NebulaSolver - Cloud-Based Math Computations" />
                <meta name="twitter:description" content="NebulaSolver - Cloud-Based Math Computations. Tackle boolean logic, non-linear equations, linear algebra, control systems and more." />
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={2}>
                    {/* Space for additional content or sidebar */}
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box sx={{ marginTop: '30px' }}>
                        <FeatureCard
                            title="Cloud-Based Math Solutions"
                            description="NebulaSolver (Numerical Engine, Boolean Utility, and Linear Algebra) harnesses the power of cloud computing to streamline engineering and mathematical computations. Whether you're a student tackling complex homework problems, a researcher exploring new theories, or a professional solving real-world challenges, our technology is designed to meet your needs."
                        />
                        <CallToActionCard />
                    </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                    {/* Space for additional content or sidebar */}
                </Grid>
            </Grid>
        </Container>
    );
};

const HeaderCard = () => (
    <Card sx={cardStyle}>
        <CardContent>
            <Typography variant="h3" sx={headerTextStyle}>
                Nebula Solver
            </Typography>
            <Typography variant="subtitle1" sx={{ color: '#b8b8b8', textAlign: 'center' }}>
                The cloud-based app for engineering and mathematical computations. Simplify, solve, and innovate with ease.
            </Typography>
        </CardContent>
    </Card>
);

// Updated FeatureCard to include a 3x3 grid of sub cards
const FeatureCard = ({ title, description }) => {
    const subCards = [
        { title: "Logic Solver", description: "Solve logical expressions and equations with ease.", Icon: GiLogicGateAnd, route: routeURL1 },
        { title: "Equation Solver", description: "Find solutions to complex mathematical equations.", Icon: PiMathOperationsFill, route: routeURL2 },
        { title: "Linear Algebra", description: "Perform calculations and operations in linear algebra.", Icon: TbBrandMatrix, route: routeURL3 },
        { title: "Control Systems", description: "Design and analyze control systems efficiently.", Icon: MdControlPoint, route: routeURL4 },
        { title: "Finite Elements", description: "Finite Element Analysis.", Icon: null, CustomIcon: TrussIcon, route: routeURL5 },
        { title: "Thermodynamics", description: "Thermodynamics.", Icon: FaRegSnowflake, route: routeURL6 },
        { title: "Battery Management", description: "Battery Management.", Icon: RiBattery2ChargeFill, route: routeURL7 },
        { title: "Electronics", description: "Electronic Circuit Simulations.", Icon: TbCircuitDiode, route: routeURL8 },
        { title: "Systems", description: "Systems Engineering.", Icon: TbCircuitMotor, route: routeURL9 },
        { title: "Diagrams", description: "Diagram Tool.", Icon: FaProjectDiagram, route: routeURL10 },
        { title: "Gantt Chart", description: "Build a Gantt Chart quickly.", Icon: FaChartGantt, route: routeURL11 },
        { title: "Stock Analysis", description: "Explore Stock Market Cap, Moving Average and Volatility", Icon: FaArrowTrendUp, route: routeURL12 },
        { title: "Resume Builder", description: "Generate a Resume in PDF format", Icon: FaWpforms, route: routeURL13 },
    ];
    return (
        <Card sx={cardStyle}>
            <CardContent>
                <Typography
                    variant="h6"
                    sx={{
                        marginBottom: 3,
                        textAlign: 'center',
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 'bold',
                        letterSpacing: '0.05em',
                        background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        animation: `${rainbowAnimation} 10s ease infinite`,
                        backgroundSize: '200% 200%',
                        '@media (max-width:600px)': { fontSize: '1.2em' },
                    }}
                >
                    {title}
                </Typography>
                <Typography variant="body1" sx={{ color: '#b8b8b8', marginBottom: '20px' }}>
                    {description}
                </Typography>
                <Grid container spacing={2}>
                    {subCards.map((card, index) => (
                        <Grid item xs={6} sm={4} md={4} key={index}>
                            <SubCard title={card.title} description={card.description} Icon={card.Icon} CustomIcon={card.CustomIcon} route={card.route} />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

// SubCard component
const SubCard = ({ title, description, Icon, CustomIcon, route }) => (
    <CardActionArea component={Link} to={route} sx={{ width: '100%', height: 180, margin: 'auto' }}>
        <Card elevation={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', backgroundColor:'#353535' }}>
            {Icon ? <Icon style={{ fontSize: '3.5rem', color: '#ffc801' }} /> : <img src={CustomIcon} alt={title} style={{ width: '3.5rem', height: '3.5rem' }} />}
            <CardContent>
                <Typography variant="subtitle1" sx={{ textAlign: 'center', color:'#fffffe' }}>{title}</Typography>
            </CardContent>
        </Card>
    </CardActionArea>
);

const CallToActionCard = () => (
    <Card sx={cardStyle}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ marginBottom: '28px', color:'#fffffe', textAlign: 'center' }}>
                Ready to Simplify Your Numerical Challenges?
            </Typography>
            <CallToAction />
        </CardContent>
    </Card>
);

const CallToAction = () => (
    <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/subscribe"
        sx={{
            backgroundColor: '#15a2fa',
            '&:hover': { backgroundColor: '#9DC183', transform: 'scale(1.05)' },
            padding: '10px 20px',
            transition: 'all 0.3s ease',
            fontSize: '1rem',
            fontWeight: 'bold',
            animation: `${pulseAnimation} 2s infinite`,
        }}
    >
        Get Started
    </Button>
);

const cardStyle = {
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    marginBottom: '20px',
    overflow: 'hidden',
};

const headerTextStyle = {
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: `${rainbowAnimation} 10s ease infinite`,
    backgroundSize: '200% 200%',
};

export default Home;
