/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// src/components/DiagramRete.jsx
import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { NodeEditor, ClassicPreset } from 'rete';
import { AreaPlugin, AreaExtensions } from 'rete-area-plugin';
import { ConnectionPlugin, Presets as ConnectionPresets } from 'rete-connection-plugin';
import { ReactPlugin, Presets, ReactArea2D } from 'rete-react-plugin';
import { CustomNodeInstance } from './NodesRete'; // Import CustomNodeInstance

const DiagramRete = () => {
    const containerRef = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function createEditor() {
            console.log('Creating editor');
            const container = containerRef.current;
            if (!container) {
                console.error('Container not found');
                return;
            }
            console.log('Container found:', container);

            const socket = new ClassicPreset.Socket("socket");

            const editor = new NodeEditor('retejs@0.1.0');
            const area = new AreaPlugin(container);
            const connection = new ConnectionPlugin();
            const render = new ReactPlugin({ createRoot });

            AreaExtensions.selectableNodes(area, AreaExtensions.selector(), {
                accumulating: AreaExtensions.accumulateOnCtrl(),
            });

            render.addPreset(Presets.classic.setup());
            connection.addPreset(ConnectionPresets.classic.setup());

            editor.use(area);
            area.use(connection);
            area.use(render);

            AreaExtensions.simpleNodesOrder(area);

            const a = new ClassicPreset.Node("A");
            a.addControl("a", new ClassicPreset.InputControl("text", { initial: "a" }));
            a.addOutput("a", new ClassicPreset.Output(socket));
            await editor.addNode(a);

            const b = new ClassicPreset.Node("B");
            b.addControl("b", new ClassicPreset.InputControl("text", { initial: "b" }));
            b.addInput("b", new ClassicPreset.Input(socket));
            await editor.addNode(b);

            await editor.addConnection(new ClassicPreset.Connection(a, "a", b, "b"));

            // Add Custom Node
            await editor.addNode(CustomNodeInstance);
            const customNode = editor.nodes.find(node => node.name === 'Custom Node');
            if (customNode) {
                await area.translate(customNode.id, { x: 500, y: 0 });
            }

            setTimeout(() => {
                // wait until nodes rendered because they don't have predefined width and height
                AreaExtensions.zoomAt(area, editor.getNodes());
                setLoading(false); // Hide the loading message
            }, 10);

            return {
                destroy: () => area.destroy(),
            };
        }

        createEditor().catch(console.error);
    }, []);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100vh', backgroundColor: 'lightgrey' }}>
            {loading && <h1>Loading Rete Editor...</h1>}
        </div>
    );
};

export default DiagramRete;
