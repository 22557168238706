import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';

function CircleNodeDown({ data }) {
    const [label, setLabel] = useState(data.label || '');

    return (
        <div style={{ padding: 10, borderRadius: '50%', background: 'transparent', color: '#fff', border: '1px solid #fff', height: '75px', width: '75px' }}>
            <input
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                style={{ width: '100%', background: 'transparent', border: 'none', color: '#fff', textAlign:'center' }}
            />
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
        </div>
    );
}

export default memo(CircleNodeDown);
