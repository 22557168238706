/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Container, TextField, Button, Typography, Card, CardContent, Grid, CircularProgress, CardMedia, DeleteIcon, Link } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { keyframes } from '@emotion/react';
import { Link as RouterLink } from 'react-router-dom';
import JSZip from 'jszip';
import CircuitBlogPosts from "../components/HowTosCircuit/CircuitBlogPosts";
import {Helmet} from "react-helmet";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Define a pulse animation for buttons
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const PricingBox = () => (
    <Grid>
        <Box
            sx={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#353535',
                    borderRadius: '15px',
                    border: '2px solid #35353500',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                            marginBottom: '10px',
                        }}
                    >
                        Pricing
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{ color: '#9DC183', marginBottom: '20px' }}
                        >
                            $9.99 / year
                        </Typography>
                        <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
                            {[
                                'Full access to all the apps',
                                'Free articles and tutorials',
                                'No need to install anything',
                                'Auth0 Authentication',
                                'Stripe Payments Integration'
                            ].map((item) => (
                                <Typography
                                    key={item}
                                    variant="h6"
                                    sx={{ color: '#b9b9b9', marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <i className="fas fa-check-circle" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', backgroundColor: '#353535' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                        Get NebulaSolver
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, color: '#b9b9b9' }}>
                        Subscribe to access the Electronic Circuit Simulator.
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/subscribe"
                        sx={{
                            backgroundColor: '#15a2fa',
                            '&:hover': {
                                backgroundColor: '#9DC183',
                            },
                            width: '50%',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            animation: `${pulseAnimation} 2s infinite`,
                        }}
                    >
                        Go to Subscribe
                    </Button>
                </CardContent>
            </Card>
        </Box>
    </Grid>
);

// Define a hover animation for links
const hoverAnimation = keyframes`
  0% { color: #fffffe; }
  100% { color: #9DC183; }
`;

const MoreByNebulaSolver = () => (
    <Box sx={{ marginTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                <CardContent sx={{ padding: '14px 0px' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                        More by NebulaSolver
                    </Typography>
                    <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: '400px' }}>
                        {[
                            { href: "https://nebulasolver.com/equations", text: "Equation Solver" },
                            { href: "https://nebulasolver.com/matrix", text: "Linear Algebra" },
                            { href: "https://nebulasolver.com/controlsystems", text: "Control Systems" },
                            { href: "https://nebulasolver.com/finiteelements", text: "Finite Elements" },
                            { href: "https://nebulasolver.com/thermodynamics", text: "Thermodynamics" },
                            { href: "https://nebulasolver.com/batterymanagement", text: "Battery Management" },
                            { href: "https://nebulasolver.com/electronics", text: "Electronics" },
                            { href: "https://nebulasolver.com/systems", text: "Systems Engineering" },
                            { href: "https://nebulasolver.com/diagrams", text: "Diagrams" },
                            { href: "https://nebulasolver.com/gantt", text: "Gantt Chart" },
                            { href: "https://nebulasolver.com/stocks", text: "Stock Analysis" }
                        ].map(item => (
                            <Link
                                key={item.href}
                                href={item.href}
                                sx={{
                                    display: 'block',
                                    mb: 1,
                                    color: '#fffffe',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                {item.text}
                            </Link>
                        ))}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Box>
);

const Circuit = () => {
    const { user, isAuthenticated, isLoading: authLoading, getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState('');
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);
    const [newNode, setNewNode] = useState('');
    const [circuitImages, setCircuitImages] = useState([]);
    const [circuitConfig, setCircuitConfig] = useState({
        netlist: '',
        nodes: []
    });

    // Function to handle netlist text change
    const handleNetlistChange = (e) => {
        setCircuitConfig(prevConfig => ({
            ...prevConfig,
            netlist: e.target.value
        }));
    };

    const validateAndFormatNetlist = () => {
        // Add an empty line and prepend four spaces to each line of the netlist
        const formattedNetlist = '\n' + circuitConfig.netlist
            .split('\n')
            .map(line => '    ' + line)
            .join('\n');
        return formattedNetlist;
    };

    // Function to add a new node for plotting
    const handleAddNode = () => {
        if (newNode.trim() !== '' && !circuitConfig.nodes.includes(newNode.trim())) {
            setCircuitConfig(prevConfig => ({
                ...prevConfig,
                nodes: [...prevConfig.nodes, newNode.trim()]
            }));
            setNewNode(''); // Reset the input after adding
        }
    };

    // Function to remove a node from plotting
    const handleRemoveNode = (nodeIndex) => {
        setCircuitConfig(prevConfig => ({
            ...prevConfig,
            nodes: prevConfig.nodes.filter((_, index) => index !== nodeIndex)
        }));
    };


    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !authLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, authLoading]);

    const handleSubmitCircuitConfig = async () => {
        if (!isAuthenticated || !user) {
            setError('User not authenticated');
            return;
        }

        const formattedNetlist = validateAndFormatNetlist();

        const payload = {
            netlist: formattedNetlist,  // Send netlist directly as it is
            nodes: circuitConfig.nodes,
        };

        try {
            const jwtResponse = await fetch('/.netlify/functions/generateJWTCircuit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userEmail: user.email }),
            });

            if (!jwtResponse.ok) {
                throw new Error(`HTTP error ${jwtResponse.status}: ${jwtResponse.statusText}`);
            }

            const jwtData = await jwtResponse.json();
            const jwtToken = jwtData.token;

            const response = await fetch('https://wbphazmuax.us-west-2.awsapprunner.com/process-json-single', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify(payload),
            });

            console.log("Response received:", response);

            if (!response.ok) {
                const errorBody = await response.text();
                throw new Error(`HTTP error! status: ${response.status} - ${errorBody}`);
            }

            const blob = await response.blob();
            console.log("Blob received:", blob);

            // Create a URL for the blob to be used in an <img> element
            const imageUrl = URL.createObjectURL(blob);
            console.log(`Generated image URL: ${imageUrl}`);

            // Update state with the new image URL
            setCircuitImages([imageUrl]); // Set the array with only one element - the new image URL

        } catch (err) {
            setError(`Error during the diagram generation: ${err.message}`);
            console.error(err);
        }
    };

    if (authLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!subscribeStatus) {
        return (
            <Container maxWidth={false}>

                <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <Box sx={{ marginTop: '0px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                                <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                                    <CardContent sx={{ padding: '0px 0px' }}>
                                        <CircuitBlogPosts />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PricingBox />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MoreByNebulaSolver />
                    </Grid>
                </Grid>
                <Helmet>
                    <title>NebulaSolver - Circuit Analysis</title>
                    <link rel="canonical" href={`https://nebulasolver.com/circuit`}/>
                    <meta property="og:title" content="NebulaSolver - Circuit Analysis"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png"/>
                    <meta property="og:url" content="https://nebulasolver.com/circuit"/>
                    <meta property="og:description"
                          content="NebulaSolver - Circuit Analysis. Simulate Electronic Circuits based off SPICE models."/>
                    <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/circuit`}/>
                    <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/circuit`}/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content="NebulaSolver - Circuit Analysis"/>
                    <meta name="twitter:description"
                          content="NebulaSolver - Circuit Analysis. Simulate Electronic Circuits based off SPICE models."/>
                    <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png"/>
                    <meta name="twitter:site" content="@nebulasolver"/>
                </Helmet>
            </Container>
        );
    }

    return (
        <Container maxWidth={false}>

            <Grid container spacing={3} alignItems="stretch" justifyContent="center">
                {/* Circuit Configuration Inputs */}
                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '30px',
                            '& .MuiTextField-root': { mb: 2 },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#1c2734',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#9DC183',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#b9b9b9',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#9ca3af',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#9ca3af',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#9ca3af',
                            },
                        }}
                    >
                        <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', padding: '20px' }}>
                            <CardContent>
                                <Typography variant="h6" sx={{ textAlign: 'center', color: 'white', mb: 2 }}>
                                    Configure Circuit Simulation
                                </Typography>
                                <TextField
                                    label="Netlist"
                                    name="netlist"
                                    value={circuitConfig.netlist}
                                    onChange={handleNetlistChange}
                                    multiline
                                    rows={10}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ style: { color: '#ddd' } }}
                                    inputProps={{ style: { color: '#b9b9b9' } }}
                                    sx={{ backgroundColor: '#424242', borderRadius: '4px' }}
                                />
                                {circuitConfig.nodes.map((node, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                                        <TextField
                                            label={`Node ${index + 1}`}
                                            value={node}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            disabled
                                            InputLabelProps={{ style: { color: '#ddd' } }}
                                            inputProps={{ style: { color: '#b9b9b9' } }}
                                            sx={{ backgroundColor: '#424242', borderRadius: '4px' }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleRemoveNode(index)}
                                            sx={{ maxHeight: '40px', backgroundColor: '#f50057', '&:hover': { backgroundColor: '#ff4081' } }}
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                ))}
                                <TextField
                                    label="New Node"
                                    value={newNode}
                                    onChange={(e) => setNewNode(e.target.value)}
                                    placeholder="Enter new node name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ style: { color: '#ddd' } }}
                                    inputProps={{ style: { color: '#b9b9b9' } }}
                                    sx={{ backgroundColor: '#424242', borderRadius: '4px' }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 3, mb: 2, backgroundColor: '#15a2fa', '&:hover': { backgroundColor: '#9DC183' } }}
                                    onClick={handleAddNode}
                                    fullWidth
                                >
                                    Add Node
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 1, backgroundColor: '#15a2fa', '&:hover': { backgroundColor: '#9DC183' } }}
                                    onClick={handleSubmitCircuitConfig}
                                    fullWidth
                                >
                                    Generate Circuit Simulation
                                </Button>
                                {error && (
                                    <Typography variant="body2" sx={{ color: 'error.main', mt: 2 }}>
                                        {error}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>



                    </Box>

                </Grid>

                {/* Circuit Simulation Diagrams */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ width: '100%', marginTop: '30px' }}>
                        {circuitImages.length > 0 && (
                            <img src={circuitImages[0]} alt="Circuit Simulation Diagram" style={{ width: '100%', height: 'auto' }} />
                        )}
                    </Box>
                </Grid>

                {/* Circuit Blog Posts */}
                <Grid item xs={12} md={3}>
                    <Box sx={{ marginTop: '0px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '14px',
                            }}
                        >
                            <Card
                                sx={{
                                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                                    backgroundColor: '#353535',
                                    borderRadius: '15px',
                                    border: '2px solid #35353500',
                                    width: '100%',
                                    mt: 2,
                                }}
                            >
                                <CardContent sx={{padding:'0px 0px'}}>
                                    <CircuitBlogPosts/>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Helmet>
                <title>NebulaSolver - Circuit Analysis</title>
                <link rel="canonical" href={`https://nebulasolver.com/circuit`}/>
                <meta property="og:title" content="NebulaSolver - Circuit Analysis"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png"/>
                <meta property="og:url" content="https://nebulasolver.com/circuit"/>
                <meta property="og:description"
                      content="NebulaSolver - Circuit Analysis. Simulate Electronic Circuits based off SPICE models."/>
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/circuit`}/>
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/circuit`}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="NebulaSolver - Circuit Analysis"/>
                <meta name="twitter:description"
                      content="NebulaSolver - Circuit Analysis. Simulate Electronic Circuits based off SPICE models."/>
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png"/>
                <meta name="twitter:site" content="@nebulasolver"/>
            </Helmet>
        </Container>
    );
};

const cardStyleTransferFunction = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px',
    // padding:'60px'
};

const cardStyle = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px',
    padding:'0px'
};

const bodePlotCardStyle = {
    ...cardStyle, // Reuse the base styles and add specific styles for the BodeBlogPost plot card below
    // maxWidth: 345, // Set a max-width for larger screens
    margin: 'auto', // Center the card
    // padding: '50px'
};

const bodePlotImageStyle = {
    width: '100%', // Make the image responsive
    height: 'auto', // Maintain aspect ratio
    borderRadius:'10px',
};

const transferFunctionCardStyle = {
    ...cardStyleTransferFunction, // Reuse the base styles and add specific styles for the BodeBlogPost plot card below
    // maxWidth: 345, // Set a max-width for larger screens
    margin: 'auto', // Center the card
};

const headerTextStyle = {
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    // textTransform: 'uppercase',
    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: `${rainbowAnimation} 10s ease infinite`,
    backgroundSize: '200% 200%',
    '@media (max-width:600px)': {
        fontSize: '1.2em',
    },
    marginBottom: '10px',
};

export default Circuit;

