/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Container, TextField, Button, Typography, Card, CardContent, Grid, CircularProgress, CardMedia, Link } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { keyframes } from '@emotion/react';
import { Link as RouterLink } from 'react-router-dom';
import JSZip from 'jszip';
import GanttBlogPosts from "../components/HowTosGantt/GanttBlogPosts";
import { Helmet } from "react-helmet";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Define a pulse animation for buttons
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const PricingBox = () => (
    <Grid>
        <Box
            sx={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#353535',
                    borderRadius: '15px',
                    border: '2px solid #35353500',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                            marginBottom: '10px',
                        }}
                    >
                        Pricing
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{ color: '#9DC183', marginBottom: '20px' }}
                        >
                            $9.99 / year
                        </Typography>
                        <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
                            {[
                                'Full access to all the apps',
                                'Free articles and tutorials',
                                'No need to install anything',
                                'Auth0 Authentication',
                                'Stripe Payments Integration'
                            ].map((item) => (
                                <Typography
                                    key={item}
                                    variant="h6"
                                    sx={{ color: '#b9b9b9', marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <i className="fas fa-check-circle" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', backgroundColor: '#353535' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                        Get NebulaSolver
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, color: '#b9b9b9' }}>
                        Subscribe to access the Gantt Chart Tool.
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/subscribe"
                        sx={{
                            backgroundColor: '#15a2fa',
                            '&:hover': {
                                backgroundColor: '#9DC183',
                            },
                            width: '50%',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            animation: `${pulseAnimation} 2s infinite`,
                        }}
                    >
                        Go to Subscribe
                    </Button>
                </CardContent>
            </Card>
        </Box>
    </Grid>
);

// Define a hover animation for links
const hoverAnimation = keyframes`
  0% { color: #fffffe; }
  100% { color: #9DC183; }
`;

const MoreByNebulaSolver = () => (
    <Box sx={{ marginTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                <CardContent sx={{ padding: '14px 0px' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                        More by NebulaSolver
                    </Typography>
                    <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: '400px' }}>
                        {[
                            { href: "https://nebulasolver.com/equations", text: "Equation Solver" },
                            { href: "https://nebulasolver.com/matrix", text: "Linear Algebra" },
                            { href: "https://nebulasolver.com/controlsystems", text: "Control Systems" },
                            { href: "https://nebulasolver.com/finiteelements", text: "Finite Elements" },
                            { href: "https://nebulasolver.com/thermodynamics", text: "Thermodynamics" },
                            { href: "https://nebulasolver.com/batterymanagement", text: "Battery Management" },
                            { href: "https://nebulasolver.com/electronics", text: "Electronics" },
                            { href: "https://nebulasolver.com/systems", text: "Systems Engineering" },
                            { href: "https://nebulasolver.com/diagrams", text: "Diagrams" },
                            { href: "https://nebulasolver.com/gantt", text: "Gantt Chart" },
                            { href: "https://nebulasolver.com/stocks", text: "Stock Analysis" }
                        ].map(item => (
                            <Link
                                key={item.href}
                                href={item.href}
                                sx={{
                                    display: 'block',
                                    mb: 1,
                                    color: '#fffffe',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                {item.text}
                            </Link>
                        ))}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Box>
);

const Gantt = () => {
    const { user, isAuthenticated, isLoading: authLoading, getAccessTokenSilently } = useAuth0();
    const [projects, setProjects] = useState([]);
    const [projectName, setProjectName] = useState('');
    const [tasks, setTasks] = useState([]);
    const [taskName, setTaskName] = useState('');
    const [taskStart, setTaskStart] = useState('');
    const [taskDuration, setTaskDuration] = useState('');
    const [taskResources, setTaskResources] = useState('');
    const [taskColor, setTaskColor] = useState('');
    const [error, setError] = useState('');
    const [ganttChartPngUrl, setGanttChartPngUrl] = useState(null);
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !authLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, authLoading]);
    const handleAddTask = () => {
        const newTask = {
            name: taskName,
            start: taskStart,
            duration: parseInt(taskDuration, 10),
            resources: taskResources.split(',').map(resource => resource.trim()),
            color: taskColor,
        };
        setTasks([...tasks, newTask]);
        // Reset the task form
        setTaskName('');
        setTaskStart('');
        setTaskDuration('');
        setTaskResources('');
        setTaskColor('');
    };

    const handleSubmitProject = async () => {
        if (!isAuthenticated || !user) {
            setError('User not authenticated');
            return;
        }

        // Create the new project object from the current state
        const newProject = {
            name: projectName,
            tasks: tasks,
        };

        // Update the projects array with the new project
        setProjects(prevProjects => [...prevProjects, newProject]);

        try {
            const jwtResponse = await fetch('/.netlify/functions/generateJWTGantt', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userEmail: user.email }),
            });

            if (!jwtResponse.ok) {
                throw new Error(`HTTP error ${jwtResponse.status}: ${jwtResponse.statusText}`);
            }

            const jwtData = await jwtResponse.json();
            const jwtToken = jwtData.token;

            // Construct the payload with the updated projects array
            // Note: Since state updates may be asynchronous, directly use the new project
            const payload = { projects: [...projects, newProject] };

            // Debug: Log the payload to the console
            console.log("Sending payload to backend:", payload);

            const response = await fetch('https://aqrjvfpupb.us-west-2.awsapprunner.com/generate_gantt_chart', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorBody = await response.text();
                throw new Error(`HTTP error! status: ${response.status} - ${errorBody}`);
            }

            const blob = await response.blob();
            const zip = await JSZip.loadAsync(blob);
            const ganttChartBlob = await zip.file("gantt_chart.png").async("blob");
            setGanttChartPngUrl(URL.createObjectURL(ganttChartBlob));
        } catch (err) {
            setError(`Error during the Gantt chart generation: ${err.message}`);
            console.error(err);
        }
    };

    // JSX to render the Gantt chart PNG image
    let ganttChartImage = null;
    if (ganttChartPngUrl) {
        ganttChartImage = (
            <img
                src={ganttChartPngUrl}
                alt="Gantt Chart"
                style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            />
        );
    }

    if (authLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!subscribeStatus) {
        return (
            <Container maxWidth={false}>

                <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <Box sx={{ marginTop: '0px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                                <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                                    <CardContent sx={{ padding: '0px 0px' }}>
                                        <GanttBlogPosts />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PricingBox />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MoreByNebulaSolver />
                    </Grid>
                </Grid>
                <Helmet>
                    <title>NebulaSolver - Gantt Chart</title>
                    <link rel="canonical" href={`https://nebulasolver.com/gantt`} />
                    <meta property="og:title" content="NebulaSolver - Gantt Chart" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                    <meta property="og:url" content="https://nebulasolver.com/gantt" />
                    <meta property="og:description" content="NebulaSolver - Gantt Chart. Generate Gantt Charts based off tasks and projects." />
                    <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/gantt`} />
                    <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/gantt`} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="NebulaSolver - Gantt Chart" />
                    <meta name="twitter:description" content="NebulaSolver - Gantt Chart. Generate Gantt Charts based off tasks and projects." />
                    <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                    <meta name="twitter:site" content="@nebulasolver" />
                </Helmet>
            </Container>
        );
    }

    return (
        <Container maxWidth={false}>

            <Grid container spacing={3} alignItems="stretch" justifyContent="center">
                {/* Project Input */}
                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '30px',
                            '& .MuiTextField-root': { mb: 2 },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#1c2734',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#9DC183',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#b9b9b9',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#9ca3af',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#9ca3af',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#9ca3af',
                            },
                        }}
                    >
                        <Card sx={{ ...transferFunctionCardStyle, height: '100%'}}>
                            <CardContent>
                                <Typography variant="h6" sx={headerTextStyle}>
                                    Tasks
                                </Typography>
                                <TextField
                                    label="Task Name"
                                    value={taskName}
                                    onChange={(e) => setTaskName(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Start Date (YYYY-MM-DD)"
                                    value={taskStart}
                                    onChange={(e) => setTaskStart(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Duration (days)"
                                    value={taskDuration}
                                    onChange={(e) => setTaskDuration(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Resources (comma separated)"
                                    value={taskResources}
                                    onChange={(e) => setTaskResources(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Task Color"
                                    value={taskColor}
                                    onChange={(e) => setTaskColor(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <Button
                                    width="50%"
                                    variant="contained"
                                    sx={{
                                        mt: 1,
                                        backgroundColor: '#15a2fa',
                                        '&:hover': {
                                            backgroundColor: '#9DC183',
                                        },
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                    onClick={handleAddTask}
                                >
                                    Add Task
                                </Button>
                            </CardContent>
                        </Card>
                        <Card sx={{ ...transferFunctionCardStyle, height: '100%', mt:'24px' }}>
                            {/* ...CardContent and other components */}
                            <CardContent>
                                <Typography variant="h6" sx={headerTextStyle}>
                                    Project
                                </Typography>

                                <TextField
                                    label="Project Name"
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    sx={{
                                        mb: 2,
                                        display: 'block', // Ensures the TextField behaves as a block-level element
                                        margin: '0 auto', // Centers the TextField within the Box
                                    }}
                                />
                                {tasks.map((task, index) => (
                                    <Typography
                                        sx={{
                                            color:'#ffc801'
                                        }}
                                        key={index}>{task.name}
                                    </Typography>
                                ))}
                                <Button
                                    width="50%"
                                    variant="contained"
                                    sx={{
                                        mt: 1,
                                        backgroundColor: '#15a2fa',
                                        '&:hover': {
                                            backgroundColor: '#9DC183',
                                        },
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                    onClick={handleSubmitProject}
                                >
                                    Gantt Chart
                                </Button>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>

                {/* Task Input */}
                <Grid item xs={12} md={6} >
                    <Box
                        sx={{ width: '100%', marginTop: '30px' }}
                    >


                        {ganttChartImage && (
                            <Grid item xs={12}>
                                {ganttChartImage}
                            </Grid>
                        )}

                    </Box>
                </Grid>

                {/* Gantt Blog */}
                <Grid item xs={12} md={3} >
                    <Box sx={{ marginTop: '0px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '14px',
                            }}
                        >
                            <Card
                                sx={{
                                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                                    backgroundColor: '#353535',
                                    borderRadius: '15px',
                                    border: '2px solid #35353500',
                                    width: '100%',
                                    mt: 2,
                                }}
                            >

                                <CardContent sx={{padding:'0px 0px'}}>
                                    <GanttBlogPosts/>
                                </CardContent>

                            </Card>

                        </Box>

                    </Box>
                </Grid>
            </Grid>
            <Helmet>
                <title>NebulaSolver - Gantt Chart</title>
                <link rel="canonical" href={`https://nebulasolver.com/gantt`}/>
                <meta property="og:title" content="NebulaSolver - Gantt Chart"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png"/>
                <meta property="og:url" content="https://nebulasolver.com/gantt"/>
                <meta property="og:description"
                      content="NebulaSolver - Gantt Chart. Generate Gantt Charts based off tasks and projects."/>
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/gantt`}/>
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/gantt`}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="NebulaSolver - Gantt Chart"/>
                <meta name="twitter:description"
                      content="NebulaSolver - Gantt Chart. Generate Gantt Charts based off tasks and projects."/>
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png"/>
                <meta name="twitter:site" content="@nebulasolver"/>
            </Helmet>
        </Container>
    );
};

const cardStyleTransferFunction = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px',
};

const cardStyle = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px',
    padding: '0px'
};

const transferFunctionCardStyle = {
    ...cardStyleTransferFunction, // Reuse the base styles and add specific styles for the BodeBlogPost plot card below
    // maxWidth: 345, // Set a max-width for larger screens
    margin: 'auto', // Center the card
};

const headerTextStyle = {
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: `${rainbowAnimation} 10s ease infinite`,
    backgroundSize: '200% 200%',
    '@media (max-width:600px)': {
        fontSize: '1.2em',
    },
    marginBottom: '10px',
};

export default Gantt;
