import { memo, useState } from 'react';
import { Handle, Position, NodeResizer } from 'reactflow';
import './customNodeStyles.css';

function ResizerNode({ data }) {
    const [width, setWidth] = useState(data.width || 80);
    const [height, setHeight] = useState(data.height || 80);
    const [label, setLabel] = useState(data.label || '');

    return (
        <div className="custom-resizer-node" style={{ width, height }}>
            <NodeResizer
                isVisible
                onResize={(event, { width, height }) => {
                    setWidth(width);
                    setHeight(height);
                }}
            />
            <input
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                style={{ width: '100%', background: 'transparent', border: 'none', color: '#fff', textAlign: 'center' }}
            />
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
        </div>
    );
}

export default memo(ResizerNode);
