import React from 'react';
import { Handle, Position } from 'reactflow';

const InputNode = ({ data }) => {
    return (
        <div style={{ padding: 10, border: '1px solid #fff', borderRadius: '5px', background: '#2a2a2a', color: '#fff' }}>
            {data.label}
            <Handle type="source" position={Position.Right} style={{ background: '#555' }} />
        </div>
    );
};

export default InputNode;
