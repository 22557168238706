import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
    width: '100%', // Ensure the link takes full width of the card
    '&:hover': {
        '& .MuiTypography-root': {
            color: '#9DC183', // Change title color on hover
        }
    }
});

const expandAnimation = keyframes`
  0% { max-height: 0px; }
  100% { max-height: 150px; }
`;

const collapseAnimation = keyframes`
  0% { max-height: 150px; }
  100% { max-height: 0px; }
`;

const FeedbackControlSystemBlog = ({ item }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = (event) => {
        event.stopPropagation(); // Prevent the link navigation
        setExpanded(!expanded);
    };

    return (
        <Card
            elevation={0}
            sx={{
                maxWidth: '100%',
                backgroundColor: '#353535',
                borderRadius: '8px',
                transition: 'transform .2s',
                '&:hover': {
                    transform: 'scale(1.02)'
                },
                overflow: 'hidden', // Ensure the card content does not overflow
                marginBottom: '8px', // Reduce space between cards
            }}
        >
            <CardContent sx={{ padding: '12px 16px' }}> {/* Reduce padding */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        onClick={handleExpandClick}
                        sx={{
                            color: '#9DC183', // Keep arrow color constant
                            padding: '4px', // Reduce padding for the button
                        }}
                    >
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    <Box sx={{ flex: 1 }}>
                        <StyledLink to={`/feedbackcontrolsystemblogpost/${item.slugName}`} >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#fffffe',
                                    marginBottom: '4px', // Reduce margin
                                    textAlign: 'left',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                }}
                            >
                                {item.title}
                            </Typography>
                        </StyledLink>
                    </Box>
                </Box>
                <Box sx={{ display: expanded ? 'block' : 'none', paddingLeft: '40px' }}>
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#b9b9b9',
                            textAlign: 'left',
                            maxHeight: expanded ? '150px' : '0px',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease',
                            animation: expanded ? `${expandAnimation} 0.3s ease` : `${collapseAnimation} 0.3s ease`,
                        }}
                    >
                        {item.shortDescription}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default FeedbackControlSystemBlog;
