/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Container, Card, CardContent, Box, CircularProgress, Grid, Link } from '@mui/material';
import { styled } from '@mui/system';
import BuyButtonComponent from '../StripePayments/BuyButtonComponent';
import { keyframes } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const StyledDialogTitle = styled(DialogTitle)({
    textAlign: 'center',
    '& .MuiTypography-root': {
        fontSize: '1.2rem',
        fontWeight: '600',
        color: '#e6e6e7',
    },
});

const StyledButton = styled(Button)({
    display: 'block',
    margin: '0 auto',
    textTransform: 'none',
    fontSize: '1rem',
    backgroundColor: '#15a2fa',
    color: '#e6e6e7',
    '&:hover': {
        backgroundColor: '#9DC183',
    }
});

const callToActionStyle = {
    backgroundColor: '#15a2fa',
    '&:hover': { backgroundColor: '#9DC183', transform: 'scale(1.05)' },
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    fontSize: '1rem',
    fontWeight: 'bold',
};

// Define a pulse animation for buttons
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const PricingBox = () => (
    <Grid>
        <Box
            sx={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#353535',
                    borderRadius: '15px',
                    border: '2px solid #35353500',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                            marginBottom: '10px',
                        }}
                    >
                        Pricing
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{ color: '#9DC183', marginBottom: '20px' }}
                        >
                            $9.99 / year
                        </Typography>
                        <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
                            {[
                                'Full access to all the apps',
                                'Free articles and tutorials',
                                'No need to install anything',
                                'Auth0 Authentication',
                                'Stripe Payments Integration'
                            ].map((item) => (
                                <Typography
                                    key={item}
                                    variant="h6"
                                    sx={{ color: '#b9b9b9', marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <i className="fas fa-check-circle" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Grid>
);

const MoreByNebulaSolver = () => (
    <Box sx={{ marginTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                <CardContent sx={{ padding: '14px 0px' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                        More by NebulaSolver
                    </Typography>
                    <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: '400px' }}>
                        <Link href="https://nebulasolver.com/logic" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Logic Solver</Link>
                        <Link href="https://nebulasolver.com/equations" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Equation Solver</Link>
                        <Link href="https://nebulasolver.com/matrix" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Linear Algebra</Link>
                        <Link href="https://nebulasolver.com/controlsystems" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Control Systems</Link>
                        <Link href="https://nebulasolver.com/finiteelements" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Finite Elements</Link>
                        <Link href="https://nebulasolver.com/thermodynamics" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Thermodynamics</Link>
                        <Link href="https://nebulasolver.com/batterymanagement" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Battery Management</Link>
                        <Link href="https://nebulasolver.com/electronics" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Electronics</Link>
                        <Link href="https://nebulasolver.com/systems" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Systems Engineering</Link>
                        <Link href="https://nebulasolver.com/diagrams" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Diagrams</Link>
                        <Link href="https://nebulasolver.com/gantt" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Gantt Chart</Link>
                        <Link href="https://nebulasolver.com/stocks" color="#fffffe" sx={{ display: 'block', mb: 1 }}>Stock Analysis</Link>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Box>
);

const BlogPlaceholder = () => (
    <Box sx={{ marginTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                <CardContent sx={{ padding: '14px 0px' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                        Blog
                    </Typography>
                    {/* Placeholder for Blog Content */}
                    <Typography variant="body1" sx={{ textAlign: 'center', color: '#b9b9b9' }}>
                        {/*Placeholder for blog posts*/}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    </Box>
);

const Subscribe = () => {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);
    const [buyDialogOpen, setBuyDialogOpen] = useState(false);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !isLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, isLoading]);

    if (isLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!user) {
        return (
            <Container maxWidth={false}>
                <Box sx={{ mt: 5 }}>
                    <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', backgroundColor:'#353535' }}>
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                                User Not Authenticated
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2, color: '#b9b9b9' }}>
                                Please log in to access the subscription page.
                            </Typography>
                            <Button variant="contained" sx={callToActionStyle} onClick={() => loginWithRedirect()}>
                                Log In
                            </Button>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        );
    }

    const handleSubscribeClick = () => {
        if (user && !subscribeStatus) {
            setBuyDialogOpen(true);
        } else if (user && subscribeStatus) {
            unsubscribeUser();
        }
    };

    const unsubscribeUser = async () => {
        if (user) {
            const response = await fetch('/.netlify/functions/unsubscribeUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userEmail: user.email,
                }),
            });

            if (response.ok) {
                setSubscribeStatus(false);
            }
        }
    };

    const handleBuyDialogClose = () => {
        setBuyDialogOpen(false);
    };

    return (
        <Container maxWidth={false}>
            <Helmet>
                <title>NebulaSolver - Subscribe</title>
                <link rel="canonical" href={`https://nebulasolver.com/subscribe`} />
                <meta property="og:title" content="NebulaSolver - Subscribe" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/subscribe" />
                <meta property="og:description" content="NebulaSolver - Cloud-Based Logic Solver. Subscribe to access the full features." />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/subscribe`} />
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/subscribe`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="NebulaSolver - Subscribe" />
                <meta name="twitter:description" content="NebulaSolver - Cloud-Based Logic Solver. Subscribe to access the full features." />
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
            <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
                <Grid item xs={12} md={3}>
                    <BlogPlaceholder />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ mt: 3 }}>
                        <PricingBox />
                        <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', backgroundColor: '#353535', mt: 3 }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                                    Manage Subscription
                                </Typography>
                                <StyledButton
                                    variant="contained"
                                    onClick={handleSubscribeClick}
                                    sx={{
                                        backgroundColor: '#15a2fa',
                                        '&:hover': {
                                            backgroundColor: '#9DC183',
                                        },
                                        width: '50%',
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        animation: `${pulseAnimation} 2s infinite`,
                                        marginTop:'15px',

                                    }}
                                    >
                                    {subscribeStatus ? 'Unsubscribe' : 'Subscribe'}
                                </StyledButton>
                            </CardContent>
                        </Card>
                    </Box>
                    <Dialog
                        open={buyDialogOpen}
                        onClose={handleBuyDialogClose}
                        fullWidth={true}
                        maxWidth='sm'
                        PaperProps={{ sx: { backgroundColor: '#353535', border: '2px solid #35353500', borderRadius: '20px' } }}
                    >
                        <StyledDialogTitle>
                            <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', textTransform: 'uppercase', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                                Purchase Subscription
                            </Typography>
                        </StyledDialogTitle>
                        <DialogContent>
                            <BuyButtonComponent userEmail={user.email} />
                        </DialogContent>
                        <DialogActions>
                            <StyledButton variant="text" onClick={handleBuyDialogClose}>Close</StyledButton>
                        </DialogActions>
                    </Dialog>
                </Grid>
                <Grid item xs={12} md={3}>
                    <MoreByNebulaSolver />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Subscribe;
