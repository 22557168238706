import React from 'react';
import { Handle, Position } from 'reactflow';

const SummingPointNode = ({ data }) => {
    return (
        <div style={{ padding: 10, border: '1px solid #fff', borderRadius: '50%', background: '#2a2a2a', color: '#fff', width: 50, height: 50, textAlign: 'center' }}>
            {data.label}
            <Handle type="target" position={Position.Left} style={{ background: '#555' }} />
            <Handle type="source" position={Position.Right} style={{ background: '#555' }} />
            <Handle type="target" position={Position.Bottom} style={{ background: '#555' }} id="bottom" />
        </div>
    );
};

export default SummingPointNode;
