/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    TextField,
    InputAdornment,
    Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import TimelineIcon from '@mui/icons-material/Timeline'; // Placeholder icon for Timeline
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faPinterest, faInstagram, faFacebook, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import PersonIcon from '@mui/icons-material/Person';
import PetsIcon from '@mui/icons-material/Pets';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { keyframes } from '@emotion/react';
import { useAuth0 } from '@auth0/auth0-react';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CalculateIcon from '@mui/icons-material/Calculate';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PaymentIcon from '@mui/icons-material/Payment';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import FunctionsIcon from '@mui/icons-material/Functions';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import logo from "./cloud-tools-1.jpg";
import { NavLink } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import { FaSignInAlt, FaSignOutAlt, FaUser } from 'react-icons/fa'
import './NavBar.css';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CasinoIcon from '@mui/icons-material/Casino';
import BarChartIcon from '@mui/icons-material/BarChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import GradientIcon from "@mui/icons-material/Gradient";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AirIcon from '@mui/icons-material/Air';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {AccountTree} from "@mui/icons-material";
import { TbCircuitDiode } from "react-icons/tb";
import { TbCircuitResistor } from "react-icons/tb";
import { TbCircuitGround } from "react-icons/tb";
import { BsDiagram3Fill } from "react-icons/bs";
import { GiLogicGateAnd } from "react-icons/gi";
import { TbBrandMatrix } from "react-icons/tb";
import { PiWaveSineFill } from "react-icons/pi";
import { GiIBeam } from "react-icons/gi";
import { GiArchBridge } from "react-icons/gi";
import { TbBuildingBridge } from "react-icons/tb";
import { FaBlog } from "react-icons/fa";
import { FaSitemap } from "react-icons/fa";
import { FaWpforms } from "react-icons/fa";
import { FaListOl } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const drawerWidth = 240;

const NavBar = ({ setSearchString }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [localSearchString, setLocalSearchString] = useState('');

    useEffect(() => {
        setSearchString(localSearchString);
    }, [localSearchString]);


    const handleSearchChange = (event) => {
        setLocalSearchString(event.target.value);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const drawerItems = [
        {
            text: 'Home',
            icon: <HomeIcon style={{ color: '#ffc801' }}/>,
            route: '/',
        },
        {
            text: 'Blog',
            icon: <RssFeedIcon style={{ color: '#ffc801' }}/>,
            route: '/blogposts',
        },
        {
            text: 'Site Index',
            icon: <FaSitemap style={{
                color: '#ffc801',
                fontSize: '20px'
            }}/>,
            route: '/siteindex',
        },
        {
            text: 'Resume Builder',
            icon: <FaWpforms style={{
                color: '#ffc801',
                fontSize: '20px'
            }}/>,
            route: '/resume',
        },
        // Add more items as needed
        ...(isAuthenticated
            ? [
                {
                    text: 'Account',
                    icon: <CardMembershipIcon style={{
                        color: '#ffc801'
                    }}/>,
                    route: '/subscribe',
                },
                {
                    text: 'Logic Solver',
                    icon: <GiLogicGateAnd style={{
                        color: '#ffc801',
                        fontSize: '30px'
                    }}/>,
                    route: '/logic',
                },
                {
                    text: 'Equation Solver',
                    icon: <CalculateIcon style={{
                        color: '#ffc801'
                    }}/>,
                    route: '/equations',
                },
                {
                    text: 'Linear Algebra',
                    icon: <TbBrandMatrix style={{
                        color: '#ffc801',
                        fontSize: '30px'
                    }}/>,
                    route: '/matrix',
                },
                {
                    text: 'Control Systems',
                    icon: <ControlPointIcon style={{
                        color: '#ffc801'
                    }}/>,
                    route: '/controlsystems',
                },
                {
                    text: 'Finite Elements',
                    icon: <TbBuildingBridge style={{
                        color: '#ffc801',
                        fontSize: '24px'
                    }}/>,
                    route: '/finiteelements',
                },
                {
                    text: 'Thermodynamics',
                    icon: <AcUnitIcon style={{
                        color: '#ffc801'
                    }}/>,
                    route: '/thermodynamics',
                },
                {
                    text: 'Battery Management',
                    icon: <BatteryCharging90Icon style={{
                        color: '#ffc801',
                        fontSize: '30px'
                    }}/>,
                    route: '/batterymanagement',
                },
                {
                    text: 'Electronics',
                    icon: <PowerInputIcon style={{
                        color: '#ffc801',
                        fontSize: '30px'
                    }}/>,
                    route: '/electronics',
                },
                {
                    text: 'Netlist',
                    icon: <TbCircuitDiode style={{
                        color: '#ffc801',
                        fontSize: '30px' // Adjust this value as needed
                    }}/>,
                    route: '/netlist',
                },
                {
                    text: 'Systems',
                    icon: <PiWaveSineFill style={{
                        color: '#ffc801',
                        fontSize:'24px'
                    }}/>,
                    route: '/systems',
                },
                {
                    text: 'Diagrams',
                    icon: <BsDiagram3Fill style={{
                        color: '#ffc801',
                        fontSize:'24px'
                    }}/>,
                    route: '/diagrams',
                },
                // {
                //     text: 'Diagram Decoder',
                //     icon: <AccountTreeIcon style={{
                //         color: '#ffc801'
                //     }}/>,
                //     route: '/diagramdecoder',
                // },
                // {
                //     text: 'Diagram Rete.js',
                //     icon: <AccountTreeIcon style={{
                //         color: '#ffc801'
                //     }}/>,
                //     route: '/diagramrete',
                // },
                // {
                //     text: 'Diagram Mermaid',
                //     icon: <AccountTreeIcon style={{
                //         color: '#ffc801'
                //     }}/>,
                //     route: '/diagrammermaid',
                // },
                {
                    text: 'Gantt Chart',
                    icon: <ViewTimelineIcon style={{
                        color: '#ffc801'
                    }}/>,
                    route: '/gantt',
                },
                {
                    text: 'Stock Analysis',
                    icon: <TrendingUpIcon style={{
                        color: '#ffc801'
                    }}/>,
                    route: '/stocks',
                },
                {
                    text: 'Add Resume',
                    icon: <IoDocumentText style={{
                        color: '#ffc801',
                        fontSize:'24px'
                    }}/>,
                    route: '/addResume',
                },
                {
                    text: 'Edit Resume',
                    icon: <FaListOl style={{
                        color: '#ffc801',
                        fontSize:'24px'
                    }}/>,
                    route: '/myResumes',
                },

                // {
                //     text: 'Probability',
                //     icon: <CasinoIcon style={{
                //         color: '#b9b9b9'
                //     }}/>,
                //     route: '/probability',
                // },
                // {
                //     text: 'Statistics',
                //     icon: <BarChartIcon style={{
                //         color: '#b9b9b9'
                //     }}/>,
                //     route: '/statistics',
                // },
                // {
                //     text: 'Finance',
                //     icon: <AccountBalanceIcon style={{
                //         color: '#b9b9b9'
                //     }}/>,
                //     route: '/finance',
                // },
                // {
                //     text: 'Investing',
                //     icon: <AttachMoneyIcon style={{
                //         color: '#b9b9b9'
                //     }}/>,
                //     route: '/investing',
                // },
                // {
                //     text: 'Power Electronics',
                //     icon: <PowerInputIcon style={{
                //         color: '#b9b9b9'
                //     }}/>,
                //     route: '/powerelectronics',
                // },
                // Add more items as needed
            ]
            : []),
    ];

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: '#353535',
                    padding: '3px',
                    borderBottom: '2px solid #35353500'
                }}
            >
                <Toolbar>
                    {/* Empty Toolbar for spacing */}
                    {/*<Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>*/}
                    {/*    <Button startIcon={<CardMembershipIcon />} component={RouterLink} to="/subscribe" sx={{ color: '#6495ED' }}>Account</Button>*/}
                    {/*</Box>*/}
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                        <div className="nav-row nav-row-logo">
                            <NavLink to="/" className="nav-logo">
                                {/*<img className="nav-img" src={logo} alt="logo" style={{ borderRadius:'50%' }} />*/}
                                Nebula Solver
                            </NavLink>
                            <div onClick={() => setOpen(!open)} className="nav-icon">
                                {open ? <FiX /> : <FiMenu />}
                            </div>
                        </div>
                    </Box>


                </Toolbar>
                <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle} sx={{ mr: 2, flexShrink: 0 }}>
                        <MenuIcon style={{ color: '#ffc801' }}/>
                    </IconButton>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                        <Button startIcon={<HomeIcon />} component={RouterLink} to="/" sx={{ color: '#b9b9b9' }}>Home</Button>
                        {!isAuthenticated ? (
                            <Button startIcon={<LockOpenIcon />} onClick={loginWithRedirect} sx={{ color: '#15a2fa' }}>Login</Button>
                        ) : (
                            <Button startIcon={<ExitToAppIcon />} onClick={() => logout({ returnTo: window.location.origin })} sx={{ color: '#ffc801' }}>Logout</Button>
                        )}
                    </Box>
                    <Box sx={{ flexShrink: 0, width: '48px' }}> {/* Placeholder to balance the IconButton width */}</Box>
                </Toolbar>
            </AppBar>


            <Drawer
                variant="persistent"
                open={drawerOpen}
                sx={{
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        top: '128px', // AppBar height on desktop devices
                        height: 'calc(100% - 128px)',
                        backgroundColor: '#353535',
                    },
                    '& .MuiListItemText-root': { // <-- this targets the main text
                        color: '#fffffe'
                    },
                    '& .MuiListItem-button:hover .MuiListItemText-root': { // <-- this preserves hover effect
                        color: 'rgba(100, 100, 101, 0.75)', // slightly faded color on hover for effect; adjust as desired
                    }
                }}
            >
                <List>
                    {drawerItems.map((item, index) => (
                        <ListItem button key={index} component={RouterLink} to={item.route}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                    {!isAuthenticated ? (
                        <ListItem button onClick={loginWithRedirect}>
                            <ListItemIcon>
                                <LockOpenIcon style={{ color: '#ed6c02' }} />
                            </ListItemIcon>
                            <ListItemText primary="Login" />
                        </ListItem>
                    ) : (
                        <ListItem button onClick={() => logout({ returnTo: window.location.origin })}>
                            <ListItemIcon>
                                <ExitToAppIcon style={{ color: '#ed6c02' }} />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    )}
                </List>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, pt: '120px', ml: { md: drawerWidth } }}>
                {/* Main content */}
            </Box>
        </>
    );
};

export default NavBar;
