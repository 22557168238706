/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import ELK from 'elkjs/lib/elk.bundled.js';
import { InputNode, SumNodePlusMinusNull, SumNodePlusPlusPlus, SumNodePlusPlusMinus, SumNodePlusMinusMinus, SumNodePlusMinusPlus, SumNodePlusPlusNull, SumNodePlusNullMinus, SumNodePlusNullPlus, PIDNode, PlantNode, ScopeNode, GainNode, TFNodeRight, TFNodeLeft } from './CustomNodes';
import axios from 'axios';
import { AppBar, Toolbar, Box, Button, Container, Grid } from '@mui/material';
import { FaPlusCircle, FaDesktop, FaSave, FaPlay } from 'react-icons/fa';
import { LuThermometerSnowflake } from 'react-icons/lu';
import { Bs1SquareFill, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill, BsFiletypeJson } from 'react-icons/bs';
import { TbWaveSine, TbJson } from 'react-icons/tb';
import EscalatorIcon from '@mui/icons-material/Escalator';
import './DiagramDecoder.css';

const initialNodes = [];
const initialEdges = [];

const nodeTypes = {
    input: InputNode,
    sumPlusMinusNull: SumNodePlusMinusNull,
    sumPlusPlusNull: SumNodePlusPlusNull,
    sumPlusPlusPlus: SumNodePlusPlusPlus,
    sumPlusPlusMinus: SumNodePlusPlusMinus,
    sumPlusMinusPlus: SumNodePlusMinusPlus,
    sumPlusMinusMinus: SumNodePlusMinusMinus,
    sumPlusNullMinus: SumNodePlusNullMinus,
    sumPlusNullPlus: SumNodePlusNullPlus,
    pid: PIDNode,
    plant: PlantNode,
    scope: ScopeNode,
    gain: GainNode,
    tfRight: TFNodeRight,
    tfLeft: TFNodeLeft,
};

const DiagramDecoder = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [elk] = useState(new ELK());
    const [jsonOutput, setJsonOutput] = useState("");

    const onConnect = useCallback((params) => setEdges((eds) => addEdge({ ...params, type: 'smoothstep' }, eds)), []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const reactFlowBounds = event.target.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            if (typeof type === 'undefined' || !type) return;

            const position = {
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            };
            const newNode = {
                id: `${+new Date()}`,
                type,
                position,
                data: { label: `${type} node` },
                style: { border: '2px solid #b9b9b9' }
            };
            setNodes((nds) => nds.concat(newNode));
        },
        [setNodes]
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const generateJson = useCallback(() => {
        const graph = {
            id: "root",
            layoutOptions: {
                'elk.algorithm': 'layered',
            },
            children: nodes.map(node => ({
                id: node.id,
                width: 100,
                height: 50,
                label: node.data.label,
                data: node.data,
            })),
            edges: edges.map(edge => ({
                id: edge.id,
                sources: [edge.source],
                targets: [edge.target],
            })),
        };

        elk.layout(graph)
            .then((layout) => {
                setJsonOutput(JSON.stringify(layout, null, 2));
            })
            .catch(console.error);
    }, [nodes, edges, elk]);

    const writeDiagram = async () => {
        try {
            const response = await axios.post('/.netlify/functions/writeDiagram', { nodes, edges });
            console.log('Diagram saved:', response.data);
        } catch (error) {
            console.error('Error saving diagram:', error);
        }
    };

    return (
        <Container maxWidth={false}>
            <AppBar position="relative" sx={{ backgroundColor: '#353535', boxShadow: 'none' }}>
                <Toolbar sx={{ justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <Button onClick={generateJson} sx={{ color: '#ffc801', marginRight: '10px' }} startIcon={<BsFiletypeJson />}>
                            JSON
                        </Button>
                        <Button onClick={writeDiagram} sx={{ color: '#ffc801' }} startIcon={<FaSave />}>
                            Save
                        </Button>
                        <Button sx={{ color: '#ffc801' }} startIcon={<FaPlay />}>Run</Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <AppBar position="relative" sx={{ backgroundColor: '#353535', boxShadow: 'none' }}>
                <Toolbar sx={{ justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <Button
                            startIcon={<EscalatorIcon />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'input')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Input
                        </Button>
                        <Button
                            startIcon={<FaPlusCircle />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'sumPlusMinusNull')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Sum Plus Minus Null
                        </Button>
                        <Button
                            startIcon={<FaPlusCircle />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'sumPlusPlusPlus')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Sum Plus Plus Plus
                        </Button>
                        <Button
                            startIcon={<FaPlusCircle />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'sumPlusMinusMinus')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Sum Plus Minus Minus
                        </Button>
                        <Button
                            startIcon={<FaPlusCircle />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'sumPlusMinusPlus')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Sum Plus Minus Plus
                        </Button>
                        <Button
                            startIcon={<FaPlusCircle />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'sumPlusPlusMinus')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Sum Plus Plus Minus
                        </Button>
                        <Button
                            startIcon={<FaPlusCircle />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'sumPlusPlusNull')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Sum Plus Plus Null
                        </Button>
                        <Button
                            startIcon={<FaPlusCircle />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'sumPlusNullMinus')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Sum Plus Null Minus
                        </Button>
                        <Button
                            startIcon={<FaPlusCircle />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'sumPlusNullPlus')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Sum Plus Null Plus
                        </Button>
                        <Button
                            startIcon={<BsFillArrowRightSquareFill />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'tfRight')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            TF-Right
                        </Button>
                        <Button
                            startIcon={<BsFillArrowLeftSquareFill />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'tfLeft')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            TF-Left
                        </Button>
                        <Button
                            startIcon={<TbWaveSine />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'pid')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            PID
                        </Button>
                        <Button
                            startIcon={<LuThermometerSnowflake />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'plant')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Plant
                        </Button>
                        <Button
                            startIcon={<FaDesktop />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'scope')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Scope
                        </Button>
                        <Button
                            startIcon={<Bs1SquareFill />}
                            onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'gain')}
                            draggable
                            sx={{ color: '#ffc801' }}
                        >
                            Gain
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Grid container spacing={1} alignItems="stretch" justifyContent="center" sx={{ flex: 1 }}>
                <Grid item xs={12} md={1}>
                    <Box sx={{ width: '100%', marginTop: '30px', height: '100%', backgroundColor: '#2a2a2a' }}>
                        {/* Placeholder content */}
                    </Box>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Box sx={{ width: '100%', marginTop: '30px', height: '600px' }}>
                        <ReactFlowProvider>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                fitView
                                nodeTypes={nodeTypes}
                            >
                                <MiniMap />
                                <Controls />
                                <Background />
                            </ReactFlow>
                        </ReactFlowProvider>
                    </Box>
                </Grid>
                <Grid item xs={12} md={1}>
                    <Box sx={{ width: '100%', marginTop: '30px', height: '100%', backgroundColor: '#2a2a2a' }}>
                        {/* Placeholder content */}
                    </Box>
                </Grid>
            </Grid>
            <div style={{ padding: '10px', background: '#2a2a2a' }}>
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', marginTop: '10px', color: 'white' }}>
                    {jsonOutput}
                </pre>
            </div>
        </Container>
    );
};

export default DiagramDecoder;
