/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, TextField, Typography, Box, Grid, CircularProgress, Snackbar, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Formik, Form, FieldArray } from 'formik';
import MenuIcon from '@mui/icons-material/Menu';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ResumeDocument from './PdfRenderer';
import { FaSave } from 'react-icons/fa';
import { BsFillFilePdfFill } from "react-icons/bs";

const initialValues = {
    resumeName: '', // New property for user-defined resume name
    name: '',
    email: '',
    phone: '',
    address: '',
    developerProfile: '',
    linkedinProfile: '',
    education: [''],
    experience: [{ employer: '', position: '', accomplishments: [''] }],
    academicProjects: [{ type: '', client: '', accomplishments: [''] }],
    leadershipExperience: [{ organization: '', position: '', accomplishments: [''] }],
    honors: [''],
    additional: {
        computerSkills: '',
        languages: '',
        interests: '',
        workEligibility: '',
        certifications: ''
    },
    role: '',
    roleDescription: '',
    company: ''
};

const AddResume = () => {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !authLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, authLoading]);

    const handleSubmit = async (values) => {
        try {
            const sanitizedValues = {
                ...values,
                name: values.name || '',
                email: values.email || '',
                phone: values.phone || '',
                address: values.address || '',
                developerProfile: values.developerProfile || '',
                linkedinProfile: values.linkedinProfile || '',
                education: values.education.map(edu => edu || ''),
                experience: values.experience.map(exp => ({
                    ...exp,
                    employer: exp.employer || '',
                    position: exp.position || '',
                    accomplishments: exp.accomplishments.map(acc => acc || '')
                })),
                academicProjects: values.academicProjects.map(proj => ({
                    ...proj,
                    type: proj.type || '',
                    client: proj.client || '',
                    accomplishments: proj.accomplishments.map(acc => acc || '')
                })),
                leadershipExperience: values.leadershipExperience.map(lead => ({
                    ...lead,
                    organization: lead.organization || '',
                    position: lead.position || '',
                    accomplishments: lead.accomplishments.map(acc => acc || '')
                })),
                honors: values.honors.map(honor => honor || ''),
                additional: {
                    computerSkills: values.additional.computerSkills || '',
                    languages: values.additional.languages || '',
                    interests: values.additional.interests || '',
                    workEligibility: values.additional.workEligibility || '',
                    certifications: values.additional.certifications || ''
                },
                role: values.role || '',
                roleDescription: values.roleDescription || '',
                company: values.company || ''
            };

            await axios.post('/.netlify/functions/createResume', {
                ...sanitizedValues,
                userEmail: user.email
            });
            setMessage('Resume created successfully!');
            setOpen(true);
        } catch (error) {
            setMessage('Error creating resume');
            setOpen(true);
            console.error('Error creating resume', error);
        }
    };

    if (authLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!subscribeStatus) {
        return (
            <Container maxWidth="md">
                <Typography variant="h6" gutterBottom>You need to subscribe to use this feature.</Typography>
                <Button variant="contained" color="primary" href="/subscribe">Go to Subscribe</Button>
            </Container>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({ values, handleChange }) => (
                <Container maxWidth="md" sx={{ bgcolor: '#2c2c2c', p: 4, borderRadius: 5 }}>
                    <AppBar position="relative" sx={{ backgroundColor: '#353535', boxShadow: 'none', padding: '10px 0' }}>
                        <Toolbar sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '10px', mt: 1 }}>
                                <PDFDownloadLink
                                    document={<ResumeDocument formData={values} />}
                                    fileName={`${values.resumeName || 'resume'}.pdf`}
                                >
                                    {({ loading }) => (
                                        <Button
                                            startIcon={<BsFillFilePdfFill />}
                                            sx={{
                                                color: '#ffc801',
                                                '&:hover': {
                                                    color: '#15a2fa',
                                                },
                                            }}
                                        >
                                            {loading ? 'Loading PDF...' : 'PDF'}
                                        </Button>
                                    )}
                                </PDFDownloadLink>
                                <Button
                                    onClick={() => handleSubmit(values)}
                                    startIcon={<FaSave />}
                                    sx={{
                                        color: '#ffc801',
                                        backgroundColor: '#353535',
                                        '&:hover': {
                                            backgroundColor: '#9DC183',
                                        },
                                    }}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Resume Name"
                                    name="resumeName"
                                    value={values.resumeName || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Role"
                                    name="role"
                                    value={values.role || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Role Description"
                                    name="roleDescription"
                                    value={values.roleDescription || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Company"
                                    name="company"
                                    value={values.company || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={values.name || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={values.email || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Phone"
                                    name="phone"
                                    value={values.phone || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Address"
                                    name="address"
                                    value={values.address || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Developer Profile"
                                    name="developerProfile"
                                    value={values.developerProfile || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="LinkedIn Profile"
                                    name="linkedinProfile"
                                    value={values.linkedinProfile || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{ style: { color: '#fff' } }}
                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                />
                            </Grid>
                        </Grid>

                        {/* Experience */}
                        <FieldArray
                            name="experience"
                            render={arrayHelpers => (
                                <Box sx={{ marginBottom: 3 }}>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>Experience</Typography>
                                    {values.experience.map((exp, index) => (
                                        <Box key={index} sx={{ marginBottom: 3 }}>
                                            <TextField
                                                label="Employer"
                                                name={`experience.${index}.employer`}
                                                value={exp.employer || ''}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ style: { color: '#fff', fontWeight: 'medium' } }}
                                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                            />
                                            <TextField
                                                label="Position"
                                                name={`experience.${index}.position`}
                                                value={exp.position || ''}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ style: { color: '#fff' } }}
                                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                            />
                                            <FieldArray
                                                name={`experience.${index}.accomplishments`}
                                                render={subArrayHelpers => (
                                                    <Box>
                                                        {exp.accomplishments.map((item, subIndex) => (
                                                            <Box key={subIndex} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                                <TextField
                                                                    label={`Accomplishment ${subIndex + 1}`}
                                                                    name={`experience.${index}.accomplishments.${subIndex}`}
                                                                    value={item || ''} // Ensure no undefined values
                                                                    onChange={handleChange}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    InputProps={{ style: { color: '#fff' } }}
                                                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                                                />
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => subArrayHelpers.remove(subIndex)}
                                                                    sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            sx={{ bgcolor: '#353535', color: '#fffffe', width: '30%' }}
                                                            onClick={() => subArrayHelpers.push('')}
                                                        >
                                                            Add Accomplishment
                                                        </Button>
                                                    </Box>
                                                )}
                                            />
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                sx={{ marginTop: 2, bgcolor: '#353535', width: '30%' }}
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                Remove Experience
                                            </Button>
                                        </Box>
                                    ))}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                        onClick={() => arrayHelpers.push({ employer: '', position: '', accomplishments: [''] })}
                                    >
                                        Add Experience
                                    </Button>
                                </Box>
                            )}
                        />

                        {/* Academic Projects */}
                        <FieldArray
                            name="academicProjects"
                            render={arrayHelpers => (
                                <Box sx={{ marginBottom: 3 }}>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>Projects</Typography>
                                    {values.academicProjects.map((project, index) => (
                                        <Box key={index} sx={{ marginBottom: 3 }}>
                                            <TextField
                                                label="Type of Project"
                                                name={`academicProjects.${index}.type`}
                                                value={project.type || ''}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ style: { color: '#fff' } }}
                                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                            />
                                            <TextField
                                                label="Client"
                                                name={`academicProjects.${index}.client`}
                                                value={project.client || ''}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ style: { color: '#fff' } }}
                                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                            />
                                            <FieldArray
                                                name={`academicProjects.${index}.accomplishments`}
                                                render={subArrayHelpers => (
                                                    <Box>
                                                        {project.accomplishments.map((item, subIndex) => (
                                                            <Box key={subIndex} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                                <TextField
                                                                    label={`Accomplishment ${subIndex + 1}`}
                                                                    name={`academicProjects.${index}.accomplishments.${subIndex}`}
                                                                    value={item || ''}
                                                                    onChange={handleChange}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    InputProps={{ style: { color: '#fff' } }}
                                                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                                                />
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => subArrayHelpers.remove(subIndex)}
                                                                    sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            sx={{ bgcolor: '#353535', color: '#fffffe', width: '30%' }}
                                                            onClick={() => subArrayHelpers.push('')}
                                                        >
                                                            Add Accomplishment
                                                        </Button>
                                                    </Box>
                                                )}
                                            />
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="secondary"
                                                sx={{ marginTop: 2, bgcolor: '#353535', width: '30%' }}
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                Remove Project
                                            </Button>
                                        </Box>
                                    ))}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                        onClick={() => arrayHelpers.push({ type: '', client: '', accomplishments: [''] })}
                                    >
                                        Add Project
                                    </Button>
                                </Box>
                            )}
                        />

                        {/* Leadership Experience */}
                        <FieldArray
                            name="leadershipExperience"
                            render={arrayHelpers => (
                                <Box sx={{ marginBottom: 3 }}>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>Leadership</Typography>
                                    {values.leadershipExperience.map((exp, index) => (
                                        <Box key={index} sx={{ marginBottom: 3 }}>
                                            <TextField
                                                label="Organization"
                                                name={`leadershipExperience.${index}.organization`}
                                                value={exp.organization || ''}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ style: { color: '#fff' } }}
                                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                            />
                                            <TextField
                                                label="Position"
                                                name={`leadershipExperience.${index}.position`}
                                                value={exp.position || ''}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ style: { color: '#fff' } }}
                                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                            />
                                            <FieldArray
                                                name={`leadershipExperience.${index}.accomplishments`}
                                                render={subArrayHelpers => (
                                                    <Box>
                                                        {exp.accomplishments.map((item, subIndex) => (
                                                            <Box key={subIndex} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                                <TextField
                                                                    label={`Accomplishment ${subIndex + 1}`}
                                                                    name={`leadershipExperience.${index}.accomplishments.${subIndex}`}
                                                                    value={item || ''}
                                                                    onChange={handleChange}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    InputProps={{ style: { color: '#fff' } }}
                                                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                                                />
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => subArrayHelpers.remove(subIndex)}
                                                                    sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            sx={{ bgcolor: '#353535', color: '#fffffe', width: '30%' }}
                                                            onClick={() => subArrayHelpers.push('')}
                                                        >
                                                            Add Accomplishment
                                                        </Button>
                                                    </Box>
                                                )}
                                            />
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="secondary"
                                                sx={{ marginTop: 2, bgcolor: '#353535', width: '30%' }}
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                Remove Leadership Experience
                                            </Button>
                                        </Box>
                                    ))}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                        onClick={() => arrayHelpers.push({ organization: '', position: '', accomplishments: [''] })}
                                    >
                                        Add Leadership Experience
                                    </Button>
                                </Box>
                            )}
                        />

                        {/* Education and Honors */}
                        {['education', 'honors'].map(section => (
                            <FieldArray
                                key={section}
                                name={section}
                                render={arrayHelpers => (
                                    <Box sx={{ marginBottom: 3 }}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>{section.charAt(0).toUpperCase() + section.slice(1)}</Typography>
                                        {values[section].map((item, index) => (
                                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                <TextField
                                                    name={`${section}.${index}`}
                                                    value={item || ''}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ style: { color: '#fff' } }}
                                                    InputLabelProps={{ style: { color: '#b9b9b9' } }}
                                                />
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="secondary"
                                                    sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    Remove
                                                </Button>
                                            </Box>
                                        ))}
                                        <Button
                                            type="button"
                                            variant="contained"
                                            sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                            onClick={() => arrayHelpers.push('')}
                                        >
                                            Add {section.charAt(0).toUpperCase() + section.slice(1)}
                                        </Button>
                                    </Box>
                                )}
                            />
                        ))}

                        {/* Additional Information */}
                        <Box sx={{ marginBottom: 3 }}>
                            <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>Additional Information</Typography>
                            <TextField
                                label="Computer Skills"
                                name="additional.computerSkills"
                                value={values.additional.computerSkills || ''}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                            />
                            <TextField
                                label="Languages"
                                name="additional.languages"
                                value={values.additional.languages || ''}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                            />
                            <TextField
                                label="Interests"
                                name="additional.interests"
                                value={values.additional.interests || ''}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                            />
                            <TextField
                                label="Work Eligibility"
                                name="additional.workEligibility"
                                value={values.additional.workEligibility || ''}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                            />
                            <TextField
                                label="Certifications"
                                name="additional.certifications"
                                value={values.additional.certifications || ''}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{ style: { color: '#fff' } }}
                                InputLabelProps={{ style: { color: '#b9b9b9' } }}
                            />
                        </Box>
                    </Form>
                    <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                        <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                </Container>
            )}
        </Formik>
    );
};

export default AddResume;
