import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import FeedbackControlSystemBlog from "./FeedbackControlSystemBlog";

const FeedbackControlSystemBlogPostList = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await fetch('/.netlify/functions/getFeedbackControlSystemBlogPostItems', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}`);
                }

                const itemData = await response.json();

                // Flatten the data structure
                const flattenedItems = itemData.map(data => {
                    const { blogPost, categories, slugs, authors, tags } = data;
                    return {
                        ...blogPost,
                        slugName: slugs[0]?.slugName,  // Access the first slugName directly
                        categories,
                        authors,
                        tags
                    };
                });

                setItems(flattenedItems);

            } catch (error) {
                console.error('Error fetching Feedback Control blog posts:', error);
            }
        };

        fetchItems();
    }, []);

    return (
        <div>
            <Grid container spacing={1}> {/* Reduce spacing between Grid items */}
                {items.map((item) => (
                    <Grid item xs={12} key={item.id}>
                        <FeedbackControlSystemBlog item={item}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    );

};

export default FeedbackControlSystemBlogPostList;
