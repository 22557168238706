/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Box, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import { Container } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import {Helmet} from "react-helmet";
import ReviewsIcon from '@mui/icons-material/Reviews';
import ThermoPropertiesBlogPostList from "./ThermoPropertiesBlogPostList";

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledTypographyBio = styled(Typography)({
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: '400',
    marginTop: '0.5rem',
    marginBottom: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
    padding: '2rem',
    marginTop: '0rem',
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2rem'
});

const ThermoPropertiesBlogPosts = () => {
    // const { user, isAuthenticated } = useAuth0();
    // const [petOwnerProfile, setPetOwnerProfile] = useState({
    //     name: '',
    //     bio: '',
    //     fileUrl: '',
    // });

    return (
        <Container maxWidth="md">
            <Helmet>
                <title>How To's - Thermo Properties Blog Post</title>
                <link rel="canonical" href={`https://nebulasolver.com/thermoproperties`}/>
                <meta
                    name="description"
                    content="NebulaSolver - Thermo Properties Blog Post."
                />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/thermoproperties`}/>
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/thermoproperties`}/>
                <meta property="og:title" content="NebulaSolver - How To's"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png"/>
                <meta property="og:url" content="https://nebulasolver.com/thermoproperties"/>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 0
                }}>

                <ThermoPropertiesBlogPostList />
            </Box>
        </Container>
    );
};

export default ThermoPropertiesBlogPosts;
