import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';

function NotNode({ data }) {
    const [text, setText] = useState(data.label || '');

    return (
        <div style={{ padding: 10, border: '1px solid #fff', borderRadius: '5px', width: '60px', backgroundColor: '#2a2a2a' }}>
            <input
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={{ width: '100%', background: 'transparent', border: 'none', color: '#fff', textAlign: 'center' }}
            />
            <Handle
                type="target"
                position={Position.Left}
                id="left1"
                style={{ top: '50%' }}
                isConnectable={true}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="right"
                style={{ top: '50%' }}
                isConnectable={true}
            />
        </div>
    );
}

export default memo(NotNode);
