/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Logic from './pages/Logic';
import Equations from './pages/Equations';
import Matrix from './pages/Matrix';
import Control from './pages/Control';
import ControlSystems from './pages/ControlSystems';
import FiniteElements from './pages/FiniteElements';
import Thermodynamics from './pages/Thermodynamics';
import BatteryManagement from './pages/BatteryManagement';
import Electronics from './pages/Electronics';
import Systems from './pages/Systems';
import Diagrams from './components/Diagrams/Diagrams';
import DiagramDecoder from './components/DiagramDecoder/DiagramDecoder';
import DiagramRete from './components/DiagramDecoder/DiagramRete';
import DiagramMermaid from './components/DiagramDecoder/DiagramMermaid';
import Resume from './components/Resume/Resume';
import ResumeBlogPost from './components/HowTosResume/ResumeBlogPost';
import ResumeBlogPosts from './components/HowTosResume/ResumeBlogPosts';
import AddResume from './components/Resume/AddResume';
import MyResumes from './components/Resume/MyResumes';
import Fluids from './pages/Fluids';
import Stocks from './pages/Stocks';
import Gantt from './pages/Gantt';
import Fea from './pages/Fea';
import Beam from './pages/Beam';
import ThermoPlots from './pages/ThermoPlots';
import Bms from './pages/Bms';
import Circuit from './pages/Circuit';
import Netlist from './pages/Netlist';
import Model from './pages/Model';
import ThermoProperties from './pages/ThermoProperties';
import Subscribe from './components/Subscriptions/Subscribe';
import BlogPostsPlain from "./components/HowTosEquations/BlogPostsPlain";
import BlogPosts from "./components/HowTosEquations/BlogPosts";
import SiteIndex from "./pages/SiteIndex";
import BlogPost from './components/HowTosEquations/BlogPost';
import StocksBlogPosts from "./components/HowTosStocks/StocksBlogPosts";
import StocksBlogPost from './components/HowTosStocks/StocksBlogPost';
import GanttBlogPosts from "./components/HowTosGantt/GanttBlogPosts";
import GanttBlogPost from './components/HowTosGantt/GanttBlogPost';
import FeaBlogPosts from "./components/HowTosFea/FeaBlogPosts";
import FeaBlogPost from './components/HowTosFea/FeaBlogPost';
import ThermoPlotsBlogPosts from "./components/HowTosThermoPlots/ThermoPlotsBlogPosts";
import ThermoPlotsBlogPost from './components/HowTosThermoPlots/ThermoPlotsBlogPost';
import BmsBlogPosts from "./components/HowTosBms/BmsBlogPosts";
import BmsBlogPost from './components/HowTosBms/BmsBlogPost';
import CircuitBlogPosts from "./components/HowTosCircuit/CircuitBlogPosts";
import CircuitBlogPost from './components/HowTosCircuit/CircuitBlogPost';
import ModelBlogPosts from "./components/HowTosModel/ModelBlogPosts";
import ModelBlogPost from './components/HowTosModel/ModelBlogPost';
import DiagramsBlogPosts from "./components/HowTosDiagrams/DiagramsBlogPosts";
import DiagramsBlogPost from './components/HowTosDiagrams/DiagramsBlogPost';
import BeamBlogPosts from "./components/HowTosBeam/BeamBlogPosts";
import BeamBlogPost from './components/HowTosBeam/BeamBlogPost';
import ThermoPropertiesBlogPosts from "./components/HowTosThermoProperties/ThermoPropertiesBlogPosts";
import ThermoPropertiesBlogPost from './components/HowTosThermoProperties/ThermoPropertiesBlogPost';
import MatrixBlogPosts from "./components/HowTosMatrix/MatrixBlogPosts";
import MatrixBlogPost from './components/HowTosMatrix/MatrixBlogPost';
import LogicBlogPosts from "./components/HowTosLogic/LogicBlogPosts";
import LogicBlogPost from './components/HowTosLogic/LogicBlogPost';
import BodePlot from './components/ControlPlots/BodePlot';
import BodeBlogPost from './components/HowTosBode/BodeBlogPost';
import BodeBlogPosts from './components/HowTosBode/BodeBlogPosts';
import NyquistPlot from './components/ControlPlots/NyquistPlot';
import NyquistBlogPost from './components/HowTosNyquist/NyquistBlogPost';
import NyquistBlogPosts from './components/HowTosNyquist/NyquistBlogPosts';
import NicholsPlot from './components/ControlPlots/NicholsPlot';
import NicholsBlogPost from './components/HowTosNichols/NicholsBlogPost';
import NicholsBlogPosts from './components/HowTosNichols/NicholsBlogPosts';
import RootLocusPlot from './components/ControlPlots/RootLocusPlot';
import RootLocusBlogPost from './components/HowTosRootLocus/RootLocusBlogPost';
import RootLocusBlogPosts from './components/HowTosRootLocus/RootLocusBlogPosts';
import PoleZeroMap from './components/ControlPlots/PoleZeroMap';
import PoleZeroMapBlogPost from './components/HowTosPoleZeroMap/PoleZeroMapBlogPost';
import PoleZeroMapBlogPosts from './components/HowTosPoleZeroMap/PoleZeroMapBlogPosts';
import StepResponse from './components/ControlPlots/StepResponse';
import StepResponseBlogPost from './components/HowTosStepResponse/StepResponseBlogPost';
import StepResponseBlogPosts from './components/HowTosStepResponse/StepResponseBlogPosts';
import ImpulseResponse from './components/ControlPlots/ImpulseResponse';
import ImpulseResponseBlogPost from './components/HowTosImpulseResponse/ImpulseResponseBlogPost';
import ImpulseResponseBlogPosts from './components/HowTosImpulseResponse/ImpulseResponseBlogPosts';
import FeedbackControlSystem from './components/ControlPlots/FeedbackControlSystem';
import FeedbackControlSystemBlogPost from './components/HowTosFeedbackControlSystem/FeedbackControlSystemBlogPost';
import FeedbackControlSystemBlogPosts from './components/HowTosFeedbackControlSystem/FeedbackControlSystemBlogPosts';
import FeedbackControl from './components/FeedbackControl/FeedbackControl';
import FeedbackControlBlogPost from './components/HowTosFeedbackControl/FeedbackControlBlogPost';
import FeedbackControlBlogPosts from './components/HowTosFeedbackControl/FeedbackControlBlogPosts';
import Probability from './pages/Probability';
import Statistics from './pages/Statistics';
import Finance from './pages/Finance';
import Investing from './pages/Investing';
import PowerElectronics from './pages/PowerElectronics';
import { Box } from '@mui/material';
import './App.css';

function App() {
  const [searchString, setSearchString] = useState('');
  return (
        <Box sx={{ mt: 4 }}> {/* Apply top margin using the Box component */}
                <Router>
                  <NavBar setSearchString={setSearchString} />
                  <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/subscribe" element={<Subscribe />} />
                    <Route path="/logic" element={<Logic />} />
                    <Route path="/equations" element={<Equations />} />
                    <Route path="/matrix" element={<Matrix />} />
                    <Route path="/control" element={<Control />} />
                    <Route path="/controlsystems" element={<ControlSystems />} />
                    <Route path="/finiteelements" element={<FiniteElements />} />
                    <Route path="/thermodynamics" element={<Thermodynamics />} />
                    <Route path="/batterymanagement" element={<BatteryManagement />} />
                    <Route path="/electronics" element={<Electronics />} />
                    <Route path="/systems" element={<Systems />} />
                    <Route path="/diagrams" element={<Diagrams />} />
                    <Route path="/resume" element={<Resume />} />
                    <Route path="/resumeblogpost/:slugName" element={<ResumeBlogPost />} />
                    <Route path="/resumeblogposts" element={<ResumeBlogPosts />} />
                    <Route path="/addResume" element={<AddResume />} />
                    <Route path="/myResumes" element={<MyResumes />} />
                    <Route path="/fluids" element={<Fluids />} />
                    <Route path="/blogpost/:slugName" element={<BlogPost />} />
                    <Route path="/blogposts" element={<BlogPosts />} />
                    <Route path="/blogpostsplain" element={<BlogPostsPlain />} />
                    <Route path="/siteindex" element={<SiteIndex />} />
                    <Route path="/stocksblogpost/:slugName" element={<StocksBlogPost />} />
                    <Route path="/stocksblogposts" element={<StocksBlogPosts />} />
                    <Route path="/ganttblogpost/:slugName" element={<GanttBlogPost />} />
                    <Route path="/ganttblogposts" element={<GanttBlogPosts />} />
                    <Route path="/feablogpost/:slugName" element={<FeaBlogPost />} />
                    <Route path="/feablogposts" element={<FeaBlogPosts />} />
                    <Route path="/diagramsblogpost/:slugName" element={<DiagramsBlogPost />} />
                    <Route path="/diagramsblogposts" element={<DiagramsBlogPosts />} />
                    <Route path="/thermoplotsblogpost/:slugName" element={<ThermoPlotsBlogPost />} />
                    <Route path="/thermoplotsblogposts" element={<ThermoPlotsBlogPosts />} />
                    <Route path="/bmsblogpost/:slugName" element={<BmsBlogPost />} />
                    <Route path="/bmsblogposts" element={<BmsBlogPosts />} />
                    <Route path="/circuitblogpost/:slugName" element={<CircuitBlogPost />} />
                    <Route path="/circuitblogposts" element={<CircuitBlogPosts />} />
                    <Route path="/modelblogpost/:slugName" element={<ModelBlogPost />} />
                    <Route path="/modelblogposts" element={<ModelBlogPosts />} />
                    <Route path="/beamblogpost/:slugName" element={<BeamBlogPost />} />
                    <Route path="/beamblogposts" element={<BeamBlogPosts />} />
                    <Route path="/thermopropertiesblogpost/:slugName" element={<ThermoPropertiesBlogPost />} />
                    <Route path="/thermopropertiesblogposts" element={<ThermoPropertiesBlogPosts />} />
                    <Route path="/matrixblogpost/:slugName" element={<MatrixBlogPost />} />
                    <Route path="/matrixblogposts" element={<MatrixBlogPosts />} />
                    <Route path="/logicblogpost/:slugName" element={<LogicBlogPost />} />
                    <Route path="/logicblogposts" element={<LogicBlogPosts />} />
                    <Route path="/bode" element={<BodePlot />} />
                    <Route path="/bodeblogpost/:slugName" element={<BodeBlogPost />} />
                    <Route path="/bodeblogposts" element={<BodeBlogPosts />} />
                    <Route path="/nyquist" element={<NyquistPlot />} />
                    <Route path="/nyquistblogpost/:slugName" element={<NyquistBlogPost />} />
                    <Route path="/nyquistblogposts" element={<NyquistBlogPosts />} />
                    <Route path="/nichols" element={<NicholsPlot />} />
                    <Route path="/nicholsblogpost/:slugName" element={<NicholsBlogPost />} />
                    <Route path="/nicholsblogposts" element={<NicholsBlogPosts />} />
                    <Route path="/rootlocus" element={<RootLocusPlot />} />
                    <Route path="/rootlocusblogpost/:slugName" element={<RootLocusBlogPost />} />
                    <Route path="/rootlocusblogposts" element={<RootLocusBlogPosts />} />
                    <Route path="/polezeromap" element={<PoleZeroMap />} />
                    <Route path="/polezeromapblogpost/:slugName" element={<PoleZeroMapBlogPost />} />
                    <Route path="/polezeromapblogposts" element={<PoleZeroMapBlogPosts />} />
                    <Route path="/stepresponse" element={<StepResponse />} />
                    <Route path="/stepresponseblogpost/:slugName" element={<StepResponseBlogPost />} />
                    <Route path="/stepresponseblogposts" element={<StepResponseBlogPosts />} />
                    <Route path="/impulseresponse" element={<ImpulseResponse />} />
                    <Route path="/impulseresponseblogpost/:slugName" element={<ImpulseResponseBlogPost />} />
                    <Route path="/impulseresponseblogposts" element={<ImpulseResponseBlogPosts />} />
                    <Route path="/feedbackcontrolsystem" element={<FeedbackControlSystem />} />
                    <Route path="/feedbackcontrolsystemblogpost/:slugName" element={<FeedbackControlSystemBlogPost />} />
                    <Route path="/feedbackcontrolsystemblogposts" element={<FeedbackControlSystemBlogPosts />} />
                    <Route path="/feedbackcontrol" element={<FeedbackControl />} />
                    <Route path="/feedbackcontrolblogpost/:slugName" element={<FeedbackControlBlogPost />} />
                    <Route path="/feedbackcontrolblogposts" element={<FeedbackControlBlogPosts />} />
                    <Route path="/probability" element={<Probability />} />
                    <Route path="/statistics" element={<Statistics />} />
                    <Route path="/finance" element={<Finance />} />
                    <Route path="/investing" element={<Investing />} />
                    <Route path="/powerelectronics" element={<PowerElectronics />} />
                    <Route path="/stocks" element={<Stocks />} />
                    <Route path="/gantt" element={<Gantt />} />
                    <Route path="/fea" element={<Fea />} />
                    <Route path="/beam" element={<Beam />} />
                    <Route path="/thermoplots" element={<ThermoPlots />} />
                    <Route path="/bms" element={<Bms />} />
                    <Route path="/circuit" element={<Circuit />} />
                    <Route path="/netlist" element={<Netlist />} />
                    <Route path="/model" element={<Model />} />
                    <Route path="/thermoproperties" element={<ThermoProperties />} />
                    <Route path="/diagramdecoder" element={<DiagramDecoder />} />
                    <Route path="/diagramrete" element={<DiagramRete />} />
                    <Route path="/diagrammermaid" element={<DiagramMermaid />} />
                  </Routes>
                </Router>
        </Box>
  );
}

export default App;

