/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import axios from 'axios';
import { Box, Container, TextField, Button, Typography, Card, CardContent, Grid, CardMedia } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { keyframes } from '@emotion/react';
import JSZip from 'jszip';

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Control = () => {
    const { isAuthenticated, user } = useAuth0();
    const [numerator, setNumerator] = useState('');
    const [denominator, setDenominator] = useState('');
    const [bodePlotUrl, setBodePlotUrl] = useState(null);
    const [nyquistPlotUrl, setNyquistPlotUrl] = useState(null);
    const [rootLocusPlotUrl, setRootLocusPlotUrl] = useState(null);
    const [poleZeroPlotUrl, setPoleZeroPlotUrl] = useState(null);
    const [nicholsPlotUrl, setNicholsPlotUrl] = useState(null);
    const [stepResponsePlotUrl, setStepResponsePlotUrl] = useState(null);
    const [impulseResponsePlotUrl, setImpulseResponsePlotUrl] = useState(null);
    const [error, setError] = useState('');

    const handleAnalysis = async () => {
        if (!isAuthenticated || !user) {
            setError('User not authenticated');
            return;
        }

        try {
            const jwtResponse = await fetch('/.netlify/functions/generateJWTControl', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userEmail: user.email }),
            });

            if (!jwtResponse.ok) {
                throw new Error(`HTTP error ${jwtResponse.status}: ${jwtResponse.statusText}`);
            }

            const jwtData = await jwtResponse.json();
            const jwtToken = jwtData.token;
            const payload = {
                numerator: numerator.split(',').map(Number),
                denominator: denominator.split(',').map(Number)
            };

            const response = await fetch('https://wisxj6gs3c.us-west-2.awsapprunner.com/plots', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const zip = await JSZip.loadAsync(blob);
            const bodePlotBlob = await zip.file("bode_plot.png").async("blob");
            setBodePlotUrl(URL.createObjectURL(bodePlotBlob));
            const nyquistPlotBlob = await zip.file("nyquist_plot.png").async("blob");
            setNyquistPlotUrl(URL.createObjectURL(nyquistPlotBlob));
            const rootLocusPlotBlob = await zip.file("root_locus_plot.png").async("blob");
            setRootLocusPlotUrl(URL.createObjectURL(rootLocusPlotBlob));
            const poleZeroPlotBlob = await zip.file("pole_zero_map.png").async("blob");
            setPoleZeroPlotUrl(URL.createObjectURL(poleZeroPlotBlob));
            const nicholsPlotBlob = await zip.file("nichols_plot.png").async("blob");
            setNicholsPlotUrl(URL.createObjectURL(nicholsPlotBlob));
            const stepResponsePlotBlob = await zip.file("step_response_plot.png").async("blob");
            setStepResponsePlotUrl(URL.createObjectURL(stepResponsePlotBlob));
            const impulseResponsePlotBlob = await zip.file("impulse_response_plot.png").async("blob");
            setImpulseResponsePlotUrl(URL.createObjectURL(impulseResponsePlotBlob));

        } catch (err) {
            setError(`Error during the plot fetch operation: ${err.message}`);
            console.error(err);
        }
    };



    return (
        <Container maxWidth="xl">
            <Grid container spacing={3} alignItems="stretch" justifyContent="center">
                {/* Combined Column for Nyquist and Root Locus Plots */}
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '30px',
                            '& .MuiTextField-root': { mb: 2 },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#1c2734',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#9DC183',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#b9b9b9',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#9ca3af',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#9ca3af',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#9ca3af',
                            },
                        }}
                    >
                        <Card sx={{ ...bodePlotCardStyle, height: '100%' }}>
                            {/* ...CardContent and other components */}
                            <CardContent>
                                <Typography variant="h4" sx={headerTextStyle}>
                                    Transfer Function
                                </Typography>

                                <TextField
                                    label="Numerator Coefficients (comma-separated)"
                                    value={numerator}
                                    onChange={(e) => setNumerator(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    sx={{
                                        mb: 2,
                                        display: 'block', // Ensures the TextField behaves as a block-level element
                                        margin: '0 auto', // Centers the TextField within the Box
                                    }}
                                />
                                <TextField
                                    label="Denominator Coefficients (comma-separated)"
                                    value={denominator}
                                    onChange={(e) => setDenominator(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <Button
                                    width="50%"
                                    variant="contained"
                                    sx={{
                                        mt: 1,
                                        backgroundColor: '#b9b9b9',
                                        '&:hover': {
                                            backgroundColor: '#9DC183',
                                        },
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                    onClick={() => handleAnalysis('bode_plot')}>
                                    Plots
                                </Button>
                            </CardContent>
                        </Card>


                        {rootLocusPlotUrl && (
                            <Card sx={{ ...bodePlotCardStyle, height: '100%', marginTop:'40px' }} elevation={4}>
                                <CardContent>
                                    <Typography variant="h4" sx={headerTextStyle}>
                                        Root Locus
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    component="img"
                                    image={rootLocusPlotUrl}
                                    alt="Root Locus Plot"
                                    title="Root Locus Plot"
                                    sx={bodePlotImageStyle}
                                />
                            </Card>
                        )}
                        {stepResponsePlotUrl && (
                            <Card sx={{ ...bodePlotCardStyle, height: '100%', marginTop:'40px' }} elevation={4}>
                                <CardContent>
                                    <Typography variant="h4" sx={headerTextStyle}>
                                        Step
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    component="img"
                                    image={stepResponsePlotUrl}
                                    alt="Step Response Plot"
                                    title="Step Response Plot"
                                    sx={bodePlotImageStyle}
                                />
                            </Card>
                        )}


                    </Box>
                </Grid>

                <Grid item xs={12} md={4} >
                    <Box
                        sx={{ width: '100%', marginTop: '30px' }}
                    >
                        {nyquistPlotUrl && (
                            <Card sx={{ ...bodePlotCardStyle, height: '100%' }} elevation={4}>
                                <CardContent>
                                    <Typography variant="h4" sx={headerTextStyle}>
                                        Nyquist
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    component="img"
                                    image={nyquistPlotUrl}
                                    alt="Nyquist Plot"
                                    title="Nyquist Plot"
                                    sx={bodePlotImageStyle}
                                />
                            </Card>
                        )}

                        {nicholsPlotUrl && (
                            <Card sx={{ ...bodePlotCardStyle, height: '100%', marginTop:'40px' }} elevation={4}>
                                <CardContent>
                                    <Typography variant="h4" sx={headerTextStyle}>
                                        Nichols
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    component="img"
                                    image={nicholsPlotUrl}
                                    alt="Nichols Plot"
                                    title="Nichols Plot"
                                    sx={bodePlotImageStyle}
                                />
                            </Card>
                        )}
                        {impulseResponsePlotUrl && (
                            <Card sx={{ ...bodePlotCardStyle, height: '100%', marginTop:'40px' }} elevation={4}>
                                <CardContent>
                                    <Typography variant="h4" sx={headerTextStyle}>
                                        Impulse
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    component="img"
                                    image={impulseResponsePlotUrl}
                                    alt="Impulse Response Plot"
                                    title="Impulse Response Plot"
                                    sx={bodePlotImageStyle}
                                />
                            </Card>
                        )}

                    </Box>
                </Grid>
                <Grid item xs={12} md={4} >
                    <Box sx={{ width: '100%', marginTop: '30px' }}>
                        {bodePlotUrl && (
                            <Card sx={{ ...bodePlotCardStyle, height: '100%' }} elevation={4}>
                                <CardContent>
                                    <Typography variant="h4" sx={headerTextStyle}>
                                        Bode
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    component="img"
                                    image={bodePlotUrl}
                                    alt="BodeBlogPost Plot"
                                    title="BodeBlogPost Plot"
                                    sx={bodePlotImageStyle}
                                />
                            </Card>
                        )}
                        {poleZeroPlotUrl && (
                            <Card sx={{ ...bodePlotCardStyle, height: '100%', marginTop:'40px' }} elevation={4}>
                                <CardContent>
                                    <Typography variant="h4" sx={headerTextStyle}>
                                        Pole Zero Map
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    component="img"
                                    image={poleZeroPlotUrl}
                                    alt="Pole Zero Map"
                                    title="Pole Zero Map"
                                    sx={bodePlotImageStyle}
                                />
                            </Card>
                        )}


                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

const cardStyle = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px'
};

const bodePlotCardStyle = {
    ...cardStyle, // Reuse the base styles and add specific styles for the BodeBlogPost plot card below
    maxWidth: 345, // Set a max-width for larger screens
    margin: 'auto', // Center the card
};

const bodePlotImageStyle = {
    width: '100%', // Make the image responsive
    height: 'auto', // Maintain aspect ratio
};

const headerTextStyle = {
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: `${rainbowAnimation} 10s ease infinite`,
    backgroundSize: '200% 200%',
    marginBottom: '10px'
};

const gridContainerStyle = {
    display: 'flex', // Use flexbox for alignment
    flexDirection: 'row', // Align items in a row
    alignItems: 'flex-start', // Align items to the start of the flex direction
    justifyContent: 'center', // Center the items horizontally
    gap: '24px', // Add gap between items for spacing
};


export default Control;

