/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, TextField, Button, Typography, Box, Grid } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ResumeDocument from './PdfRenderer';
import { keyframes } from "@emotion/react";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const initialValues = {
    name: '',
    email: '',
    phone: '',
    address: '',
    developerProfile: '',
    linkedinProfile: '',
    education: [''],
    experience: [{ employer: '', position: '', accomplishments: [''] }],
    academicProjects: [{ type: '', client: '', accomplishments: [''] }],
    leadershipExperience: [{ organization: '', position: '', accomplishments: [''] }],
    honors: [''],
    additional: {
        computerSkills: '',
        languages: '',
        interests: '',
        workEligibility: '',
        certifications: ''
    }
};

const Resume = () => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                console.log('Form Values:', values);
            }}
        >
            {({ values, handleChange }) => (
                <Container maxWidth="md" sx={{ bgcolor: '#2c2c2c', p: 4, borderRadius: 5 }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>Contact Info</Typography>
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        style: { color: '#fff' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#b9b9b9' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        style: { color: '#fff' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#b9b9b9' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Phone"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        style: { color: '#fff' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#b9b9b9' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Address"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        style: { color: '#fff' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#b9b9b9' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Developer Profile"
                                    name="developerProfile"
                                    value={values.developerProfile}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        style: { color: '#fff' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#b9b9b9' }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="LinkedIn Profile"
                                    name="linkedinProfile"
                                    value={values.linkedinProfile}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        style: { color: '#fff' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: '#b9b9b9' }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <FieldArray
                            name="experience"
                            render={arrayHelpers => (
                                <Box sx={{ marginBottom: 3 }}>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>Experience</Typography>
                                    {values.experience.map((exp, index) => (
                                        <Box key={index} sx={{ marginBottom: 3 }}>
                                            <TextField
                                                label="Employer"
                                                name={`experience.${index}.employer`}
                                                value={exp.employer}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{
                                                    style: { color: '#fff', fontWeight: 'medium' }
                                                }}
                                                InputLabelProps={{
                                                    style: { color: '#b9b9b9' }
                                                }}
                                            />
                                            <TextField
                                                label="Position"
                                                name={`experience.${index}.position`}
                                                value={exp.position}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{
                                                    style: { color: '#fff' }
                                                }}
                                                InputLabelProps={{
                                                    style: { color: '#b9b9b9' }
                                                }}
                                            />
                                            <FieldArray
                                                name={`experience.${index}.accomplishments`}
                                                render={subArrayHelpers => (
                                                    <Box>
                                                        {exp.accomplishments.map((item, subIndex) => (
                                                            <Box key={subIndex} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                                <TextField
                                                                    label={`Accomplishment ${subIndex + 1}`}
                                                                    name={`experience.${index}.accomplishments.${subIndex}`}
                                                                    value={item}
                                                                    onChange={handleChange}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    InputProps={{
                                                                        style: { color: '#fff' }
                                                                    }}
                                                                    InputLabelProps={{
                                                                        style: { color: '#b9b9b9' }
                                                                    }}
                                                                />
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => subArrayHelpers.remove(subIndex)}
                                                                    sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            sx={{ bgcolor: '#353535', color: '#fffffe', width: '30%' }}
                                                            onClick={() => subArrayHelpers.push('')}
                                                        >
                                                            Add Accomplishment
                                                        </Button>
                                                    </Box>
                                                )}
                                            />
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                sx={{ marginTop: 2, bgcolor: '#353535', width: '30%' }}
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                Remove Experience
                                            </Button>
                                        </Box>
                                    ))}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                        onClick={() => arrayHelpers.push({ employer: '', position: '', accomplishments: [''] })}
                                    >
                                        Add Experience
                                    </Button>
                                </Box>
                            )}
                        />

                        <FieldArray
                            name="academicProjects"
                            render={arrayHelpers => (
                                <Box sx={{ marginBottom: 3 }}>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>Projects</Typography>
                                    {values.academicProjects.map((project, index) => (
                                        <Box key={index} sx={{ marginBottom: 3 }}>
                                            <TextField
                                                label="Type of Project"
                                                name={`academicProjects.${index}.type`}
                                                value={project.type}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{
                                                    style: { color: '#fff' }
                                                }}
                                                InputLabelProps={{
                                                    style: { color: '#b9b9b9' }
                                                }}
                                            />
                                            <TextField
                                                label="Client"
                                                name={`academicProjects.${index}.client`}
                                                value={project.client}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{
                                                    style: { color: '#fff' }
                                                }}
                                                InputLabelProps={{
                                                    style: { color: '#b9b9b9' }
                                                }}
                                            />
                                            <FieldArray
                                                name={`academicProjects.${index}.accomplishments`}
                                                render={subArrayHelpers => (
                                                    <Box>
                                                        {project.accomplishments.map((item, subIndex) => (
                                                            <Box key={subIndex} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                                <TextField
                                                                    label={`Accomplishment ${subIndex + 1}`}
                                                                    name={`academicProjects.${index}.accomplishments.${subIndex}`}
                                                                    value={item}
                                                                    onChange={handleChange}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    InputProps={{
                                                                        style: { color: '#fff' }
                                                                    }}
                                                                    InputLabelProps={{
                                                                        style: { color: '#b9b9b9' }
                                                                    }}
                                                                />
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => subArrayHelpers.remove(subIndex)}
                                                                    sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            sx={{ bgcolor: '#353535', color: '#fffffe', width: '30%' }}
                                                            onClick={() => subArrayHelpers.push('')}
                                                        >
                                                            Add Accomplishment
                                                        </Button>
                                                    </Box>
                                                )}
                                            />
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="secondary"
                                                sx={{ marginTop: 2, bgcolor: '#353535', width: '30%' }}
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                Remove Project
                                            </Button>
                                        </Box>
                                    ))}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                        onClick={() => arrayHelpers.push({ type: '', client: '', accomplishments: [''] })}
                                    >
                                        Add Project
                                    </Button>
                                </Box>
                            )}
                        />

                        <FieldArray
                            name="leadershipExperience"
                            render={arrayHelpers => (
                                <Box sx={{ marginBottom: 3 }}>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>Leadership</Typography>
                                    {values.leadershipExperience.length > 0 && values.leadershipExperience.some((exp) => exp.organization || exp.position || exp.accomplishments.length > 0) && (
                                        values.leadershipExperience.map((exp, index) => (
                                            <Box key={index} sx={{ marginBottom: 3 }}>
                                                <TextField
                                                    label="Organization"
                                                    name={`leadershipExperience.${index}.organization`}
                                                    value={exp.organization}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{
                                                        style: { color: '#fff' }
                                                    }}
                                                    InputLabelProps={{
                                                        style: { color: '#b9b9b9' }
                                                    }}
                                                />
                                                <TextField
                                                    label="Position"
                                                    name={`leadershipExperience.${index}.position`}
                                                    value={exp.position}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{
                                                        style: { color: '#fff' }
                                                    }}
                                                    InputLabelProps={{
                                                        style: { color: '#b9b9b9' }
                                                    }}
                                                />
                                                <FieldArray
                                                    name={`leadershipExperience.${index}.accomplishments`}
                                                    render={subArrayHelpers => (
                                                        <Box>
                                                            {exp.accomplishments.map((item, subIndex) => (
                                                                <Box key={subIndex} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                                    <TextField
                                                                        label={`Accomplishment ${subIndex + 1}`}
                                                                        name={`leadershipExperience.${index}.accomplishments.${subIndex}`}
                                                                        value={item}
                                                                        onChange={handleChange}
                                                                        fullWidth
                                                                        margin="normal"
                                                                        InputProps={{
                                                                            style: { color: '#fff' }
                                                                        }}
                                                                        InputLabelProps={{
                                                                            style: { color: '#b9b9b9' }
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        onClick={() => subArrayHelpers.remove(subIndex)}
                                                                        sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </Box>
                                                            ))}
                                                            <Button
                                                                type="button"
                                                                variant="contained"
                                                                sx={{ bgcolor: '#353535', color: '#fffffe', width: '30%' }}
                                                                onClick={() => subArrayHelpers.push('')}
                                                            >
                                                                Add Accomplishment
                                                            </Button>
                                                        </Box>
                                                    )}
                                                />
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="secondary"
                                                    sx={{ marginTop: 2, bgcolor: '#353535', width: '30%' }}
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    Remove Experience
                                                </Button>
                                            </Box>
                                        ))
                                    )}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                        onClick={() => arrayHelpers.push({ organization: '', position: '', accomplishments: [''] })}
                                    >
                                        Add Experience
                                    </Button>
                                </Box>
                            )}
                        />

                        {['education', 'honors'].map(section => (
                            <FieldArray
                                key={section}
                                name={section}
                                render={arrayHelpers => (
                                    <Box sx={{ marginBottom: 3 }}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>{section.charAt(0).toUpperCase() + section.slice(1)}</Typography>
                                        {values[section].map((item, index) => (
                                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                <TextField
                                                    name={`${section}.${index}`}
                                                    value={item}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{
                                                        style: { color: '#fff' }
                                                    }}
                                                    InputLabelProps={{
                                                        style: { color: '#b9b9b9' }
                                                    }}
                                                />
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="secondary"
                                                    sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    Remove
                                                </Button>
                                            </Box>
                                        ))}
                                        <Button
                                            type="button"
                                            variant="contained"
                                            sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                            onClick={() => arrayHelpers.push('')}
                                        >
                                            Add {section.charAt(0).toUpperCase() + section.slice(1)}
                                        </Button>
                                    </Box>
                                )}
                            />
                        ))}

                        <Box sx={{ marginBottom: 3 }}>
                            <Typography variant="h6" gutterBottom style={{ fontWeight: 'medium', textTransform: 'uppercase', color: '#fffffe' }}>Additional Information</Typography>
                            <TextField
                                label="Computer Skills"
                                name="additional.computerSkills"
                                value={values.additional.computerSkills}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#b9b9b9' }
                                }}
                            />
                            <TextField
                                label="Languages"
                                name="additional.languages"
                                value={values.additional.languages}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#b9b9b9' }
                                }}
                            />
                            <TextField
                                label="Interests"
                                name="additional.interests"
                                value={values.additional.interests}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#b9b9b9' }
                                }}
                            />
                            <TextField
                                label="Work Eligibility"
                                name="additional.workEligibility"
                                value={values.additional.workEligibility}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#b9b9b9' }
                                }}
                            />
                            <TextField
                                label="Certifications"
                                name="additional.certifications"
                                value={values.additional.certifications}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#b9b9b9' }
                                }}
                            />
                        </Box>

                        <Box sx={{ marginTop: 3, marginBottom: 3, textAlign: 'center' }}>
                            <PDFDownloadLink
                                document={<ResumeDocument formData={values} />}
                                fileName="resume.pdf"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#15a2fa',
                                                '&:hover': {
                                                    backgroundColor: '#9DC183',
                                                },
                                                textDecoration: 'none',
                                                width: '30%',
                                                display: 'block',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                animation: `${pulseAnimation} 2s infinite`,
                                            }}
                                            onClick={() => window.open(url, '_blank')}
                                        >
                                            Download PDF
                                        </Button>
                                    )
                                }
                            </PDFDownloadLink>
                        </Box>
                    </Form>
                </Container>
            )}
        </Formik>
    );
};

export default Resume;
