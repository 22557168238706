import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';

function TextNodeRight({ data }) {
    const [text, setText] = useState(data.label || '');

    return (
        <div style={{ padding: 10, border: '1px solid #fff', borderRadius: '5px', width: '120px' }}>
            <input
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={{ width: '100%', background: 'transparent', border: 'none', color: '#fff', textAlign:'center' }}
            />
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
        </div>
    );
}

export default memo(TextNodeRight);
