/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import mermaid from 'mermaid';
import './DiagramMermaid.css'; // New CSS file for additional styles
import { InputNode, SumNode, PIDNode, PlantNode, ScopeNode, GainNode } from './NodesMermaid';

const initialNodes = [];
const initialEdges = [];

const DiagramMermaid = () => {
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);
    const [mermaidCode, setMermaidCode] = useState('');
    const mermaidRef = useRef(null);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const reactFlowBounds = event.target.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            if (typeof type === 'undefined' || !type) return;

            const position = {
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            };
            const newNode = {
                id: `${+new Date()}`,
                type,
                position,
                data: { label: `${type} node` },
            };
            setNodes((nds) => nds.concat(newNode));
        },
        [setNodes]
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const generateMermaidCode = useCallback(() => {
        const mermaidNodes = nodes.map(node => `    ${node.id}["${node.data.label}"]`);
        const mermaidEdges = edges.map(edge => `    ${edge.source} --> ${edge.target}`);
        const code = `graph TD\n${[...mermaidNodes, ...mermaidEdges].join('\n')}`;
        setMermaidCode(code);
    }, [nodes, edges]);

    useEffect(() => {
        if (mermaidRef.current) {
            mermaid.initialize({ startOnLoad: true, theme: "dark" });
            mermaid.contentLoaded();
        }
    }, [mermaidCode]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div style={{ display: 'flex', height: '90%' }}>
                <div className="sidebar">
                    <div className="sidebar-title">NebulaSolver Library:</div>
                    <div
                        className="node-item"
                        onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'input')}
                        draggable
                    >
                        Input
                    </div>
                    <div
                        className="node-item"
                        onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'sum')}
                        draggable
                    >
                        Sum
                    </div>
                    <div
                        className="node-item"
                        onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'pid')}
                        draggable
                    >
                        PID
                    </div>
                    <div
                        className="node-item"
                        onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'plant')}
                        draggable
                    >
                        Plant
                    </div>
                    <div
                        className="node-item"
                        onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'scope')}
                        draggable
                    >
                        Scope
                    </div>
                    <div
                        className="node-item"
                        onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'gain')}
                        draggable
                    >
                        Gain
                    </div>
                </div>
                <div
                    style={{ width: '80%', height: '100%', background: '#2a2a2a', border: '1px solid #444', marginLeft: '10px', color: 'white', position: 'relative' }}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                >
                    {nodes.map(node => (
                        <div
                            key={node.id}
                            style={{
                                position: 'absolute',
                                left: node.position.x,
                                top: node.position.y,
                                border: '1px solid #444',
                                background: '#555',
                                padding: '5px',
                                color: 'white',
                                cursor: 'pointer'
                            }}
                        >
                            {node.data.label}
                        </div>
                    ))}
                    <div ref={mermaidRef} className="mermaid">
                        {mermaidCode}
                    </div>
                </div>
            </div>
            <div style={{ height: '10%', padding: '10px', background: '#2a2a2a' }}>
                <button onClick={generateMermaidCode} style={{ marginTop: '20px' }}>Generate Mermaid Diagram</button>
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', marginTop: '10px', color: 'white' }}>
                    {mermaidCode}
                </pre>
            </div>
        </div>
    );
};

export default DiagramMermaid;
