import * as React from 'react';

function BuyButtonComponent({ userEmail}) {
    // Generating client-reference-id
    //const clientReferenceId = `${userEmail}#${publicFigureId}`;

    return (
        <stripe-buy-button
            buy-button-id="buy_btn_1P64VpDoiJh8VhvkaAqJftQx"
            publishable-key="pk_live_51On3ozDoiJh8VhvkYyjO38LKwfwPjcQe8X785Sx3SG468gQ5U4xCL4W6H8nlQ4G4ix2p8OdBYnTF7cECVcGsUsCP00DPBLl2dK"
            customer-email={userEmail}
        >
        </stripe-buy-button>
    );
}

export default BuyButtonComponent;
