/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, CardMedia, CardContent, Typography, Container, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import {Helmet} from "react-helmet";

const StyledCardMedia = styled(CardMedia)({
    paddingTop: '56.25%', // 16:9
});

const StyledCardVideo = styled('video')({
    width: '100%',
    height: 'auto',
});

const StyledCard = styled(Card)({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    margin: '1em auto', // for centering and spacing
});

function LogicBlogPost() {
    const { slugName } = useParams();
    const [item, setItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.post('/.netlify/functions/getLogicBlogPostItem', { slugName })
            .then(response => {
                setItem(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching item:', error);
                setIsLoading(false);
            });
    }, [slugName]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (!item) {
        return <p>No item found for this id.</p>;
    }

    // A function to create markup
    const createMarkup = htmlContent => {
        return {__html: htmlContent};
    };

    return (
        <Container maxWidth="md">
            <Helmet>
                <title>{item.title ? `${item.title} - NebulaSolver` : 'Blog Post - NebulaSolver'}</title>
                <link rel="canonical" href={`https://nebulasolver.com/logicblogpost/${slugName}`} />
                <meta
                    name="description"
                    content={item.shortDescription ? `${item.shortDescription.substring(0, 150)}...` : "Explore Blog Posts on NebulaSolver."}
                />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/logicblogpost/${slugName}`} />
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/logicblogpost/${slugName}`} />
            </Helmet>

            <div
                className="markdown-container"
                style={{
                    backgroundColor: '#353535',
                    padding: '15px',
                    borderRadius: '15px',
                    marginBottom: '15px',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    textAlign: 'left',
                    color: '#fffffe',
                }}
                dangerouslySetInnerHTML={createMarkup(item.detailedReview)}
            />
        </Container>
    );
}
export default LogicBlogPost;
