import React from 'react';
import { Handle, Position } from 'reactflow';
import './nodeStyles.css';

const UnityNode = ({ data }) => {
    return (
        <div className="node unity-node">
            <div className="unity-label">1</div>
            <Handle
                type="target"
                position={Position.Right}
                id="left"
                style={{ background: '#555' }}
            />
            <Handle
                type="source"
                position={Position.Left}
                id="right"
                style={{ background: '#555' }}
            />
        </div>
    );
};

export default UnityNode;
