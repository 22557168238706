/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, IconButton } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Container } from '@mui/material';
import { Helmet } from "react-helmet";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ThermoPlotsBlogPostList from "./ThermoPlotsBlogPostList";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Define a pulse animation for buttons
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: 'none',
    borderRadius: '15px',
    padding: '0',
    marginTop: '0rem',
    backgroundColor: '#353535',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    marginBottom: '2rem',
    height: 'auto',
    overflow: 'hidden', // Ensure overflow content is hidden
    textAlign: 'center'
});

const hoverAnimation = keyframes`
  0% { color: #b9b9b9; }
  100% { color: #9DC183; }
`;

const ThermoPlotsBlogPosts = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mt: 0
                }}
            >
                <StyledCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                onClick={handleExpandClick}
                                sx={{
                                    color: '#9DC183', // Update arrow color
                                    padding: '4px', // Reduce padding for the button
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '0px' }}>
                                Thermo Blog
                            </Typography>
                        </Box>
                        <Box sx={{ display: expanded ? 'block' : 'none', paddingLeft: '40px' }}>
                            <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2, color:'#fffffe' }}>
                                Thermodynamic Plots Tutorials and Articles
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: 2, color: '#b9b9b9' }}>
                                Learn how to create and analyze various thermodynamic plots for system performance.
                            </Typography>
                        </Box>
                    </CardContent>
                </StyledCard>
                <ThermoPlotsBlogPostList />
            </Box>
            <Helmet>
                <title>How To's - Thermo Plots Blog Posts</title>
                <link rel="canonical" href={`https://nebulasolver.com/thermoplotsblogposts`} />
                <meta
                    name="description"
                    content="Explore comprehensive articles and tutorials on thermodynamic plots using NebulaSolver. Learn how to create and analyze various thermodynamic plots for system performance."
                />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/thermoplotsblogposts`} />
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/thermoplotsblogposts`} />
                <meta property="og:title" content="How To's - Thermo Plots Blog Posts" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/thermoplotsblogposts" />
                <meta property="og:description" content="Explore comprehensive articles and tutorials on thermodynamic plots using NebulaSolver. Learn how to create and analyze various thermodynamic plots for system performance." />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How To's - Thermo Plots Blog Posts" />
                <meta name="twitter:description" content="Explore comprehensive articles and tutorials on thermodynamic plots using NebulaSolver. Learn how to create and analyze various thermodynamic plots for system performance." />
                <meta name="twitter:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};

export default ThermoPlotsBlogPosts;
