/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// src/components/NodesRete.jsx
import { ClassicPreset } from 'rete'; // Import ClassicPreset

class CustomNode extends ClassicPreset.Node {
    constructor() {
        super('Custom Node');
    }

    async builder(node) {
        const socket = new ClassicPreset.Socket('any');
        const inp1 = new ClassicPreset.Input('input', 'Input', socket);
        const out = new ClassicPreset.Output('output', 'Output', socket);

        node.addInput(inp1).addOutput(out);

        return node;
    }

    worker(node, inputs, outputs) {
        outputs['output'] = inputs['input'].length ? inputs['input'][0] : node.data.value;
    }
}

export const CustomNodeInstance = new CustomNode();
