/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Box, Card, CardContent, Link } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Container } from '@mui/material';
import { Helmet } from "react-helmet";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const hoverAnimation = keyframes`
  0% { color: #fffffe; }
  100% { color: #9DC183; }
`;

const SiteIndex = () => {
    const blogPosts = [
        { title: "Logic Solver", url: "https://nebulasolver.com/logic" },
        { title: "Equation Solver", url: "https://nebulasolver.com/equations" },
        { title: "Linear Algebra", url: "https://nebulasolver.com/matrix" },
        { title: "Bode Plot", url: "https://nebulasolver.com/bode" },
        { title: "Nyquist Plot", url: "https://nebulasolver.com/nyquist" },
        { title: "Nichols", url: "https://nebulasolver.com/nichols" },
        { title: "Root Locus", url: "https://nebulasolver.com/rootlocus" },
        { title: "Pole Zero Map", url: "https://nebulasolver.com/polezeromap" },
        { title: "Step Response", url: "https://nebulasolver.com/stepresponse" },
        { title: "Impulse Response", url: "https://nebulasolver.com/impulseresponse" },
        { title: "PID Control System", url: "https://nebulasolver.com/feedbackcontrolsystem" },
        { title: "2D-Truss Analysis", url: "https://nebulasolver.com/fea" },
        { title: "Beam Analysis", url: "https://nebulasolver.com/beam" },
        { title: "Thermodynamic Diagrams", url: "https://nebulasolver.com/thermoplots" },
        { title: "Battery Management Systems", url: "https://nebulasolver.com/bms" },
        { title: "Circuit Analysis", url: "https://nebulasolver.com/circuit" },
        { title: "Model Simulation", url: "https://nebulasolver.com/model" },
        { title: "Diagrams", url: "https://nebulasolver.com/diagrams" },
        { title: "Gantt Chart", url: "https://nebulasolver.com/gantt" },
        { title: "Stock Analysis", url: "https://nebulasolver.com/stocks" },
        { title: "Resume Builder", url: "https://nebulasolver.com/resume" },
        { title: "Logic Solver Blog Posts", url: "https://nebulasolver.com/logicblogposts" },
        { title: "Equation Solver Blog Posts", url: "https://nebulasolver.com/blogpostsplain" },
        { title: "Linear Algebra Blog Posts", url: "https://nebulasolver.com/matrixblogposts" },
        { title: "Bode Plot Blog Posts", url: "https://nebulasolver.com/bodeblogposts" },
        { title: "Nyquist Plot Blog Posts", url: "https://nebulasolver.com/nyquistblogposts" },
        { title: "Nichols Plot Blog Posts", url: "https://nebulasolver.com/nicholsblogposts" },
        { title: "Root Locus Blog Posts", url: "https://nebulasolver.com/rootlocusblogposts" },
        { title: "Pole Zero Map Blog Posts", url: "https://nebulasolver.com/polezeromapblogposts" },
        { title: "Step Response Blog Posts", url: "https://nebulasolver.com/stepresponseblogposts" },
        { title: "Impulse Response Blog Posts", url: "https://nebulasolver.com/impulseresponseblogposts" },
        { title: "PID Control System Blog Posts", url: "https://nebulasolver.com/feedbackcontrolsystemblogposts" },
        { title: "2D-Truss Analysis Blog Posts", url: "https://nebulasolver.com/feablogposts" },
        { title: "Beam Analysis Blog Posts", url: "https://nebulasolver.com/beamblogposts" },
        { title: "Thermodynamic Diagrams Blog Posts", url: "https://nebulasolver.com/thermoplotsblogposts" },
        { title: "Battery Management Systems Blog Posts", url: "https://nebulasolver.com/bmsblogposts" },
        { title: "Circuit Blog Posts", url: "https://nebulasolver.com/circuitblogposts" },
        { title: "Model Blog Posts", url: "https://nebulasolver.com/modelblogposts" },
        { title: "Diagrams Blog Posts", url: "https://nebulasolver.com/diagramsblogposts" },
        { title: "Gantt Chart Blog Posts", url: "https://nebulasolver.com/ganttblogposts" },
        { title: "Stock Analysis Blog Posts", url: "https://nebulasolver.com/stocksblogposts" },
        { title: "Resume Blog Posts", url: "https://nebulasolver.com/resumeblogposts" },
        { title: "Beam Analysis with NebulaSolver's Mini App", url: "https://nebulasolver.com/beamblogpost/beam-analysis-with-nebulasolver" },
        { title: "Solving Non-Linear Equations with NebulaSolver's Features", url: "https://nebulasolver.com/blogpost/how-to-use-the-equation-solver" },
        { title: "NebulaSolver.com - Cloud-Based Computational Platform", url: "https://nebulasolver.com/blogpost/nebulasolver-cloud-based-computational-platform" },
        { title: "Non-Linear Equation Solver with Trigonometric, Exponential, and Logarithmic Capabilities", url: "https://nebulasolver.com/blogpost/non-linear-equation-solver-with-trigonometric-exponential-logarithms" },
        { title: "Battery Performance with NebulaSolver's BMS Simulation App", url: "https://nebulasolver.com/bmsblogpost/battery-performance-with-nebulasolver" },
        { title: "Create Bode Plots on NebulaSolver.com", url: "https://nebulasolver.com/bodeblogpost/mastering-bode-plots-on-nebulasolver" },
        { title: "Circuit Simulations with NebulaSolver's Mini App", url: "https://nebulasolver.com/circuitblogpost/circuit-simulation-with-nebulasolver" },
        { title: "Browser-Based Diagramming with NebulaSolver", url: "https://nebulasolver.com/diagramsblogpost/browser-based-diagramming-with-nebulasolver" },
        { title: "Analyzing Truss Structures with NebulaSolver's 2D-Truss Mini App", url: "https://nebulasolver.com/feablogpost/analyzing-truss-structures-with-nebulasolver-2d-truss-app" },
        { title: "Performance Analysis with NebulaSolver's New Feedback Control System App", url: "https://nebulasolver.com/feedbackcontrolsystemblogpost/performance-analysis-feedback-control-system-with-pid-action" },
        { title: "Manage Projects with NebulaSolver's Gantt Chart Tool", url: "https://nebulasolver.com/ganttblogpost/manage-projects-with-nebulasolver-gantt-chart-tool" },
        { title: "Project Management with Gantt Charts", url: "https://nebulasolver.com/ganttblogpost/project-management-with-gantt-charts" },
        { title: "Impulse Response Analysis with NebulaSolver.com", url: "https://nebulasolver.com/impulseresponseblogpost/step-response-on-nebulasolver" },
        { title: "Simplify Boolean Expressions with NebulaSolver.com's Logic Solver", url: "https://nebulasolver.com/logicblogpost/simplify-boolean-expressions-with-nebulasolver-logic-solver" },
        { title: "Disjunctive Normal Forms with NebulaSolver Logic Solver", url: "https://nebulasolver.com/logicblogpost/disjunctive-normal-forms-with-nebulasolver-logic-solver" },
        { title: "Logic Simplification in Digital Circuit Design", url: "https://nebulasolver.com/logicblogpost/logic-simplification-in-digital-circuit-design" },
        { title: "Computational Boolean Logic Simplification vs. Karnaugh Maps", url: "https://nebulasolver.com/logicblogpost/computational-boolean-logic-simplification-vs-karnaugh-maps" },
        { title: "Performing Matrix Operations with NebulaSolver.com", url: "https://nebulasolver.com/matrixblogpost/performing-matrix-operations-with-nebulasolver" },
        { title: "Understanding Linear Algebra: The Power of Matrix Operations", url: "https://nebulasolver.com/matrixblogpost/understanding-linear-algebra-the-power-of-matrix-operations" },
        { title: "Linear Algebra Solver: Determinants, Eigenvalues, and More", url: "https://nebulasolver.com/matrixblogpost/linear-algebra-solver-determinants-eigenvalues-and-more" },
        { title: "Systems Engineering with NebulaSolver's FMU-Based Simulations", url: "https://nebulasolver.com/modelblogpost/systems-engineering-with-nebulasolver-fmu-based-simulations" },
        { title: "Create Nichols Plots with Precision on NebulaSolver.com", url: "https://nebulasolver.com/nicholsblogpost/nichols-plot-on-nebulasolver" },
        { title: "Generate Nyquist Plots on NebulaSolver.com", url: "https://nebulasolver.com/nyquistblogpost/nyquist-plot-on-nebulasolver" },
        { title: "Creating Pole Zero Maps with NebulaSolver.com", url: "https://nebulasolver.com/polezeromapblogpost/pole-zero-map-on-nebulasolver" },
        { title: "Generate Root Locus Plots on NebulaSolver.com", url: "https://nebulasolver.com/rootlocusblogpost/root-locus-plot-on-nebulasolver" },
        { title: "Generate Step Response Plots Easily with NebulaSolver.com", url: "https://nebulasolver.com/stepresponseblogpost/step-response-on-nebulasolver" },
        { title: "Unlocking Financial Insights with NebulaSolver's Stock Analysis Tool", url: "https://nebulasolver.com/stocksblogpost/unlocking-financial-insights-with-nebulasolver-stock-analysis-tool" },
        { title: "Visualizing Stock Performance: Key Metrics and Effective Plots", url: "https://nebulasolver.com/stocksblogpost/visualizing-stock-performance-key-metrics-and-effective-plots" },
        { title: "Visualizing Thermodynamic Diagrams with NebulaSolver's App", url: "https://nebulasolver.com/thermoplotsblogpost/visualizing-thermodynamic-diagrams-with-nebulasolver" },
        { title: "Instant Resume Creation with NebulaSolver: Free, One-Step Solution for Professional Results", url: "https://nebulasolver.com/resumeblogpost/instant-resume-creator-free-no-registration" }
    ];

    return (
        <Container maxWidth="md">
            <Helmet>
                <title>Site Index - NebulaSolver</title>
                <link rel="canonical" href={`https://nebulasolver.com/siteindex`} />
                <meta
                    name="description"
                    content="NebulaSolver - Find all the urls related to various numerical solvers and simulators in one place."
                />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/siteindex`} />
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/siteindex`} />
                <meta property="og:title" content="NebulaSolver - Site Index" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/siteindex" />
            </Helmet>

            <Box sx={{ marginTop: '0px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                    <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                        <CardContent sx={{ padding: '14px 0px' }}>
                            <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                                Site Index
                            </Typography>
                            <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: 'false' }}>
                                {blogPosts.map((post, index) => (
                                    <Link
                                        key={index}
                                        href={post.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            display: 'block',
                                            mb: 1,
                                            color: '#fffffe',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                animation: `${hoverAnimation} 0.3s forwards`
                                            }
                                        }}
                                    >
                                        <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                        {post.title}
                                    </Link>
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Container>
    );
};

export default SiteIndex;
