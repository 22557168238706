/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Box, TextField, Button, Typography, Card, CardContent, CircularProgress, Grid, Link } from '@mui/material';
import { keyframes } from '@emotion/react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MatrixBlogPosts from "../components/HowTosMatrix/MatrixBlogPosts";

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Define a pulse animation for buttons
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const PricingBox = () => (
    <Grid>
        <Box
            sx={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#353535',
                    borderRadius: '15px',
                    border: '2px solid #35353500',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                            marginBottom: '10px',
                        }}
                    >
                        Pricing
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{ color: '#9DC183', marginBottom: '20px' }}
                        >
                            $9.99 / year
                        </Typography>
                        <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
                            {[
                                'Full access to all the apps',
                                'Free articles and tutorials',
                                'No need to install anything',
                                'Auth0 Authentication',
                                'Stripe Payments Integration'
                            ].map((item) => (
                                <Typography
                                    key={item}
                                    variant="h6"
                                    sx={{ color: '#b9b9b9', marginBottom: '5px', display: 'flex', alignItems: 'center' }}
                                >
                                    <i className="fas fa-check-circle" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', backgroundColor: '#353535' }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                        Get NebulaSolver
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, color: '#b9b9b9' }}>
                        Subscribe to access the Linear Algebra Tool.
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/subscribe"
                        sx={{
                            backgroundColor: '#15a2fa',
                            '&:hover': {
                                backgroundColor: '#9DC183',
                            },
                            width: '50%',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            animation: `${pulseAnimation} 2s infinite`,
                        }}
                    >
                        Go to Subscribe
                    </Button>
                </CardContent>
            </Card>
        </Box>
    </Grid>
);

// Define a hover animation for links
const hoverAnimation = keyframes`
  0% { color: #fffffe; }
  100% { color: #9DC183; }
`;

const MoreByNebulaSolver = () => (
    <Box sx={{ marginTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                <CardContent sx={{ padding: '14px 0px' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                        More by NebulaSolver
                    </Typography>
                    <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: '400px' }}>
                        {[
                            { href: "https://nebulasolver.com/logic", text: "Logic Solver" },
                            { href: "https://nebulasolver.com/equations", text: "Equation Solver" },
                            { href: "https://nebulasolver.com/matrix", text: "Linear Algebra" },
                            { href: "https://nebulasolver.com/controlsystems", text: "Control Systems" },
                            { href: "https://nebulasolver.com/finiteelements", text: "Finite Elements" },
                            { href: "https://nebulasolver.com/thermodynamics", text: "Thermodynamics" },
                            { href: "https://nebulasolver.com/batterymanagement", text: "Battery Management" },
                            { href: "https://nebulasolver.com/electronics", text: "Electronics" },
                            { href: "https://nebulasolver.com/systems", text: "Systems Engineering" },
                            { href: "https://nebulasolver.com/diagrams", text: "Diagrams" },
                            { href: "https://nebulasolver.com/gantt", text: "Gantt Chart" },
                            { href: "https://nebulasolver.com/stocks", text: "Stock Analysis" }
                        ].map(item => (
                            <Link
                                key={item.href}
                                href={item.href}
                                sx={{
                                    display: 'block',
                                    mb: 1,
                                    color: '#fffffe',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        animation: `${hoverAnimation} 0.3s forwards`
                                    }
                                }}
                            >
                                <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                {item.text}
                            </Link>
                        ))}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Box>
);

const Matrix = () => {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [commandInput, setCommandInput] = useState('');
    const [result, setResult] = useState('');
    const [error, setError] = useState('');
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !authLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, authLoading]);

    const handleSubmit = async () => {
        if (!isAuthenticated || !user) {
            setError('User not authenticated');
            return;
        }

        try {
            const jwtResponse = await fetch('/.netlify/functions/generateJWTMatrix', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userEmail: user.email }),
            });

            if (!jwtResponse.ok) {
                const errorDetail = await jwtResponse.text();
                throw new Error(`HTTP error ${jwtResponse.status}: ${errorDetail}`);
            }

            const jwtData = await jwtResponse.json();
            const jwtToken = jwtData.token;

            const response = await axios.post('https://p7qxy973ba.us-west-2.awsapprunner.com/matrix-command', {
                commands: commandInput
            }, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            setResult(formatResult(response.data));
            setError('');
        } catch (err) {
            const errorMessage = err.response?.data?.error || err.response?.statusText || err.message;
            setError(`Error: ${errorMessage}`);
            setResult('');
        }
    };

    const formatResult = (data) => {
        const formattedResult = Object.entries(data.result).map(([key, value]) => {
            if (Array.isArray(value)) {
                if (typeof value[0] === 'number') {
                    return `${key}:\n[${value.join(', ')}]\n\n`;
                } else if (Array.isArray(value[0])) {
                    const matrixString = value.map(row => `[${row.join(', ')}]`).join('\n');
                    return `${key}:\n${matrixString}\n\n`;
                }
            } else if (typeof value === 'object' && value !== null) {
                if ('eigenvalues' in value && 'eigenvectors' in value) {
                    const eigenvaluesString = `[${value.eigenvalues.join(', ')}]`;
                    const eigenvectorsString = value.eigenvectors.map(row => `[${row.join(', ')}]`).join('\n');
                    return `${key}:\n{\n  "eigenvalues": ${eigenvaluesString},\n  "eigenvectors":\n${eigenvectorsString}\n}\n\n`;
                }
            }
            return `${key}: ${JSON.stringify(value)}\n\n`;
        }).join('');

        return formattedResult;
    };

    if (authLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!subscribeStatus) {
        return (
            <Container maxWidth={false}>

                <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <Box sx={{ marginTop: '0px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                                <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                                    <CardContent sx={{ padding: '0px 0px' }}>
                                        <MatrixBlogPosts />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PricingBox />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MoreByNebulaSolver />
                    </Grid>
                </Grid>
                <Helmet>
                    <title>NebulaSolver - Linear Algebra</title>
                    <link rel="canonical" href={`https://nebulasolver.com/matrix`} />
                    <meta property="og:title" content="NebulaSolver - Linear Algebra Solver" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                    <meta property="og:url" content="https://nebulasolver.com/matrix" />
                    <meta property="og:description" content="NebulaSolver - Cloud-Based Linear Algebra Solver. Solve matrix operations like addition, subtraction, multiplication, transposition and inversion." />
                    <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/matrix`} />
                    <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/matrix`} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="NebulaSolver - Linear Algebra Solver" />
                    <meta name="twitter:description" content="NebulaSolver - Cloud-Based Linear Algebra Solver. Solve matrix operations like addition, subtraction, multiplication, transposition and inversion." />
                    <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                    <meta name="twitter:site" content="@nebulasolver" />
                </Helmet>
            </Container>
        );
    }

    return (
        <Container maxWidth={false}>

            <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
                <Grid item xs={12} md={3}>
                    <Box sx={{ marginTop: '0px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                            <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#353535', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                                <CardContent sx={{ padding: '0px 0px' }}>
                                    <MatrixBlogPosts />
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* Matrix Command Input */}
                    <Box sx={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Card sx={cardStyle}>
                            <CardContent>
                                <Typography variant="h6" sx={headerTextStyle}>
                                    Matrix Operations
                                </Typography>
                                <Box component="form" sx={formStyle} noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                    <TextField
                                        label="Matrix Commands"
                                        multiline
                                        rows={10}
                                        value={commandInput}
                                        onChange={(e) => setCommandInput(e.target.value)}
                                        sx={textFieldStyle}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Button variant="contained" type="submit" sx={buttonStyle}>
                                        Submit
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    {/* Solutions */}
                    <Box sx={{ marginTop: '0px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '14px',
                            }}
                        >
                            <Card
                                sx={{
                                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                                    backgroundColor: '#353535',
                                    borderRadius: '15px',
                                    border: '2px solid #35353500',
                                    width: '100%',
                                    mt: 2,
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            textAlign: 'center',
                                            fontFamily: "'Poppins', sans-serif",
                                            fontWeight: 'bold',
                                            letterSpacing: '0.05em',
                                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            animation: `${rainbowAnimation} 10s ease infinite`,
                                            backgroundSize: '200% 200%',
                                            '@media (max-width:600px)': {
                                                fontSize: '1.2em',
                                            },
                                            marginBottom:'10px',
                                        }}
                                    >
                                        Solutions
                                    </Typography>
                                    {error && <Typography sx={{ color: 'red', textAlign: 'center' }}>{error}</Typography>}
                                    {result &&
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                color:'#fffffe'
                                            }}
                                        >
                                            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                                {result}
                                            </pre>
                                        </Typography>}
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Helmet>
                <title>NebulaSolver - Linear Algebra</title>
                <link rel="canonical" href={`https://nebulasolver.com/matrix`} />
                <meta property="og:title" content="NebulaSolver - Linear Algebra Solver" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://nebulasolver.com/matrix" />
                <meta property="og:description" content="NebulaSolver - Cloud-Based Linear Algebra Solver. Solve matrix operations like addition, subtraction, multiplication, transposition and inversion." />
                <link rel="alternate" hrefLang="en" href={`https://nebulasolver.com/matrix`} />
                <link rel="alternate" hrefLang="x-default" href={`https://nebulasolver.com/matrix`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="NebulaSolver - Linear Algebra Solver" />
                <meta name="twitter:description" content="NebulaSolver - Cloud-Based Linear Algebra Solver. Solve matrix operations like addition, subtraction, multiplication, transposition and inversion." />
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                <meta name="twitter:site" content="@nebulasolver" />
            </Helmet>
        </Container>
    );
};


const cardStyle = {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#353535',
    borderRadius: '15px',
    border: '2px solid #35353500',
    width: '100%',
    marginBottom: '20px'
};

const headerTextStyle = {
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: `${rainbowAnimation} 10s ease infinite`,
    backgroundSize: '200% 200%',
    '@media (max-width:600px)': {
        fontSize: '1.2em',
    },
    marginBottom:'10px',
};

const formStyle = {
    '& .MuiTextField-root': { mb: 1 },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#1c2734',
        },
        '&:hover fieldset': {
            borderColor: '#9DC183',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#b9b9b9',
        },
    },
    '& .MuiInputBase-input': {
        color: '#9ca3af',
    },
    '& .MuiInputLabel-root': {
        color: '#9ca3af',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#9ca3af',
    },
};

const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: '#9DC183',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#b9b9b9',
        },
    },
    '& .MuiInputBase-input': {
        color: '#9ca3af',
    },
    backgroundColor: '#353535',
    borderColor: '#353535',
};

const buttonStyle = {
    mt: 2,
    backgroundColor: '#15a2fa',
    '&:hover': {
        backgroundColor: '#9DC183',
    },
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
};

export default Matrix;
