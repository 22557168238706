/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// src/components/NodesMermaid.jsx

export const InputNode = {
    label: 'Input Node',
    type: 'input',
    shape: 'rect',
};

export const SumNode = {
    label: 'Sum Node',
    type: 'sum',
    shape: 'rect',
};

export const PIDNode = {
    label: 'PID Node',
    type: 'pid',
    shape: 'rect',
};

export const PlantNode = {
    label: 'Plant Node',
    type: 'plant',
    shape: 'rect',
};

export const ScopeNode = {
    label: 'Scope Node',
    type: 'scope',
    shape: 'rect',
};

export const GainNode = {
    label: 'Gain Node',
    type: 'gain',
    shape: 'rect',
};
