import { memo, useState } from 'react';
import { Handle, Position, NodeToolbar } from 'reactflow';

function ToolbarNode({ data }) {
    const [emoji, setEmoji] = useState(() => '🚀');
    const [label, setLabel] = useState(data.label || '');

    return (
        <>
            <NodeToolbar isVisible>
                <button onClick={() => setEmoji('🚀')}>🚀</button>
                <button onClick={() => setEmoji('🔥')}>🔥</button>
                <button onClick={() => setEmoji('✨')}>✨</button>
            </NodeToolbar>
            <div className="custom-toolbar-node" style={{ padding: '5px 5px', border: '1px solid #fff', borderRadius: '5px' }}>
                <div>{emoji}</div>
                <input
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                    style={{ width: '100%', background: 'transparent', border: 'none', color: '#fff', textAlign:'center' }}
                />
            </div>
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
            <div style={{ position: 'absolute', bottom: -15, fontSize: 8, color: '#555' }}>{label}</div>
        </>
    );
}

export default memo(ToolbarNode);
