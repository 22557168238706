import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';

function AnnotationNode({ data }) {
    const [label, setLabel] = useState(data.label || 'Annotation');

    return (
        <div style={{ padding: 10, border: '1px solid #fff', borderRadius: '5px', width: '120px', height: '120px' }}>
      <textarea
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          style={{
              width: '100%',
              height: '100%',
              resize: 'none',
              background: 'transparent',
              border: 'none',
              color: '#fff',
              textAlign: 'center',
          }}
      />
            {data.arrowStyle && (
                <div className="arrow" style={data.arrowStyle}>
                    ⤹
                </div>
            )}
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
        </div>
    );
}

export default memo(AnnotationNode);
