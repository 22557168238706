/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

// Register font
Font.register({
    family: 'Poppins',
    fonts: [
        { src: `${process.env.PUBLIC_URL}/fonts/Poppins-Regular.ttf` }, // Regular
        { src: `${process.env.PUBLIC_URL}/fonts/Poppins-SemiBold.ttf`, fontWeight: 600 }, // Semi-bold
        { src: `${process.env.PUBLIC_URL}/fonts/Poppins-Bold.ttf`, fontWeight: 700 }  // Bold
    ]
});

const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: 40,
        color: '#000',
    },
    section: {
        marginBottom: 4,
        padding: 4,
    },
    header: {
        fontSize: 14,
        fontWeight: 'medium',
        marginBottom: 4,
        textAlign: 'center',
        fontFamily: 'Poppins',
        color: '#000',
    },
    subheader: {
        fontSize: 11,
        fontWeight: 'medium',
        marginBottom: 5,
        fontFamily: 'Poppins',
        color: '#000',
        textTransform: 'uppercase',
    },
    text: {
        fontSize: 9,
        fontWeight: 'light',
        marginBottom: 4,
        fontFamily: 'Poppins',
        color: '#000',
    },
    contactContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    contactText: {
        fontSize: 9,
        fontWeight: 'light',
        fontFamily: 'Poppins',
        color: '#000',
        marginHorizontal: 5,
    },
    centeredText: {
        textAlign: 'center',
    }
});

const sanitizeText = (text) => (text && text.trim()) ? text : null;

const ResumeDocument = ({ formData }) => (
    <Document>
        <Page size="LETTER" style={pdfStyles.page}>
            {/* Header Section */}
            <View style={pdfStyles.section}>
                {sanitizeText(formData.name) && (
                    <Text style={pdfStyles.header}>{formData.name}</Text>
                )}
                {sanitizeText(formData.email) && (
                    <Text style={[pdfStyles.text, pdfStyles.centeredText]}>{formData.email}</Text>
                )}
                {(sanitizeText(formData.address) || sanitizeText(formData.phone)) && (
                    <View style={pdfStyles.contactContainer}>
                        {sanitizeText(formData.address) && (
                            <Text style={pdfStyles.contactText}>{formData.address}</Text>
                        )}
                        {sanitizeText(formData.phone) && (
                            <Text style={pdfStyles.contactText}>{formData.phone}</Text>
                        )}
                    </View>
                )}
                {(sanitizeText(formData.developerProfile) || sanitizeText(formData.linkedinProfile)) && (
                    <View style={pdfStyles.contactContainer}>
                        {sanitizeText(formData.developerProfile) && (
                            <Text style={pdfStyles.contactText}>{formData.developerProfile}</Text>
                        )}
                        {sanitizeText(formData.linkedinProfile) && (
                            <Text style={pdfStyles.contactText}>{formData.linkedinProfile}</Text>
                        )}
                    </View>
                )}
            </View>

            {/* Education Section */}
            {formData.education && formData.education.length > 0 && formData.education[0] && (
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.subheader}>Education</Text>
                    {formData.education.map((edu, index) => (
                        sanitizeText(edu) && <Text key={index} style={pdfStyles.text}>{edu}</Text>
                    ))}
                </View>
            )}

            {/* Experience Section */}
            {formData.experience && formData.experience.length > 0 && sanitizeText(formData.experience[0].employer) && (
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.subheader}>Experience</Text>
                    {formData.experience.map((exp, index) => (
                        sanitizeText(exp.employer) && (
                            <View key={index}>
                                <Text style={{ ...pdfStyles.text, fontWeight: 'medium' }}>{exp.employer} - {exp.position}</Text>
                                {exp.accomplishments.map((acc, accIndex) => (
                                    sanitizeText(acc) && <Text key={accIndex} style={pdfStyles.text}>• {acc}</Text>
                                ))}
                            </View>
                        )
                    ))}
                </View>
            )}

            {/* Academic Projects Section */}
            {formData.academicProjects && formData.academicProjects.length > 0 && sanitizeText(formData.academicProjects[0].type) && (
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.subheader}>Projects</Text>
                    {formData.academicProjects.map((project, index) => (
                        sanitizeText(project.type) && (
                            <View key={index}>
                                <Text style={pdfStyles.text}>{project.type}{sanitizeText(project.client) && ` - Client: ${project.client}`}</Text>
                                {project.accomplishments.map((acc, accIndex) => (
                                    sanitizeText(acc) && <Text key={accIndex} style={pdfStyles.text}>• {acc}</Text>
                                ))}
                            </View>
                        )
                    ))}
                </View>
            )}

            {/* Leadership Experience Section */}
            {formData.leadershipExperience && formData.leadershipExperience.length > 0 && sanitizeText(formData.leadershipExperience[0].organization) && (
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.subheader}>Leadership Experience</Text>
                    {formData.leadershipExperience.map((exp, index) => (
                        sanitizeText(exp.organization) && (
                            <View key={index}>
                                <Text style={pdfStyles.text}>{exp.organization} - {exp.position}</Text>
                                {exp.accomplishments.map((acc, accIndex) => (
                                    sanitizeText(acc) && <Text key={accIndex} style={pdfStyles.text}>• {acc}</Text>
                                ))}
                            </View>
                        )
                    ))}
                </View>
            )}

            {/* Honors Section */}
            {formData.honors && formData.honors.length > 0 && sanitizeText(formData.honors[0]) && (
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.subheader}>Honors</Text>
                    {formData.honors.map((honor, index) => (
                        sanitizeText(honor) && <Text key={index} style={pdfStyles.text}>{honor}</Text>
                    ))}
                </View>
            )}

            {/* Additional Information Section */}
            {formData.additional && (
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.subheader}>Additional Information</Text>
                    {sanitizeText(formData.additional.computerSkills) && (
                        <Text style={pdfStyles.text}>Computer Skills: {formData.additional.computerSkills}</Text>
                    )}
                    {sanitizeText(formData.additional.languages) && (
                        <Text style={pdfStyles.text}>Languages: {formData.additional.languages}</Text>
                    )}
                    {sanitizeText(formData.additional.interests) && (
                        <Text style={pdfStyles.text}>Interests: {formData.additional.interests}</Text>
                    )}
                    {sanitizeText(formData.additional.workEligibility) && (
                        <Text style={pdfStyles.text}>Work Eligibility: {formData.additional.workEligibility}</Text>
                    )}
                    {sanitizeText(formData.additional.certifications) && (
                        <Text style={pdfStyles.text}>Certifications: {formData.additional.certifications}</Text>
                    )}
                </View>
            )}
        </Page>
    </Document>
);

export default ResumeDocument;
