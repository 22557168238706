/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Container,
    Select,
    MenuItem,
    Typography,
    Box,
    CircularProgress,
    Grid,
    Snackbar,
    TextField,
    AppBar,
    Toolbar,
    IconButton,
    Button
} from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import Alert from '@mui/material/Alert';
import MenuIcon from '@mui/icons-material/Menu';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ResumeDocument from './PdfRenderer';
import { FaSave } from 'react-icons/fa'; // Icons for PDF and Update buttons
import { BsFillFilePdfFill } from "react-icons/bs";

const MyResumes = () => {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [resumes, setResumes] = useState([]);
    const [selectedResumeId, setSelectedResumeId] = useState('');
    const [selectedResume, setSelectedResume] = useState(null);
    const [subscribeStatus, setSubscribeStatus] = useState(false);
    const [isSubscriptionStatusLoading, setIsSubscriptionStatusLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const formikRef = useRef();

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            setIsSubscriptionStatusLoading(true);
            if (isAuthenticated && user && !authLoading) {
                try {
                    const response = await fetch('/.netlify/functions/getUserSubscriptionStatus', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ userEmail: user.email })
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const subscriptionData = await response.json();
                    setSubscribeStatus(subscriptionData.isSubscribed);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                } finally {
                    setIsSubscriptionStatusLoading(false);
                }
            } else {
                setIsSubscriptionStatusLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [user, isAuthenticated, authLoading]);

    useEffect(() => {
        const fetchResumes = async () => {
            if (isAuthenticated && subscribeStatus) {
                try {
                    const response = await axios.get('/.netlify/functions/getResumes', {
                        params: { userEmail: user.email }
                    });
                    setResumes(response.data);
                } catch (error) {
                    console.error('Error fetching resumes', error);
                }
            }
        };

        fetchResumes();
    }, [user, isAuthenticated, subscribeStatus]);

    const handleResumeSelection = (e) => {
        const resumeId = e.target.value;
        setSelectedResumeId(resumeId);
        const resume = resumes.find((r) => r.id === resumeId);
        setSelectedResume(resume);
    };

    const handleUpdateSubmit = async (values) => {
        try {
            const cleanValues = {
                ...values,
                experience: values.experience.map(exp => ({
                    ...exp,
                    accomplishments: exp.accomplishments.filter(acc => acc.trim() !== ''),
                })),
                academicProjects: values.academicProjects.map(proj => ({
                    ...proj,
                    accomplishments: proj.accomplishments.filter(acc => acc.trim() !== ''),
                })),
                leadershipExperience: values.leadershipExperience.map(lead => ({
                    ...lead,
                    accomplishments: lead.accomplishments.filter(acc => acc.trim() !== ''),
                })),
            };

            await axios.post('/.netlify/functions/updateResume', {
                ...cleanValues,
                userEmail: user.email,
                id: selectedResumeId
            });
            setMessage('Resume updated successfully!');
            setOpen(true);

            // Fetch the updated resume to refresh the PDF data
            const updatedResumeResponse = await axios.get('/.netlify/functions/getResume', {
                params: { id: selectedResumeId }
            });
            setSelectedResume(updatedResumeResponse.data);

        } catch (error) {
            setMessage('Error updating resume');
            setOpen(true);
            console.error('Error updating resume', error);
        }
    };

    if (authLoading || isSubscriptionStatusLoading) {
        return (
            <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!subscribeStatus) {
        return (
            <Container maxWidth="md">
                <Typography variant="h6" gutterBottom>
                    You need to subscribe to use this feature.
                </Typography>
                <Button variant="contained" color="primary" href="/subscribe">
                    Go to Subscribe
                </Button>
            </Container>
        );
    }

    const PDFButton = React.forwardRef((props, ref) => (
        <div ref={ref}>
            <PDFDownloadLink {...props} />
        </div>
    ));

    return (
        <Container maxWidth="md">
            <AppBar position="relative" sx={{ backgroundColor: '#353535', boxShadow: 'none', padding: '10px 0' }}>
                <Toolbar sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', gap: '20px', marginBottom: '10px', mt: 1 }}>
                        {selectedResume && (
                            <>
                                <Button
                                    color="inherit"
                                    component={PDFButton}
                                    document={<ResumeDocument formData={selectedResume} />}
                                    fileName={`${selectedResume.resumeName || 'resume'}.pdf`}
                                    startIcon={<BsFillFilePdfFill />}
                                    sx={{ color: '#ffc801', marginRight: '10px' }}
                                >
                                    PDF
                                </Button>
                                <Button
                                    color="inherit"
                                    onClick={() => formikRef.current.submitForm()}
                                    startIcon={<FaSave />}
                                    sx={{
                                        color: '#ffc801',
                                        backgroundColor: '#353535',
                                        '&:hover': {
                                            backgroundColor: '#9DC183',
                                        },
                                    }}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>

            <Typography variant="h6" gutterBottom>
                Select a Resume to Edit
            </Typography>
            <Select value={selectedResumeId} onChange={handleResumeSelection} fullWidth displayEmpty>
                <MenuItem value="" disabled>
                    Select a resume
                </MenuItem>
                {resumes.map((resume) => (
                    <MenuItem key={resume.id} value={resume.id}>
                        {resume.resumeName || resume.role}
                    </MenuItem>
                ))}
            </Select>

            {selectedResume && (
                <Formik
                    enableReinitialize={true}
                    initialValues={selectedResume}
                    onSubmit={handleUpdateSubmit}
                    innerRef={formikRef}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <Box mt={3} mb={3}>
                                <Typography variant="h6" gutterBottom>
                                    Resume Details:
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Resume Name"
                                            name="resumeName"
                                            value={values.resumeName || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Role"
                                            name="role"
                                            value={values.role || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Role Description"
                                            name="roleDescription"
                                            value={values.roleDescription || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Company"
                                            name="company"
                                            value={values.company || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Name"
                                            name="name"
                                            value={values.name || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Email"
                                            name="email"
                                            value={values.email || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Phone"
                                            name="phone"
                                            value={values.phone || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Address"
                                            name="address"
                                            value={values.address || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Developer Profile"
                                            name="developerProfile"
                                            value={values.developerProfile || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="LinkedIn Profile"
                                            name="linkedinProfile"
                                            value={values.linkedinProfile || ''}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                style: { color: '#fff' }
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#b9b9b9' }
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                {/* Experience */}
                                <FieldArray
                                    name="experience"
                                    render={(arrayHelpers) => (
                                        <Box sx={{ marginBottom: 3 }}>
                                            <Typography variant="h6" gutterBottom>
                                                Experience
                                            </Typography>
                                            {values.experience.map((exp, index) => (
                                                <Box key={index} sx={{ marginBottom: 3 }}>
                                                    <TextField
                                                        label="Employer"
                                                        name={`experience.${index}.employer`}
                                                        value={exp.employer || ''}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        margin="normal"
                                                        InputProps={{
                                                            style: { color: '#fff', fontWeight: 'medium' }
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: '#b9b9b9' }
                                                        }}
                                                    />
                                                    <TextField
                                                        label="Position"
                                                        name={`experience.${index}.position`}
                                                        value={exp.position || ''}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        margin="normal"
                                                        InputProps={{
                                                            style: { color: '#fff' }
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: '#b9b9b9' }
                                                        }}
                                                    />
                                                    <FieldArray
                                                        name={`experience.${index}.accomplishments`}
                                                        render={(subArrayHelpers) => (
                                                            <Box>
                                                                {exp.accomplishments.map((item, subIndex) => (
                                                                    <Box
                                                                        key={subIndex}
                                                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
                                                                    >
                                                                        <TextField
                                                                            label={`Accomplishment ${subIndex + 1}`}
                                                                            name={`experience.${index}.accomplishments.${subIndex}`}
                                                                            value={item || ''}
                                                                            onChange={handleChange}
                                                                            fullWidth
                                                                            margin="normal"
                                                                            InputProps={{
                                                                                style: { color: '#fff' }
                                                                            }}
                                                                            InputLabelProps={{
                                                                                style: { color: '#b9b9b9' }
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            onClick={() => subArrayHelpers.remove(subIndex)}
                                                                            sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </Box>
                                                                ))}
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    sx={{ bgcolor: '#353535', color: '#fffffe', width: '30%' }}
                                                                    onClick={() => subArrayHelpers.push('')}
                                                                >
                                                                    Add Accomplishment
                                                                </Button>
                                                            </Box>
                                                        )}
                                                    />
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ marginTop: 2, bgcolor: '#353535', width: '30%' }}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        Remove Experience
                                                    </Button>
                                                </Box>
                                            ))}
                                            <Button
                                                type="button"
                                                variant="contained"
                                                sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                                onClick={() => arrayHelpers.push({ employer: '', position: '', accomplishments: [''] })}
                                            >
                                                Add Experience
                                            </Button>
                                        </Box>
                                    )}
                                />

                                {/* Academic Projects */}
                                <FieldArray
                                    name="academicProjects"
                                    render={(arrayHelpers) => (
                                        <Box sx={{ marginBottom: 3 }}>
                                            <Typography variant="h6" gutterBottom>
                                                Projects
                                            </Typography>
                                            {values.academicProjects.map((project, index) => (
                                                <Box key={index} sx={{ marginBottom: 3 }}>
                                                    <TextField
                                                        label="Type of Project"
                                                        name={`academicProjects.${index}.type`}
                                                        value={project.type || ''}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        margin="normal"
                                                        InputProps={{
                                                            style: { color: '#fff' }
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: '#b9b9b9' }
                                                        }}
                                                    />
                                                    <TextField
                                                        label="Client"
                                                        name={`academicProjects.${index}.client`}
                                                        value={project.client || ''}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        margin="normal"
                                                        InputProps={{
                                                            style: { color: '#fff' }
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: '#b9b9b9' }
                                                        }}
                                                    />
                                                    <FieldArray
                                                        name={`academicProjects.${index}.accomplishments`}
                                                        render={(subArrayHelpers) => (
                                                            <Box>
                                                                {project.accomplishments.map((item, subIndex) => (
                                                                    <Box
                                                                        key={subIndex}
                                                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
                                                                    >
                                                                        <TextField
                                                                            label={`Accomplishment ${subIndex + 1}`}
                                                                            name={`academicProjects.${index}.accomplishments.${subIndex}`}
                                                                            value={item || ''}
                                                                            onChange={handleChange}
                                                                            fullWidth
                                                                            margin="normal"
                                                                            InputProps={{
                                                                                style: { color: '#fff' }
                                                                            }}
                                                                            InputLabelProps={{
                                                                                style: { color: '#b9b9b9' }
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            onClick={() => subArrayHelpers.remove(subIndex)}
                                                                            sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </Box>
                                                                ))}
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    sx={{ bgcolor: '#353535', color: '#fffffe', width: '30%' }}
                                                                    onClick={() => subArrayHelpers.push('')}
                                                                >
                                                                    Add Accomplishment
                                                                </Button>
                                                            </Box>
                                                        )}
                                                    />
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        color="secondary"
                                                        sx={{ marginTop: 2, bgcolor: '#353535', width: '30%' }}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        Remove Project
                                                    </Button>
                                                </Box>
                                            ))}
                                            <Button
                                                type="button"
                                                variant="contained"
                                                sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                                onClick={() => arrayHelpers.push({ type: '', client: '', accomplishments: [''] })}
                                            >
                                                Add Project
                                            </Button>
                                        </Box>
                                    )}
                                />

                                {/* Leadership Experience */}
                                <FieldArray
                                    name="leadershipExperience"
                                    render={(arrayHelpers) => (
                                        <Box sx={{ marginBottom: 3 }}>
                                            <Typography variant="h6" gutterBottom>
                                                Leadership
                                            </Typography>
                                            {values.leadershipExperience.map((exp, index) => (
                                                <Box key={index} sx={{ marginBottom: 3 }}>
                                                    <TextField
                                                        label="Organization"
                                                        name={`leadershipExperience.${index}.organization`}
                                                        value={exp.organization || ''}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        margin="normal"
                                                        InputProps={{
                                                            style: { color: '#fff' }
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: '#b9b9b9' }
                                                        }}
                                                    />
                                                    <TextField
                                                        label="Position"
                                                        name={`leadershipExperience.${index}.position`}
                                                        value={exp.position || ''}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        margin="normal"
                                                        InputProps={{
                                                            style: { color: '#fff' }
                                                        }}
                                                        InputLabelProps={{
                                                            style: { color: '#b9b9b9' }
                                                        }}
                                                    />
                                                    <FieldArray
                                                        name={`leadershipExperience.${index}.accomplishments`}
                                                        render={(subArrayHelpers) => (
                                                            <Box>
                                                                {exp.accomplishments.map((item, subIndex) => (
                                                                    <Box
                                                                        key={subIndex}
                                                                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
                                                                    >
                                                                        <TextField
                                                                            label={`Accomplishment ${subIndex + 1}`}
                                                                            name={`leadershipExperience.${index}.accomplishments.${subIndex}`}
                                                                            value={item || ''}
                                                                            onChange={handleChange}
                                                                            fullWidth
                                                                            margin="normal"
                                                                            InputProps={{
                                                                                style: { color: '#fff' }
                                                                            }}
                                                                            InputLabelProps={{
                                                                                style: { color: '#b9b9b9' }
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            type="button"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            onClick={() => subArrayHelpers.remove(subIndex)}
                                                                            sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </Box>
                                                                ))}
                                                                <Button
                                                                    type="button"
                                                                    variant="contained"
                                                                    sx={{ bgcolor: '#353535', color: '#fffffe', width: '30%' }}
                                                                    onClick={() => subArrayHelpers.push('')}
                                                                >
                                                                    Add Accomplishment
                                                                </Button>
                                                            </Box>
                                                        )}
                                                    />
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        color="secondary"
                                                        sx={{ marginTop: 2, bgcolor: '#353535', width: '30%' }}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        Remove Leadership Experience
                                                    </Button>
                                                </Box>
                                            ))}
                                            <Button
                                                type="button"
                                                variant="contained"
                                                sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                                onClick={() => arrayHelpers.push({ organization: '', position: '', accomplishments: [''] })}
                                            >
                                                Add Leadership Experience
                                            </Button>
                                        </Box>
                                    )}
                                />

                                {/* Education and Honors */}
                                {['education', 'honors'].map((section) => (
                                    <FieldArray
                                        key={section}
                                        name={section}
                                        render={(arrayHelpers) => (
                                            <Box sx={{ marginBottom: 3 }}>
                                                <Typography variant="h6" gutterBottom>
                                                    {section.charAt(0).toUpperCase() + section.slice(1)}
                                                </Typography>
                                                {values[section].map((item, index) => (
                                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                        <TextField
                                                            name={`${section}.${index}`}
                                                            value={item || ''}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            margin="normal"
                                                            InputProps={{
                                                                style: { color: '#fff' }
                                                            }}
                                                            InputLabelProps={{
                                                                style: { color: '#b9b9b9' }
                                                            }}
                                                        />
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            color="secondary"
                                                            sx={{ marginLeft: 2, bgcolor: '#353535', width: '25%' }}
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Box>
                                                ))}
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    sx={{ bgcolor: '#9DC183', color: '#fffffe', width: '30%' }}
                                                    onClick={() => arrayHelpers.push('')}
                                                >
                                                    Add {section.charAt(0).toUpperCase() + section.slice(1)}
                                                </Button>
                                            </Box>
                                        )}
                                    />
                                ))}

                                {/* Additional Information */}
                                <Box sx={{ marginBottom: 3 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Additional Information
                                    </Typography>
                                    <TextField
                                        label="Computer Skills"
                                        name="additional.computerSkills"
                                        value={values.additional.computerSkills || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            style: { color: '#fff' }
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#b9b9b9' }
                                        }}
                                    />
                                    <TextField
                                        label="Languages"
                                        name="additional.languages"
                                        value={values.additional.languages || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            style: { color: '#fff' }
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#b9b9b9' }
                                        }}
                                    />
                                    <TextField
                                        label="Interests"
                                        name="additional.interests"
                                        value={values.additional.interests || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            style: { color: '#fff' }
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#b9b9b9' }
                                        }}
                                    />
                                    <TextField
                                        label="Work Eligibility"
                                        name="additional.workEligibility"
                                        value={values.additional.workEligibility || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            style: { color: '#fff' }
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#b9b9b9' }
                                        }}
                                    />
                                    <TextField
                                        label="Certifications"
                                        name="additional.certifications"
                                        value={values.additional.certifications || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            style: { color: '#fff' }
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#b9b9b9' }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                                <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Form>
                    )}
                </Formik>
            )}
        </Container>
    );
};

export default MyResumes;
